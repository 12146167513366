import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputFile from '../../components/input/InputFile'
import InputText from '../../components/input/InputText'
import InputCooperative from '../../components/input/InputCooperative'
import InputTrajet from '../../components/input/InputTrajet'
import InputSelect from '../../components/input/InputSelect'
import Btn from '../../components/button/Btn'


export default function EditVehicule({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const categorieList = [
        'ECONOMIQUE',
        'PREMIUM',
        'VIP'
    ]

    const nbPlaceList = [
        '22',
        '18',
        '13'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [cooperative, setCooperative] = useState(null)
    const [profil, setProfil] = useState("")
    const [tmpFile, setTmpFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [trajet, setTrajet] = useState(null)
    const [matricule, setMatricule] = useState("")
    const [marque, setMarque] = useState("")
    const [modele, setModele] = useState("")
    const [annee, setAnnee] = useState("")
    const [categorie, setCategorie] = useState("")
    const [nombrePlace, setNombrePlace] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(!cooperative || !trajet || !matricule.trim() || !marque.trim() || !categorie.trim() || !String(nombrePlace).trim() || loading)
    }, [cooperative, trajet, matricule, marque, annee, categorie, nombrePlace, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('fichier', profil)
        if (['/app/vehicule/edit/' + params.id].includes(pathname)) {
            data.append('fichier_id', fileId)
        }
        data.append('level', 1)
        data.append('cooperative_id', cooperative ? cooperative.id : '')
        data.append('trajet_id', trajet ? trajet.id : '')
        data.append('matricule', matricule)
        data.append('marque', marque)
        data.append('modele', modele)
        data.append('annee', annee)
        data.append('categorie', categorie)
        data.append('nombre_place', nombrePlace)

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/vehicule/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setCategorie(null)
                setTrajet(null)
                setMatricule("")
                setMarque("")
                setModele("")
                setAnnee("")
                setCategorie("")
                setNombrePlace("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/vehicule')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/vehicule/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const vehicule = res.data.vehicule
                    if (vehicule) {
                        if (vehicule.photo) setTmpFile(vehicule.photo)
                        if (vehicule.photo_id) setFileId(vehicule.photo_id)
                        if (vehicule.cooperative) setCooperative({
                            id: vehicule.cooperative_id,
                            designation: vehicule.cooperative
                        })
                        if (vehicule.trajet) setTrajet({
                            id: vehicule.trajet_id,
                            designation: vehicule.trajet,
                        })
                        if (vehicule.matricule) setMatricule(vehicule.matricule)
                        if (vehicule.marque) setMarque(vehicule.marque)
                        if (vehicule.modele) setModele(vehicule.modele)
                        if (vehicule.annee) setAnnee(vehicule.annee)
                        if (vehicule.categorie) setCategorie(vehicule.categorie)
                        if (vehicule.nombre_place) setNombrePlace(vehicule.nombre_place)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/vehicule')
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid-one">
                        <InputFile
                            label="Photo de profil"
                            file={profil}
                            setFile={setProfil}
                            tmpFile={tmpFile}
                            setTmpFile={setTmpFile}
                            id="profil"
                            required
                        />
                    </div>
                    <div className="grid-two">
                        <InputCooperative
                            label="Coopérative"
                            value={cooperative}
                            onChange={setCooperative}
                            required
                        />
                        <InputTrajet
                            label="Trajet"
                            value={trajet}
                            onChange={setTrajet}
                            required
                        />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label='Matricule'
                            placeholder='Saisir le matricule'
                            value={matricule}
                            onChange={setMatricule}
                            className='large'
                            required />
                        <InputText
                            label='Marque'
                            placeholder="Saisir la marque"
                            value={marque}
                            onChange={setMarque}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label='Modèle'
                            placeholder='Saisir le modèle'
                            value={modele}
                            onChange={setModele}
                            className='large' />
                        <InputText
                            label='Année'
                            placeholder="Saisir l'année de frabrication"
                            type="number"
                            value={annee}
                            onChange={setAnnee}
                            className='large' />
                    </div>
                    <div className="grid-two">
                        <InputSelect
                            label='Nombre de place'
                            selected={nombrePlace}
                            setSelected={setNombrePlace}
                            options={nbPlaceList}
                            className='large'
                            required />
                        <InputSelect
                            label='Catégorie'
                            selected={categorie}
                            setSelected={setCategorie}
                            options={categorieList}
                            className='large'
                            required />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/vehicule/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
