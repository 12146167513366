import { useState, useEffect } from 'react'
import User from '../pages/user/User'
import Cooperative from '../pages/cooperative/Cooperative'
import Guichet from '../pages/guichet/Guichet'
import Gare from '../pages/gare/Gare'
import Site from '../pages/site/Site'
import Ville from '../pages/ville/Ville'
import Trajet from '../pages/trajet/Trajet'
import Tarif from '../pages/tarif/Tarif'
import Vehicule from '../pages/vehicule/Vehicule'
import Voyage from '../pages/voyage/Voyage'
import Notification from '../pages/notification/Notification'
import Conversation from '../pages/conversation/Conversation'
import ReservationClient from '../pages/reservation/ReservationClient'
import ShowUser from '../pages/user/ShowUser'
import ShowCooperative from '../pages/cooperative/ShowCooperative'
import ShowGare from '../pages/gare/ShowGare'
import ShowSite from '../pages/site/ShowSite'
import ShowVille from '../pages/ville/ShowVille'
import ShowTrajet from '../pages/trajet/ShowTrajet'
import ShowTarif from '../pages/tarif/ShowTarif'
import ShowVehicule from '../pages/vehicule/ShowVehicule'
import ShowVoyage from '../pages/voyage/ShowVoyage'
import ShowConversation from '../pages/conversation/ShowConversation'
import ShowGuichet from '../pages/guichet/ShowGuichet'
import ShowReservationClient from '../pages/reservation/ShowReservationClient'
import useScreenSize from '../hooks/useScreenSize'
import LOGO_BG from '../assets/LOGO/LOGO_BG.svg'

export default function View({ auth, name }) {
    const [currentId, setCurrentId] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [items, setItems] = useState([])
    const [itemTitle, setItemTitle] = useState("")
    const [messages, setMessages] = useState([])
    const screenSize = useScreenSize()

    useEffect(() => {
        if (currentItem) {
            let newItems = []
            items.forEach((item) => {
                if (currentItem.id == item.id) {
                    newItems.push(currentItem)
                }
                else
                    newItems.push(item)
            })
            setItems(newItems)
        }
    }, [currentItem])

    return (
        <div className='view-container'>
            {((["sm", "md"].includes(screenSize) && !currentId) || !["sm", "md"].includes(screenSize)) &&
                <div className="view-list"
                    style={{
                        width:
                            ["2xl", "xl", "lg"].includes(screenSize) && name === "conversation" ? "450px"
                                : ["2xl"].includes(screenSize) ? "calc(100% - 650px)"
                                    : ["xl"].includes(screenSize) ? "calc(100% - 600px)"
                                        : ["lg"].includes(screenSize) ? "calc(100% - 500px)"
                                            : "100%"
                    }}>
                    {
                        name === "user" &&
                        <User
                            auth={auth}
                            users={items}
                            setUsers={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "guichet" &&
                        <Guichet
                            auth={auth}
                            guichets={items}
                            setGuichets={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "cooperative" &&
                        <Cooperative
                            auth={auth}
                            cooperatives={items}
                            setCooperatives={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "gare" &&
                        <Gare
                            auth={auth}
                            gares={items}
                            setGares={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "site" &&
                        <Site
                            auth={auth}
                            sites={items}
                            setSites={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "ville" &&
                        <Ville
                            auth={auth}
                            villes={items}
                            setVilles={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "trajet" &&
                        <Trajet
                            auth={auth}
                            trajets={items}
                            setTrajets={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "tarif" &&
                        <Tarif
                            auth={auth}
                            tarifs={items}
                            setTarifs={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "vehicule" &&
                        <Vehicule
                            auth={auth}
                            vehicules={items}
                            setVehicules={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "voyage" &&
                        <Voyage
                            auth={auth}
                            voyages={items}
                            setVoyages={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                    {
                        name === "notification" &&
                        <Notification
                            auth={auth}
                            notifications={items}
                            setNotifications={setItems} />
                    }
                    {
                        name === "conversation" &&
                        <Conversation
                            auth={auth}
                            conversations={items}
                            setConversations={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setItemTitle={setItemTitle}
                            messages={messages} />
                    }
                    {
                        name === "reservation_client" &&
                        <ReservationClient
                            auth={auth}
                            reservations={items}
                            setReservations={setItems}
                            currentId={currentId}
                            setCurrentId={setCurrentId} />
                    }
                </div>
            }
            {((["sm", "md"].includes(screenSize) && currentId) || !["sm", "md"].includes(screenSize)) &&
                <div className="view-details"
                    style={{
                        width:
                            ["2xl", "xl"].includes(screenSize) && name === "conversation" ? "calc(100% - 720px)"
                                : ["lg"].includes(screenSize) && name === "conversation" ? "calc(100% - 490px)"
                                    : ["2xl"].includes(screenSize) ? "650px"
                                        : ["xl"].includes(screenSize) ? "600px"
                                            : ["lg"].includes(screenSize) ? "500px"
                                                : "100%"
                    }}>
                    {currentId ?
                        <>
                            {
                                name === "user" &&
                                <ShowUser
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "cooperative" &&
                                <ShowCooperative
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "gare" &&
                                <ShowGare
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "site" &&
                                <ShowSite
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "ville" &&
                                <ShowVille
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "trajet" &&
                                <ShowTrajet
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "tarif" &&
                                <ShowTarif
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "vehicule" &&
                                <ShowVehicule
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "voyage" &&
                                <ShowVoyage
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "conversation" &&
                                <ShowConversation
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    messages={messages}
                                    setMessages={setMessages} />
                            }
                            {
                                name === "guichet" &&
                                <ShowGuichet
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                            {
                                name === "reservation_client" &&
                                <ShowReservationClient
                                    auth={auth}
                                    currentId={currentId}
                                    setCurrentId={setCurrentId}
                                    setCurrentItem={setCurrentItem} />
                            }
                        </>
                        :
                        <div className="view-background">
                            <img src={LOGO_BG} alt="Logo" />
                        </div>
                    }
                </div>
            }

        </div>
    )
}
