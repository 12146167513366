export default function InputRadio({ label, name, options, selectedValue, onChange }) {
    const defaultOption = { value: 'Tous', label: 'Tous' }
    const allOptions = [defaultOption, ...options]

    return (
        <fieldset className="custom-radio-group">
            <legend className="radio-label">{label ? label : "Options"} :</legend>
            {allOptions.map((option, index) => (
                <div key={option.value} className="custom-radio-container">
                    <input
                        type="radio"
                        id={`${name}-${option.value}`}
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={() => onChange(option.value)}
                        className="custom-radio-input visually-hidden"
                    />
                    <label htmlFor={`${name}-${option.value}`} className="custom-radio-label">{option.label}</label>
                </div>
            ))}
        </fieldset>
    )
}
