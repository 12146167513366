
import { useRef } from 'react'
import { CgClose } from 'react-icons/cg'
import { useReactToPrint } from 'react-to-print'
import Btn from '../../components/button/Btn'
import BadgeItem from './BadgeItem'
import moment from 'moment'

export default function Badge({ selectedUsers, closeModal }) {
    const componentPDF = useRef()
    const generatePdf = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Badge-" + moment().format('D-M-Y H:m'),

    })

    return (
        <div className="badge-modal">
            <div>
                {selectedUsers && selectedUsers.length > 0 &&
                    <div className='space-between' style={{ marginBottom: 20, }}>
                        <Btn className="btn-primary small" label="Exporter" onClick={generatePdf} />
                        <CgClose className='secondary' size={30} onClick={() => { closeModal() }} />
                    </div>
                }
                <div className="list-modal-container" >
                    <div ref={componentPDF} className='container-badge-profil'>
                        {selectedUsers &&
                            selectedUsers.map((u) => (
                                <div key={u.id}>
                                    <BadgeItem user={u} />
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>

    )
}
