import { combineReducers } from 'redux'
import vehiculeReducer from './vehiculeReducer'
// Importe d'autres reducers au besoin

const rootReducer = combineReducers({
    vehicules: vehiculeReducer,
    // Ajoute d'autres objets et leurs reducers ici
});

export default rootReducer
