import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import useFileUrl from '../../hooks/useFileUrl'
import usePriceClient from '../../hooks/usePriceClient'
import PageLoader from '../../components/loader/PageLoader'
import Carseat from '../../components/carseat/Carseat'
import InputVille from '../../components/input/InputVille'
import InputText from '../../components/input/InputText'
import InputRadio from '../../components/input/InputRadio'
import COOPERATIVE from '../../assets/PROFILE/COOPERATIVE.png'
import VAN from '../../assets/SVG/VAN.png'
import Btn from '../../components/button/Btn'
import { CgClose } from 'react-icons/cg'
import EditReservation from '../reservation/EditReservation'

export default function VoyageClient({ auth }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const formatPriceClient = usePriceClient()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [voyages, setVoyages] = useState([])
    const [currentId, setCurrentId] = useState(null)
    const [currentVoyage, setCurrentVoyage] = useState(null)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])
    const [villeDepart, setVilleDepart] = useState("")
    const [villeArrive, setVilleArrive] = useState("")
    const [dateDepart, setDateDepart] = useState(moment().format('YYYY-MM-DD'))
    const [nbVoyageur, setNbVoyageur] = useState("")
    const [categorie, setCategorie] = useState("Tous")
    const [horaire, setHoraire] = useState("Tous")
    const [showModal, setShowModal] = useState(false)

    const categorieOptions = [
        { value: 'ECONOMIQUE', label: 'Economique' },
        { value: 'PREMIUM', label: 'Premium' },
        { value: 'VIP', label: 'VIP' }
    ];

    const horaireOptions = [
        { value: 'MATIN', label: 'Matin' },
        { value: 'APRES_MIDI', label: 'Après-midi' },
        { value: 'SOIR', label: 'Soir' }
    ];

    const [prixVoyage, setPrixVoyage] = useState(null)

    const [selectedSeats, setSelectedSeats] = useState([])
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null)

    const formatDateClass = (date) => {
        const heureDepart = moment(date)
        const formatHeure = heureDepart.hour()

        const heureFormatted =
            formatHeure < 12
                ? 'matin'
                : formatHeure >= 12 && formatHeure < 18
                    ? 'après-midi'
                    : 'soir'

        return `${heureFormatted}`
    }

    const handleUserSelection = (userId) => {
        setSelectedUserId(userId)
    }

    const formatDates = (voyages) => {
        const formattedDates = voyages.map(vo => moment(vo.created_at).from(vo.datetime))
        setFormattedDates(formattedDates)
    }

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (villeDepart) params.set("ville_depart_id", villeDepart.id)
        if (villeArrive) params.set("ville_arrive_id", villeArrive.id)
        if (dateDepart) params.set("date_depart", dateDepart)
        if (nbVoyageur) params.set("nombre_place_libre", nbVoyageur)
        if (categorie) params.set("categorie", categorie)
        if (horaire) params.set("horaire", horaire)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", voyages.length)
        }

        try {
            const res = await useApi.get('/voyage/client' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error)
                } else {
                    if (initial) {
                        const voyagesWithReservations = res.data.voyages.map(voyage => ({
                            ...voyage,
                            reservations: JSON.parse(voyage.reservations)
                        }))
                        setVoyages(voyagesWithReservations)
                    } else {
                        const updatedVoyages = [...voyages, ...res.data.voyages]
                        setVoyages(updatedVoyages)
                    }
                    setallDataLoaded(res.data.voyages.length < 30)
                    formatDates(res.data.voyages)
                }
                setLoading(false)
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (villeDepart !== '' || villeArrive !== '' || dateDepart !== '' || nbVoyageur !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, villeDepart, villeArrive, dateDepart, nbVoyageur, categorie, horaire])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(voyages)
        const interval = setInterval(() => {
            formatDates(voyages)
        }, 60000)
        return () => clearInterval(interval)
    }, [voyages])

    useEffect(() => {
        window.Echo.channel('reservation-channel')
            .listen('.created', (event) => {
                setVoyages(prevReservations => {
                    if (!prevReservations.some(reservation => reservation.id === event.reservation.id)) {
                        return [event.reservation, ...prevReservations]
                    }
                    return prevReservations
                })

            })
            .listen('.updated', (event) => {
                setVoyages(prevReservations => prevReservations.map(reservation => {
                    if (reservation.id === event.reservation.id) {
                        return event.reservation
                    } else {
                        return reservation
                    }
                }))
            })
    }, [voyages])

    useEffect(() => {
        if (currentId) {
            const selectedSeatsForCurrentId = selectedSeats.filter(seat => seat.voyage_id === currentId);
            if (JSON.stringify(selectedSeatsForCurrentId) !== JSON.stringify(selectedSeats)) {
                setSelectedSeats(selectedSeatsForCurrentId);
            }
        }
        if (!selectedSeats.length) {
            setCurrentVoyage(null);
        }
    }, [currentId, selectedSeats])

    const handleVoyage = (voyage) => {
        setPrixVoyage(voyage.tarif_prix)
        setCurrentId(voyage.id)
        setCurrentVoyage(voyage)
    }

    return (
        <>
            {showModal &&
                <EditReservation
                    auth={auth}
                    setShowModal={setShowModal}
                    selectedSeats={selectedSeats}
                    setSelectedSeats={setSelectedSeats}
                    voyageId={currentId}
                    updateData={updateData}
                    currentVoyage={currentVoyage} />
            }
            <div className="voyage-client-header">
                <h3>Sélectionner l'itinéraire</h3>
                <div className="input-group">
                    <InputVille
                        label="Ville de départ"
                        value={villeDepart}
                        onChange={setVilleDepart}
                        emptyObject={() => setVilleDepart("")}
                    />
                    <InputVille
                        label="Ville arrivé"
                        value={villeArrive}
                        onChange={setVilleArrive}
                        className='large'
                        emptyObject={() => setVilleArrive("")}
                    />
                    <InputText
                        label={"Date du voyage"}
                        value={dateDepart}
                        onChange={setDateDepart}
                        type='date'
                        className='large'
                    />
                    <InputText
                        label={"Nombre de voyageur"}
                        value={nbVoyageur}
                        onChange={setNbVoyageur}
                        type='number'
                        className='large'
                        min="1"
                    />
                </div>
            </div>
            {loading ? <PageLoader />
                :
                <>
                    <div className="voyage-client-container">
                        <div className="voyage-filter">
                            <h3>Filtre</h3>
                            <div className="input-radio-group">
                                <InputRadio
                                    label="Classe"
                                    name="categorie"
                                    options={categorieOptions}
                                    selectedValue={categorie}
                                    onChange={setCategorie}
                                />
                                <InputRadio
                                    label="Horaire"
                                    name="horaire"
                                    options={horaireOptions}
                                    selectedValue={horaire}
                                    onChange={setHoraire}
                                />
                            </div>

                        </div>
                        <div className='voyage-data'>
                            {voyages.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                                :
                                <InfiniteScroll
                                    dataLength={voyages.length}
                                    next={fetchMoreData}
                                    hasMore={!allDataLoaded}
                                    loader={<PageLoader />}
                                >

                                    <div className={`voyage-list-container`}>
                                        {selectedSeats.length > 0 &&
                                            <h3>Sélectionner place</h3>
                                        }
                                        <div className="reservation-info-container">
                                            {selectedSeats.length > 0 &&
                                                <>
                                                    <p>En cliquant sur valider, vous acceptez les <span className='text-orange'>Termes et Conditions</span></p>
                                                    <div className='reservation-validation-container'>
                                                        <span>{`Vos place${selectedSeats.length > 1 ? 's' : ''}`} : {selectedSeats.length}</span>
                                                        <span>Montant : {formatPrice(prixVoyage * selectedSeats.length)}</span>
                                                        <div className="reservation-btn-container">
                                                            <Btn
                                                                label="Valider"
                                                                className='btn-primary small'
                                                                onClick={() => setShowModal(true)}
                                                            />
                                                            <CgClose className='pointer' size={18} onClick={() => setSelectedSeats([])} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="reservation-legend">
                                                <div className='reservation-legend-text'><div className='reservation-libre' /> Libres</div>
                                                <div className='reservation-legend-text'><div className='reservation-avance' /> Avances</div>
                                                <div className='reservation-legend-text'><div className='reservation-paye' /> Payés</div>
                                                <div className='reservation-legend-text'><div className='reservation-place' /> Sélectionnées</div>
                                            </div>
                                        </div>
                                        {
                                            voyages.map((vo, index) => (
                                                <div className={`voyage-list ${currentId && currentId == vo.id ? 'selected' : ''}`} key={vo.id} onClick={() => handleVoyage(vo)}>
                                                    <div className="voyage-info">
                                                        <div className="voyage-info-title">
                                                            <div className="voyage-cooperative">
                                                                <img src={vo.cooperative_photo ? useFileUrl + vo.cooperative_photo : COOPERATIVE} alt="Logo coopérative" />
                                                                <div className="cooperative-info">
                                                                    <h3>{vo.cooperative}</h3>
                                                                    <span>{vo.cooperative_adresse}</span>
                                                                </div>
                                                            </div>
                                                            <h4 className='voyage-price'>{formatPriceClient(vo.tarif_prix)}</h4>
                                                        </div>
                                                        <div className="voyage-trajet">
                                                            <h4 className='capitalize' style={{ margin: '0' }}>{moment(vo.date_depart).format('dddd, DD MMMM YYYY')}</h4>
                                                            <h3 className='uppercase'>{vo.tarif_categorie} </h3>
                                                            <div className="trajet-container">
                                                                <div className="trajet-designation">
                                                                    <h1>{vo.ville_depart_libelle}</h1>
                                                                    <span>{vo.ville_depart_nom}</span>
                                                                </div>
                                                                <div className="trajet-design">
                                                                    <hr className="dash-line" />
                                                                    <img src={VAN} alt="Image" />
                                                                    <hr className="dash-line" />
                                                                </div>
                                                                <div className="trajet-designation">
                                                                    <h1>{vo.ville_arrive_libelle}</h1>
                                                                    <span>{vo.ville_arrive_nom}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-between">
                                                            <p className='voyage-text'>Départ : <span className='voyage-important'>{moment(vo.date_depart).format('HH[h] mm')}</span> {formatDateClass(vo.date_depart)}</p>
                                                            <p className='voyage-text'>Places libres : <span className='voyage-important'>{vo.place_libre}</span></p>
                                                        </div>
                                                    </div>
                                                    <Carseat
                                                        auth={auth}
                                                        selectedSeats={selectedSeats}
                                                        setSelectedSeats={setSelectedSeats}
                                                        seats={vo.reservations}
                                                        reservations={vo.reservations}
                                                        setSelectedClient={setSelectedClient}
                                                        handleUserSelection={handleUserSelection} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}
