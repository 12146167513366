import { useState } from 'react'
import { Link } from 'react-router-dom'
import StateBlog from './StateBlog'

export default function ActionBlog({ auth, blog, updateData }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState("")

    return (
        <>
            {showConfirmModal &&
                <StateBlog
                    actionType={actionType}
                    blog={blog}
                    updateData={() => updateData()}
                    closeModal={() => setShowConfirmModal(false)} />
            }
            <div className='action-container'>
                {
                    (auth.id === blog.user_id) &&
                    <span>
                        <Link to={"/app/blog/edit/" + blog.id}>Editer</Link>
                    </span>
                }
                {
                    ((auth.id === blog.user_id && ["BROUILLON"].includes(blog.status))) &&
                    <span onClick={() => { setShowConfirmModal(true); setActionType("do_publish") }}>
                        Publié
                    </span>
                }
                {
                    ((auth.id === blog.user_id && ["PUBLIE"].includes(blog.status))) &&
                    <span onClick={() => { setShowConfirmModal(true); setActionType("do_draft") }}>
                        Remettre en brouillon
                    </span>
                }
                {
                    (["superadmin"].includes(auth.role) || auth.id === blog.user_id) &&
                    <span onClick={() => { setShowConfirmModal(true); setActionType("do_delete") }}>
                        Supprimer
                    </span>
                }
            </div>
        </>
    )
}