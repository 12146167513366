import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionSite from './ActionSite'
import ShowTab from '../../view/ShowTab'
import { MdOutlineLocationOn } from "react-icons/md"
import { MdOutlinePhoneForwarded } from "react-icons/md"
import { FaUserTie } from "react-icons/fa"
import { PiUserCirclePlusFill } from "react-icons/pi"

export default function ShowSite({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [site, setSite] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/site/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setSite(res.data.site)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(site)
    }, [site])

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('site-channel')
            .listen('.updated', (event) => {
                setSite(prevSite => {
                    if (prevSite && prevSite.id === event.site.id) {
                        return { ...prevSite, ...event.site }
                    }
                    return prevSite
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {site &&
                    <div className='view-show-container'>
                        <ShowHeader data={site} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <h3>{site.designation}</h3>
                            {auth.role === "superadmin" &&
                                <p className="important">{site.cooperative}</p>
                            }
                            <p><MdOutlineLocationOn size={16} className='svg-text' /> <span className='text'>{site.adresse}</span>, Ville : <span className='text'>{site.ville}</span>, Province : <span className='text'>{site.province}</span></p>
                            <p><FaUserTie size={16} className='svg-text' /> <span className='text'>{site.admin_site_nom + " " + site.admin_site_prenom + " <" + site.admin_site_email + ">"}</span></p>
                            <p><MdOutlinePhoneForwarded size={16} className='svg-text' /> <span className='text'>{formatPhoneNumber(site.admin_site_telephone) + (site.guichet_unique ? ', Guichet unique' : '')}</span> </p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{site.createur_prenom + " <" + site.createur_email + ">"}</span></p>
                            <ActionSite auth={auth} site={site} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="site_id" value={site.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
