import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputCooperative from '../../components/input/InputCooperative'
import InputGare from '../../components/input/InputGare'
import InputUser from '../../components/input/InputUser'
import InputCheckbox from '../../components/input/InputCheckbox'
import Btn from '../../components/button/Btn'

export default function EditSite({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [designation, setDesignation] = useState("")
    const [cooperative, setCooperative] = useState(null)
    const [gare, setGare] = useState(null)
    const [ville, setVille] = useState(null)
    const [adminSite, setAdminSite] = useState(null)
    const [telephoneTelma, setTelephoneTelma] = useState("")
    const [telephoneOrange, setTelephoneOrange] = useState("")
    const [telephoneAirtel, setTelephoneAirtel] = useState("")
    const [guichetUnique, setGuichetUnique] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const validPhoneTelma = /^(034|038)\d{7}$/
    const validPhoneOrange = /^(032|037)\d{7}$/
    const validPhoneAirtel = /^(033)\d{7}$/

    useEffect(() => {
        const isBasicInvalidData = !gare || !designation.trim() || !adminSite || !validPhoneTelma.test(telephoneTelma)
            || !validPhoneTelma.test(telephoneTelma) || !validPhoneOrange.test(telephoneOrange) || !validPhoneAirtel.test(telephoneAirtel) || loading
        setBtnDisabled(
            auth.role === "superadmin" ?
                isBasicInvalidData || !cooperative
                :
                isBasicInvalidData
        )
    }, [gare, designation, cooperative, adminSite, telephoneTelma, telephoneOrange, telephoneAirtel, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            designation: designation,
            cooperative_id: cooperative ? cooperative.id : '',
            ville_id: ville ? ville.id : '',
            gare_routiere_id: gare ? gare.id : '',
            admin_site_id: adminSite ? adminSite.id : '',
            telephone_telma: telephoneTelma,
            telephone_orange: telephoneOrange,
            telephone_airtel: telephoneAirtel,
            guichet_unique: guichetUnique
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/site/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setDesignation("")
                setCooperative(null)
                setVille(null)
                setGare(null)
                setAdminSite(null)
                setTelephoneTelma("")
                setTelephoneOrange("")
                setTelephoneAirtel("")
                setGuichetUnique(false)
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/site')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/site/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const site = res.data.site
                    if (site) {
                        if (site.designation) setDesignation(site.designation)
                        if (site.cooperative) setCooperative({
                            id: site.cooperative_id,
                            designation: site.cooperative,
                        })
                        if (site.ville) setVille({
                            id: site.ville_id,
                            nom: site.ville,
                        })
                        if (site.gare_routiere) setGare({
                            id: site.gare_routiere_id,
                            designation: site.gare_routiere,
                        })
                        if (site.admin_site_id) setAdminSite({
                            id: site.admin_site_id,
                            prenom: site.admin_site_prenom,
                            email: site.admin_site_email
                        })
                        if (site.guichet_unique) setGuichetUnique(site.guichet_unique)
                        if (site.telephone_telma) setTelephoneTelma(site.telephone_telma)
                        if (site.telephone_orange) setTelephoneOrange(site.telephone_orange)
                        if (site.telephone_airtel) setTelephoneAirtel(site.telephone_airtel)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/site')
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid-two">
                        <InputGare
                            label="Gare routière"
                            value={gare}
                            onChange={setGare}
                            required
                        />
                        <InputText
                            label="Designation"
                            placeholder="Saisir la désignation du site"
                            value={designation}
                            onChange={setDesignation}
                            className='large'
                            required
                        />
                    </div>
                    <div className="grid-two">
                        {['superadmin'].includes(auth.role) &&
                            <InputCooperative
                                value={cooperative}
                                onChange={setCooperative}
                                required
                            />
                        }

                        <InputUser
                            label="Responsable du site"
                            role="admin_site"
                            value={adminSite}
                            onChange={setAdminSite}
                            required
                            adminSiteNull
                        />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label="Mobile money Telma"
                            placeholder="Saisir le numéro Telma"
                            value={telephoneTelma}
                            onChange={setTelephoneTelma}
                            className='large'
                            required />
                        <InputText
                            label="Mobile money Orange"
                            placeholder="Saisir le numéro Orange"
                            value={telephoneOrange}
                            onChange={setTelephoneOrange}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label="Mobile money Airtel"
                            placeholder="Saisir le numéro Airtel"
                            value={telephoneAirtel}
                            onChange={setTelephoneAirtel}
                            className='large'
                            required />
                    </div>
                    <div className="grid-one">
                        <InputCheckbox
                            label="Guichet unique"
                            name="guichet_unique"
                            checked={guichetUnique}
                            onChange={setGuichetUnique}
                        />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/site/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
