import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import USER_GROUP from '../../assets/PROFILE/USER_GROUP.png'
import moment from 'moment'

export default function Conversation({ auth, conversations, setConversations, currentId, setCurrentId, setItemTitle, messages }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const locationSearch = useLocation().search
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    useEffect(() => {
        const params = new URLSearchParams(locationSearch)
        if (params.get("id")) {
            setCurrentId(params.get("id"))
        } else {
            setCurrentId(null)
        }
    }, [])

    const handleClearParams = () => {
        const params = new URLSearchParams(locationSearch)
        if (params) {
            params.delete("id")
            navigate({
                pathname: pathname,
                search: params.toString()
            })
        }
    }

    const formatDates = (conversations) => {
        const formattedDates = conversations.map(conv => moment(conv.created_at).from(conv.datetime))
        setFormattedDates(formattedDates)
    }

    let searchItems = [
        { label: 'Detail', name: 'detail', type: 'string' }
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", conversations.length)
        }

        try {
            const res = await useApi.get('/conversation' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setConversations(res.data.conversations)
                    } else {
                        const list = conversations.slice().concat(res.data.conversations)
                        setConversations(list)
                    }
                    setallDataLoaded(res.data.conversations.length < 30)
                    formatDates(res.data.conversations)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch, messages])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(conversations)
        const interval = setInterval(() => {
            formatDates(conversations)
        }, 60000)
        return () => clearInterval(interval)
    }, [conversations])

    useEffect(() => {
        window.Echo.channel('conversation-channel')
            .listen('.created', (event) => {
                setConversations(prevConversations => {
                    if (!prevConversations.some(conversation => conversation.id === event.conversation.id)) {
                        return [event.conversation, ...prevConversations]
                    }
                    return prevConversations
                });

            })
            .listen('.updated', (event) => {
                setConversations(prevConversations => prevConversations.map(conversation => {
                    if (conversation.id === event.conversation.id) {
                        return event.conversation
                    } else {
                        return conversation
                    }
                }))
            })
    }, [conversations])

    const handleMarkSeen = async (conversationId) => {
        try {
            const response = await useApi.post(`/seen/mark_as_seen/conversation_id/${conversationId}`, {}, tokenHeaders)

            if (response.data.error) {
                console.error(response.data.error)
            } else if (response.data.success) {
                console.error(null)
                updateData(true)
            }
        } catch (error) {
            console.error(error)
            console.error("Erreur d'envoi, réessayez.")
        }
    }

    return (
        <>
            <ViewHeader
                label="Messages"
                searchItems={searchItems}
                authorisation={false} />
            {loading ? <PageLoader />
                :
                <>
                    {conversations.length === 0 ? <h3 className='justify-center'>Aucun message trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={conversations.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                conversations.map((conv, index) => (
                                    <div className={`conversation-card ${currentId && currentId === conv.id ? 'selected' : ''}`} key={index} onClick={() => {
                                        handleMarkSeen(conv.id);
                                        setCurrentId(conv.id);
                                        handleClearParams();
                                    }} >
                                        <div className="img-container">
                                            <img src={
                                                conv.recipient_id ?
                                                    (
                                                        conv.recipient_id === auth.id ?
                                                            (conv.createur_photo ? useFileUrl + conv.createur_photo
                                                                : (conv.createur_sexe === "homme" ? HOMME : FEMME))
                                                            :
                                                            (conv.recipient_photo ? useFileUrl + conv.recipient_photo
                                                                : (conv.recipient_sexe === "homme" ? HOMME : FEMME))
                                                    )
                                                    :
                                                    USER_GROUP
                                            } alt="img" />
                                        </div>
                                        <div className='detail-container'>
                                            <h4>{conv.recipient_id ? (conv.recipient_id === auth.id ? conv.createur_prenom : conv.recipient_prenom) : conv.titre}</h4>
                                            <p className={(conv.seen || conv.message_user_id === auth.id) ? '' : 'unread'}>
                                                {conv.contenu}
                                            </p>
                                            <span>{moment(conv.created_at).fromNow()}</span>
                                        </div>

                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
