import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import VEHICULE from '../../assets/PROFILE/VEHICULE.png'

export default function Voyage({ auth, voyages, setVoyages, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    const formatDates = (voyages) => {
        const formattedDates = voyages.map(vo => moment(vo.created_at).from(vo.datetime))
        setFormattedDates(formattedDates)
    }

    let searchItems =
        ["superadmin"].includes(auth.role) ?
            [
                { label: 'Identifiant', name: 'id', type: 'string' },
                { label: 'Detail', name: 'detail', type: 'string' },
                { label: "Gare de départ", name: 'gare_routiere_id', type: 'string' },
                { label: 'Coopérative', name: 'cooperative_id', type: 'string' },
                { label: 'Trajet', name: 'trajet_id', type: 'string' },
                { label: 'Ville de départ', name: 'ville_depart_id', type: 'string' },
                { label: "Ville d'arrivé ", name: 'ville_arrive_id', type: 'string' },
                { label: 'Créateur', name: 'user_id', type: 'string' },
                { label: 'Date de création', name: 'created_at', type: 'date' },
            ]
            :
            [
                { label: 'Identifiant', name: 'id', type: 'string' },
                { label: 'Detail', name: 'detail', type: 'string' },
                { label: 'Trajet', name: 'trajet_id', type: 'string' },
                { label: 'Ville de départ', name: 'ville_depart_id', type: 'string' },
                { label: "Ville d'arrivé ", name: 'ville_arrive_id', type: 'string' },
                { label: 'Créateur', name: 'user_id', type: 'string' },
                { label: 'Date de création', name: 'created_at', type: 'date' },
            ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", voyages.length)
        }

        try {
            const res = await useApi.get('/voyage' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setVoyages(res.data.voyages)
                    } else {
                        const list = voyages.slice().concat(res.data.voyages)
                        setVoyages(list)
                    }
                    setallDataLoaded(res.data.voyages.length < 30)
                    formatDates(res.data.voyages)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(voyages)
        const interval = setInterval(() => {
            formatDates(voyages)
        }, 60000)
        return () => clearInterval(interval)
    }, [voyages])

    useEffect(() => {
        window.Echo.channel('voyage-channel')
            .listen('.created', (event) => {
                setVoyages(prevVoyages => {
                    if (!prevVoyages.some(voyage => voyage.id === event.voyage.id)) {
                        return [event.voyage, ...prevVoyages]
                    }
                    return prevVoyages
                })

            })
            .listen('.updated', (event) => {
                setVoyages(prevVoyages => prevVoyages.map(voyage => {
                    if (voyage.id === event.voyage.id) {
                        return event.voyage
                    } else {
                        return voyage
                    }
                }))
            })
    }, [voyages])

    return (
        <>
            <ViewHeader
                label="Voyages"
                searchItems={searchItems}
                link="/app/voyage/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {voyages.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={voyages.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                voyages.map((vo, index) => (
                                    <div className={`view-card-container ${currentId && currentId == vo.id ? 'selected' : ''}`} key={vo.id} onClick={() => setCurrentId(vo.id)}>
                                        <div className="view-card-space-between">
                                            <div className="view-card-with-img">
                                                <div className="img-container">
                                                    <img src={vo.vehicule_photo ? useFileUrl + vo.vehicule_photo : VEHICULE} alt="img" />
                                                </div>
                                                <div className="card-with-img-detail">
                                                    <div className="badge">
                                                        <div
                                                            className={`badge-shape ${vo.status == "EN ATTENTE" ? "bg-purple"
                                                                : vo.status == "VALIDE" ? "bg-blue"
                                                                    : vo.status == "ANNULE" ? "bg-red"
                                                                        : vo.status == "REPORTE" ? "bg-pink"
                                                                            : vo.status == "EN ROUTE" ? "bg-yellow"
                                                                                : vo.status == "EN RETARD" ? "bg-orange"
                                                                                    : "bg-green"}`
                                                            }
                                                        />
                                                        {vo.status}
                                                    </div>

                                                    <h2>{vo.designation}</h2>
                                                    <p>{formatPrice(vo.tarif_prix) + (vo.vehicule_categorie ? ", " + vo.vehicule_categorie : "") + ", " + moment(vo.date_depart).format('D MMMM YYYY [à] HH[h] mm')}</p>
                                                    {auth.role == "superadmin" &&
                                                        <p className='important'>{vo.cooperative}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='space-between contact-and-date'>
                                            <span>
                                                <span className='text'>{" <" + vo.createur_email + ">"}</span>
                                            </span>
                                            <span className='secondary'>{formattedDates[index]}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
