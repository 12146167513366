import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import PageLoader from '../loader/PageLoader'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'

export default function Story({ auth, name, value, clientId, voyageId }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [historiques, setHistoriques] = useState([])
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (clientId && voyageId) {
            params.set('client_id', clientId)
            params.set('voyage_id', voyageId)
        } else {
            params.append(name, value)
        }
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", historiques.length)
        }

        try {
            const res = await useApi.get((clientId && voyageId ? '/historique/tab_guichet' : '/historique/tab') + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setHistoriques(res.data.historiques)
                    } else {
                        const list = historiques.slice().concat(res.data.historiques)
                        setHistoriques(list)
                    }
                    setallDataLoaded(res.data.historiques.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('historique-channel')
            .listen('.created', (event) => {
                setHistoriques(prevHistoriques => {
                    if (!prevHistoriques.some(historique => historique.id === event.historique.id)) {
                        return [event.historique, ...prevHistoriques]
                    }
                    return prevHistoriques
                });

            })
            .listen('.updated', (event) => {
                setHistoriques(prevHistoriques => prevHistoriques.map(historique => {
                    if (historique.id === event.historique.id) {
                        return event.historique
                    } else {
                        return historique
                    }
                }))
            })
    }, [historiques])

    return (
        <>
            {loading ? <PageLoader />
                :
                <>
                    {historiques.length == 0 ? <h3 className='justify-center'>Aucun données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={historiques.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                historiques.map((h) => (
                                    <div className='story-container' key={h.id}>
                                        <h4>{h.objet}</h4>
                                        {
                                            h.note &&
                                            <p className='story-note'>
                                                " {h.note} "
                                            </p>
                                        }
                                        {
                                            h.detail &&
                                            <p className='trace'>{h.detail.split('\\n').map((d, index) => <span key={index}>{d}<br /></span>)}</p>
                                        }
                                        <div className='story-footer'>
                                            <span>{h.user}</span>
                                            <span>{moment(h.created_at).format("DD MMM YY à HH:mm")}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
