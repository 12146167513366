import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import Btn from '../../components/button/Btn'


export default function EditVille({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const provinceList = [
        'ANTANANARIVO',
        'ANTSIRANANA',
        'FIANARANTSOA',
        'MAHAJANGA',
        'TOAMASINA',
        'TOLIARA'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [nom, setNom] = useState("")
    const [province, setProvince] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(!nom.trim() || !province.trim() || loading)
    }, [nom, province, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            nom: nom,
            province: province
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : ""),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setNom("")
                setProvince("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/ville')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/ville/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const ville = res.data.ville
                    if (ville) {
                        if (ville.nom) setNom(ville.nom)
                        if (ville.province) setProvince(ville.province)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/ville')
    }

    return (
        <div className="view-content">
            <div className='view-regular'>
                <div className="vertical-align">
                    <div style={{ paddingRight: "10px" }} onClick={goBack}>
                        <IoMdArrowBack size={26} className='pointer' />
                    </div>
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <InputText
                        label='Nom'
                        placeholder='Saisir le nom'
                        value={nom}
                        onChange={setNom}
                        className='large'
                        required />
                    <InputSelect
                        label='Province'
                        selected={province}
                        setSelected={setProvince}
                        options={provinceList}
                        className='large'
                        required />
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/ville/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
