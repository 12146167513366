import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputCooperative from '../../components/input/InputCooperative'
import InputVille from '../../components/input/InputVille'
import InputUser from '../../components/input/InputUser'
import Btn from '../../components/button/Btn'

export default function EditGare({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [designation, setDesignation] = useState("")
    const [adresse, setAdresse] = useState("")
    const [ville, setVille] = useState(null)
    const [respGare, setRespGare] = useState(null)
    const [respCaisse, setRespCaisse] = useState(null)
    const [telephoneTelma, setTelephoneTelma] = useState("")
    const [telephoneOrange, setTelephoneOrange] = useState("")
    const [telephoneAirtel, setTelephoneAirtel] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)
    const validPhoneTelma = /^(034|038)\d{7}$/
    const validPhoneOrange = /^(032|037)\d{7}$/
    const validPhoneAirtel = /^(033)\d{7}$/

    useEffect(() => {
        setBtnDisabled(
            !designation.trim() || !adresse.trim() || !ville || !respGare || !respCaisse
            || !validPhoneTelma.test(telephoneTelma) || !validPhoneOrange.test(telephoneOrange) || !validPhoneAirtel.test(telephoneAirtel)
            || loading
        )
    }, [designation, adresse, ville, respGare, respCaisse, telephoneTelma, telephoneOrange, telephoneAirtel, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            designation: designation,
            adresse: adresse,
            ville_id: ville ? ville.id : '',
            resp_gare_id: respGare ? respGare.id : '',
            resp_caisse_id: respCaisse ? respCaisse.id : '',
            telephone_telma: telephoneTelma,
            telephone_orange: telephoneOrange,
            telephone_airtel: telephoneAirtel
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/gare/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setDesignation("")
                setAdresse("")
                setVille(null)
                setRespGare(null)
                setRespCaisse(null)
                setTelephoneTelma("")
                setTelephoneOrange("")
                setTelephoneAirtel("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/gare')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/gare_routiere/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const gare = res.data.gare_routiere
                    if (gare) {
                        if (gare.designation) setDesignation(gare.designation)
                        if (gare.adresse) setAdresse(gare.adresse)
                        if (gare.ville) setVille({
                            id: gare.ville_id,
                            nom: gare.ville,
                        })
                        if (gare.resp_gare_id) setRespGare({
                            id: gare.resp_gare_id,
                            prenom: gare.resp_gare_prenom,
                            email: gare.resp_gare_email
                        })
                        if (gare.resp_caisse_id) setRespCaisse({
                            id: gare.resp_caisse_id,
                            prenom: gare.resp_caisse_prenom,
                            email: gare.resp_caisse_email
                        })
                        if (gare.telephone_telma) setTelephoneTelma(gare.telephone_telma)
                        if (gare.telephone_orange) setTelephoneOrange(gare.telephone_orange)
                        if (gare.telephone_airtel) setTelephoneAirtel(gare.telephone_airtel)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/gare')
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid-two">
                        <InputText
                            label="Designation"
                            placeholder="Saisir la désignation du gare"
                            value={designation}
                            onChange={setDesignation}
                            className='large'
                            required />
                        <InputText
                            label="Adresse"
                            placeholder="Saisir l'adresse"
                            value={adresse}
                            onChange={setAdresse}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputVille
                            label="ville d'emplacement"
                            value={ville}
                            onChange={setVille}
                        />
                        <InputUser
                            label="Responsable du gare routière"
                            role="resp_gare"
                            value={respGare}
                            onChange={setRespGare}
                            respGareNull
                        />
                    </div>
                    <div className="grid-two">
                        <InputUser
                            label="Responsable guichet unique"
                            role="resp_caisse"
                            value={respCaisse}
                            onChange={setRespCaisse}
                            respCaisseNull
                        />
                        <InputText
                            label="Mobile money Telma"
                            placeholder="Saisir le numéro Telma"
                            value={telephoneTelma}
                            onChange={setTelephoneTelma}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label="Mobile money Orange"
                            placeholder="Saisir le numéro Orange"
                            value={telephoneOrange}
                            onChange={setTelephoneOrange}
                            className='large'
                            required />
                        <InputText
                            label="Mobile money Airtel"
                            placeholder="Saisir le numéro Airtel"
                            value={telephoneAirtel}
                            onChange={setTelephoneAirtel}
                            className='large'
                            required />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/gare/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
