import Sidebar from "./Sidebar"

export default function SidebarModal({auth,setShowSidebar,setShowLogoutModal}) {
    return (
        <div className="sidebar-modal">
            <Sidebar 
                auth={auth} 
                setShowSidebar={setShowSidebar} 
                setShowLogoutModal={setShowLogoutModal}/>
        </div>
    )
}
