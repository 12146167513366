import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import BLOG from '../../assets/PROFILE/BLOG.jpg'
import PageLoader from '../../components/loader/PageLoader'
import moment, { now } from 'moment/moment'
import { CgClose } from 'react-icons/cg'
import { FaCircle } from "react-icons/fa"
import BlogCommentaire from './BlogCommentaire'
import ActionBlog from './ActionBlog'

export default function ShowBlog({ auth }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [blog, setBlog] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/blog/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setBlog(res.data.blog)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }


    useEffect(() => {
        updateData()
    }, [params.id])

    useEffect(() => {
        window.Echo.channel('blog-channel')
            .listen('.updated', (event) => {
                setBlog(prevBlog => {
                    if (prevBlog && prevBlog.id === event.blog.id) {
                        return { ...prevBlog, ...event.blog }
                    }
                    return prevBlog
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <div className="show-blog">
                <div className="show-blog-img">
                    <div className="space-between">
                        <h3>Détails du blog</h3>
                        <span><CgClose className='pointer' size={30} onClick={() => navigate('/app/blog')} /></span>
                    </div>
                    <div className="img-container">
                        <img src={blog.photo ? useFileUrl + blog.photo : BLOG} alt="img" />
                    </div>
                </div>
                <div className="show-blog-details">
                    <div className="card-blog-details">
                        <div className='categorie'>
                            <span className='important'>{blog.categorie}</span>
                            <FaCircle size={5} className='separator' />
                        </div>
                        <h1>{blog.titre}</h1>
                        <p>{blog.contenu}</p>
                        <div className="blog-details-footer">
                            <div className="img-container">
                                <img src={
                                    blog.createur_photo ? useFileUrl + blog.createur_photo
                                        : (blog.createur_sexe == "homme" ? HOMME : FEMME)
                                } alt="img" />
                            </div>
                            <p>
                                {blog.createur_prenom}
                                <FaCircle size={5} className='separator' />
                                {moment(blog.created_at).fromNow()}
                            </p>
                        </div>
                    </div>
                    <ActionBlog auth={auth} blog={blog} updateData={updateData} />
                    <BlogCommentaire auth={auth} blogId={blog.id} />
                </div>
            </div >
    )
}
