import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import RateStar from '../../components/ratestar/RateStar'
import InputTextArea from '../../components/input/InputTextArea'
import Btn from '../../components/button/Btn'
import ScreenLoader from '../../components/loader/ScreenLoader'
import { CgClose } from 'react-icons/cg'

export default function EditNote({ auth, voyageId, showEdit, setShowEdit, chauffeurSecondId, updateData, editAction }) {

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [noteId, setNoteId] = useState(null)
    const [noteVehicule, setNoteVehicule] = useState(0)
    const [noteChauffeur, setNoteChauffeur] = useState(0)
    const [noteChauffeurSecond, setNoteChauffeurSecond] = useState(0)
    const [noteCooperative, setNoteCooperative] = useState(0)
    const [commentaire, setCommentaire] = useState("")

    useEffect(() => {
        if (!showEdit) {
            setNoteVehicule(0)
            setNoteChauffeur(0)
            setNoteChauffeurSecond(0)
            setNoteCooperative(0)
            setCommentaire("")
        }
    }, [showEdit])

    const closeModal = () => {
        setNoteVehicule(0)
        setNoteChauffeur(0)
        setNoteChauffeurSecond(0)
        setNoteCooperative(0)
        setCommentaire("")
        setShowEdit(false)
    }

    const fetchNote = () => {
        let isMounted = true
        setLoading(true)
        useApi.get(`/note/show/${voyageId}`, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const note = res.data.note
                    if (note) {
                        if (note.id) setNoteId(note.id)
                        if (note.note_vehicule) setNoteVehicule(note.note_vehicule)
                        if (note.note_chauffeur) setNoteChauffeur(note.note_chauffeur)
                        if (note.note_chauffeur_second) setNoteChauffeurSecond(note.note_chauffeur_second)
                        if (note.note_cooperative) setNoteCooperative(note.note_cooperative)
                        if (note.commentaire) setCommentaire(note.commentaire)
                    }

                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(() => {
        fetchNote()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (editAction && !noteId) {
            toast.error("Aucune note trouvé")
            return
        }

        const data = {
            voyage_id: voyageId,
            note_vehicule: noteVehicule,
            note_chauffeur: noteChauffeur,
            note_chauffeur_second: noteChauffeurSecond,
            note_cooperative: noteCooperative,
            commentaire: commentaire
        }

        try {
            const response = await useApi.post(
                editAction ? `/note/edit/${noteId}` : `/note/add`,
                data, tokenHeaders)
            if (response.data.error) {
                setError(response.data.error)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setNoteVehicule(0)
                setNoteChauffeur(0)
                setNoteChauffeurSecond(0)
                setNoteCooperative(0)
                setCommentaire("")
                setLoading(false)
                toast.success(response.data.success)
                updateData()
                setShowEdit(false)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
        }
    }

    return (
        loading ? <ScreenLoader />
            :
            <div className='input-modal'>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="space-between">
                            <h3>Note de voyage</h3>
                            <CgClose className='pointer' size={22} onClick={() => closeModal()} />
                        </div>
                        <RateStar
                            label="Véhicule"
                            value={noteVehicule}
                            onChange={setNoteVehicule}
                            required
                            edit />
                        <RateStar
                            label="Chauffeur"
                            value={noteChauffeur}
                            onChange={setNoteChauffeur}
                            required
                            edit />
                        {chauffeurSecondId &&
                            <RateStar
                                label="Chauffeur Second"
                                value={noteChauffeurSecond}
                                onChange={setNoteChauffeurSecond}
                                required
                                edit />
                        }
                        <RateStar
                            label="Coopérative"
                            value={noteCooperative}
                            onChange={setNoteCooperative}
                            required
                            edit />
                        <InputTextArea
                            label="Commentaire"
                            placeholder="Saisir votre commentaire ici"
                            value={commentaire}
                            onChange={setCommentaire}
                            className='large'
                            rows="7" />
                        <div className="justify-center">
                            <Btn
                                label={loading ? "Chargement..." : "Confirmer"}
                                className='btn-primary small'
                                type='submit'
                                disabled={loading}
                            />
                        </div>
                    </form>
                </div>
            </div >

    )
}
