import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import InputText from '../input/InputText'
import Btn from '../button/Btn'
import PageLoader from '../loader/PageLoader'
import { IoClose } from "react-icons/io5"
import { MdOutlineAddCircleOutline } from "react-icons/md"
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"

export default function InputUserMultiple({ label, values, onChange, required, hideInput, closeModal, disabled, useLink, role, type, selectedUser }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const location = useLocation()
    const locationSearch = useLocation().search
    const [showModal, setShowModal] = useState(false)
    const [users, setUsers] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setAllDataLoaded] = useState(false)

    useEffect(() => {
        if (selectedUser) {
            onChange(selectedUser)
        }
    }, [selectedUser, onChange])

    const handleSelectUser = (u) => {
        const isAlreadySelected = values.some(user => user.id === u.id)
        const updatedValues = isAlreadySelected ? values.filter(user => user.id !== u.id) : [...values, u]

        onChange(updatedValues)
        if (closeModal) closeModal()
    }

    const handleRemoveUser = (userId) => {
        const updatedValues = values.filter(user => user.id !== userId)
        onChange(updatedValues)
    }

    const handleCloseModal = () => {
        setShowModal(false)
        if (closeModal) closeModal()
    }

    const updateData = (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (role) params.set("role", role)
        if (type) params.set("type", type)
        params.set("account", searchValue)
        if (initial) {
            setLoading(true)
            setAllDataLoaded(false)
            params.set("offset", 0)
        } else {
            params.set("offset", users.length)
        }

        useApi.get('/utilisateur?' + params, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    if (res.data.error) {
                        console.error(res.data.error)
                    } else {
                        if (initial) {
                            setUsers(res.data.utilisateurs)
                        } else {
                            setUsers(prevUsers => [...prevUsers, ...res.data.utilisateurs])
                        }
                        setAllDataLoaded(res.data.utilisateurs.length < 30)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('utilisateur-channel')
            .listen('.created', (event) => {
                setUsers(prevUsers => {
                    if (!prevUsers.some(user => user.id === event.utilisateur.id)) {
                        return [event.utilisateur, ...prevUsers]
                    }
                    return prevUsers
                })
            })
            .listen('.updated', (event) => {
                setUsers(prevUsers => prevUsers.map(user => {
                    if (user.id === event.utilisateur.id) {
                        return event.utilisateur
                    } else {
                        return user
                    }
                }))
            })
    }, [users])

    return (
        <>
            {!hideInput &&
                <div>
                    {(values && values.length === 0) ?
                        <InputText
                            label={label ? label : "Utilisateur(s)"}
                            placeholder='Cliquer pour chercher'
                            value=""
                            disabled={disabled}
                            className='large'
                            required={required}
                            readOnly
                            onClick={() => setShowModal(true)}
                        />
                        :
                        <div style={{ marginTop: '10px', marginBottom: '9px' }}>
                            <label htmlFor="selected-users">{label ? label : "Utilisateur(s)"} {required && <span className='danger'>*</span>}</label>
                            <div className="selected-users" id='selected-users'>
                                {values.map(user => (
                                    <div key={user.id} className="selected-user">
                                        <span>{user.prenom} {user.nom}</span>
                                        <IoClose size={18} onClick={() => handleRemoveUser(user.id)} />
                                    </div>
                                ))}
                                {(values && values.length > 0) &&
                                    <MdOutlineAddCircleOutline size={18} onClick={() => setShowModal(true)} />
                                }
                            </div>
                        </div>
                    }
                </div>
            }
            {(hideInput || showModal) &&
                <div className='input-modal'>
                    <div>
                        <div>
                            <InputText
                                label={label ? label : "Utilisateur"}
                                placeholder='Saisir votre recherche'
                                value={searchValue}
                                onChange={setSearchValue}
                                className='large'
                                required={required}
                            />
                            <div className="justify-right">
                                <Btn
                                    label={values && values.length > 0 ? "OK" : "Annuler"}
                                    className='btn-outline small'
                                    onClick={handleCloseModal}
                                />
                            </div>
                        </div>
                        {loading ? <PageLoader />
                            :
                            <>
                                {users.length === 0 ? <h3 className='justify-center'>Aucune donnée trouvée</h3>
                                    :
                                    <div className="list-modal-container">
                                        <InfiniteScroll
                                            dataLength={users.length}
                                            next={fetchMoreData}
                                            hasMore={!allDataLoaded}
                                            loader={<PageLoader />}
                                        >
                                            {
                                                users.map((u) => (
                                                    <div
                                                        className={`list-modal ${values.some(user => user.id === u.id) ? 'selected' : ''}`}
                                                        key={u.id}
                                                        onClick={() => handleSelectUser(u)}
                                                    >
                                                        <div className="list-modal-with-img">
                                                            <div className="img-container">
                                                                <img src={u.profil ? useFileUrl + u.profil : (u.sexe == "homme" ? Homme : Femme)} alt="img" />
                                                            </div>
                                                            <div className="list-modal-details">
                                                                <h3>{u.nom + " " + u.prenom}</h3>
                                                                <span>{u.email}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}
