import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import StateCooperative from './StateCooperative'

export default function ActionCooperative({auth, cooperative, updateData}) {
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    
    return (
        <>
            {showConfirmModal && 
                <StateCooperative
                    cooperative={cooperative} 
                    updateData={() => updateData()} 
                    closeModal={() => setShowConfirmModal(false)}/> 
            }
            <div className='action-container'>
                {
                    (["superadmin"].includes(auth.role)) && 
                    <span>
                        <Link to={"/app/cooperative/edit/" + cooperative.id}>Editer</Link>
                    </span>
                }
                {
                    (["superadmin"].includes(auth.role)) &&
                    <span onClick={() => setShowConfirmModal(true)}>
                        {cooperative.status == "actif" ? "Suspendre" : "Activé"}
                    </span>
                }
            </div>
        </>
    )
}