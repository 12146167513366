import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APIProvider, Map, Marker, useAdvancedMarkerRef, InfoWindow } from '@vis.gl/react-google-maps'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useReactMapApiKey from '../../hooks/useMapApiKey'
import MyPostion from '../../assets/MARKER/MyPosition.png'
import ClusterMarker from '../../components/marker/ClusterMarker'
import PageLoader from '../../components/loader/PageLoader'

export default function MapView({ auth, action, title }) {

    const tokenHeaders = useToken()
    const apiKey = useReactMapApiKey()
    const fallbackPosition = { lat: -18.919463, lng: 47.526067 }
    const [userPosition, setUserPosition] = useState(null)
    const [myMarkerRef, myMarker] = useAdvancedMarkerRef()
    const [showMyInfo, setShowMyInfo] = useState(false)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [itemType, setItemType] = useState("site")
    const [items, setItems] = useState([])
    const [mapLoaded, setMapLoaded] = useState(false)

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    setUserPosition({ lat: latitude, lng: longitude })
                },
                (error) => {
                    console.error('Error getting user position:', error)
                    setUserPosition(fallbackPosition)
                }
            )
        } else {
            console.error('Geolocation is not supported by this browser.')
            setUserPosition(fallbackPosition)
        }
    }, [])

    const updateData = async () => {
        let isMounted = true
        setLoading(true)

        try {
            const res = await useApi.get(`/${itemType}/localisation/`, tokenHeaders)

            if (isMounted) {
                setItems(res.data[`${itemType}s`])
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData() }, [])

    useEffect(() => {
        window.Echo.channel(`${itemType}-channel`)
            .listen('.created', (event) => {
                setItems(prevItems => {
                    if (!prevItems.some(item => item.id === event[itemType].id)) {
                        return [event[itemType], ...prevItems]
                    }
                    return prevItems
                })

            })
            .listen('.updated', (event) => {
                setItems(prevItems => prevItems.map(item => {
                    if (item.id === event[itemType].id) {
                        return event[itemType]
                    } else {
                        return item
                    }
                }))
            })
    }, [itemType])

    useEffect(() => {
        if (mapLoaded) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [mapLoaded])

    return (
        (loading) ? <PageLoader />
            :
            <>
                <div className="map-title">

                </div>
                <div className="map-view-container">
                    <APIProvider apiKey={apiKey}>
                        <Map
                            defaultCenter={userPosition}
                            defaultZoom={4}
                            gestureHandling={'greedy'}
                            fullscreenControl={false}
                            onLoad={() => setMapLoaded(true)}
                        >
                            <>
                                {userPosition &&
                                    <>
                                        <Marker
                                            ref={myMarkerRef}
                                            position={userPosition}
                                            icon={{ url: MyPostion, scaledSize: { width: 23, height: 30 } }}
                                            onClick={() => setShowMyInfo(true)} />
                                        {showMyInfo &&
                                            <InfoWindow
                                                onCloseClick={() => setShowMyInfo(false)}
                                                anchor={myMarker}>
                                                <p>Votre position</p>
                                            </InfoWindow>
                                        }
                                    </>
                                }
                                {items && <ClusterMarker items={items} />}
                            </>
                        </Map>
                    </APIProvider>
                </div>
            </>
    )
}
