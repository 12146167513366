import React from 'react'
import { useEffect, useState } from 'react'

export default function CarseatPrint({ selectedSeats, setSelectedSeats, seats, setShowDetail, reservations, setSelectedClient, handleUserSelection }) {
    const [seatPerRowNb, setSeatPerRowNb] = useState(4)

    useEffect(() => {
        [12, 13, 18, 22].includes(seats.length) ? setSeatPerRowNb(4) : setSeatPerRowNb(5)
    }, [seats])

    // Ajouter des sièges fictifs si nécessaire
    const totalSeats = seats.length
    const seatsPerRow = seatPerRowNb
    const numRows = Math.ceil((totalSeats - 2) / seatsPerRow)


    // Calculer le nombre de sièges fictifs nécessaires pour compléter les rangées incomplètes
    const remainingSeats = (totalSeats - 2) % seatsPerRow;

    const seatsToAdd = remainingSeats === 3
        ? 1 : remainingSeats === 2
            ? 2 : remainingSeats === 1
                ? 3 : 0

    // Copier les sièges existants et ajouter les sièges fictifs à la fin
    const seatsWithFictional = [...seats];
    if (totalSeats == 12) {
        seatsWithFictional.unshift({ designation_place: "" });
        seatsWithFictional.splice(8, 0, { designation_place: "" });
    } else {
        for (let i = 0; i < seatsToAdd; i++) {
            if (numRows - 1 - i > 0) {
                seatsWithFictional.splice((numRows - 1 - i) * seatsPerRow - (seatsToAdd - i - 1), 0, { designation_place: "" })
            } else {
                seatsWithFictional.splice((numRows - 2 - i) * seatsPerRow - (seatsToAdd - i - 1), 0, { designation_place: "" })
            }
        }
    }
    // Diviser les sièges en groupes de 4 à partir du troisième siège
    const groupedSeats = []
    for (let i = 2; i < totalSeats; i += seatsPerRow) {
        groupedSeats.push(seatsWithFictional.slice(i, i + seatsPerRow))
    }

    // Créer des éléments de rangée basés sur les données de sièges groupées
    const carRows = groupedSeats.map((row, index) => (
        <div key={index} className="print-car-row">
            {row.map((seat, seatIndex) => (
                <span
                    key={seatIndex}
                    className={`print-seat ${selectedSeats.includes(seat) ? 'print-seat-selected' : ''}`}
                    onClick={() => handleSeatSelection(seat)}
                >
                    {seat.designation_place}
                </span>
            ))}
        </div>
    ))

    const handleSeatSelection = (seat) => {
        if (!seat.designation_place) {
            return
        }
        const hasAPSeats = selectedSeats.some(seat => ["ASSIGNE", "AVANCE", "PAYE"].includes(seat.status))
        if (["ASSIGNE", "AVANCE", "PAYE"].includes(seat.status)) {
            const isSameUser = selectedSeats.length > 0 && selectedSeats[0].client_id === seat.client_id
            setSelectedClient(seat)
            setShowDetail(true)
            const userReservations = reservations.filter(reservation => reservation.client_id === seat.client_id)
            setSelectedSeats([...userReservations])
            if (isSameUser) {
                setSelectedSeats([])
                setShowDetail(false)
                setSelectedClient({})
            } else {
                handleUserSelection(seat.client_id)
            }
        } else {
            setShowDetail(false)
            setSelectedClient({})
            let updatedSeats = []
            const seatIndex = selectedSeats.indexOf(seat)
            if (seatIndex === -1) {
                updatedSeats = [...selectedSeats, seat]
            } else {
                updatedSeats = selectedSeats.filter(s => s !== seat)
            }
            if (hasAPSeats) {
                updatedSeats = updatedSeats.filter(s => !["ASSIGNE", "AVANCE", "PAYE"].includes(s.status))
                setShowDetail(false)
                setSelectedClient({})
                handleUserSelection(seat.client_id)
            }
            setSelectedSeats(updatedSeats)
        }
    }

    return (
        <div className="print-carseat-container">
            <div className='print-carseat'>
                <div className='print-car-row'>
                    <span className='print-driver-seat'>Chauffeur</span>
                    {seatsWithFictional.slice(0, 2).map((seat, index) => (
                        <span
                            key={index}
                            className={`print-seat ${selectedSeats.includes(seat) ? 'print-seat-selected' : ''}`}
                            onClick={() => handleSeatSelection(seat)}
                        >
                            {seat.designation_place}
                        </span>
                    ))}
                </div>
                {carRows}
            </div>
        </div>
    )
}