import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import useFileUrl from '../../hooks/useFileUrl'
import usePrice from '../../hooks/usePrice'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionReservationClient from './ActionReservationClient'
import ShowTab from '../../view/ShowTab'
import COOPERATIVE from '../../assets/PROFILE/COOPERATIVE.png'
import moment from 'moment'

export default function ShowReservationClient({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const formatPrice = usePrice()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [reservation, setReservation] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/reservation/client_detail/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setReservation(res.data.reservation)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(reservation)
    }, [reservation]);

    useEffect(updateData, [currentId])

    return (
        loading ? <PageLoader />
            :
            <>
                {reservation &&
                    <div className='view-show-container'>
                        <ShowHeader data={reservation} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={reservation.cooperative_photo ? useFileUrl + reservation.cooperative_photo : COOPERATIVE} alt="img" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{reservation.cooperative}</h3>
                                    <p>
                                        <span>
                                            {
                                                reservation.total_payer === null ?
                                                    <span className="badge-outline badge-purple">
                                                        PAIEMENT EN ATTENTE
                                                    </span>
                                                    :
                                                    <span className={`badge-outline ${reservation.reste_a_payer > 0 ? 'badge-orange' : 'badge-green'}`}>
                                                        {reservation.reste_a_payer > 0 ? "AVANCE" : "PAYE"}
                                                    </span>
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <p>Contact coopérative </p>
                            <p>
                                - {formatPhoneNumber(reservation.cooperative_telephone_telma)} <br />
                                - {formatPhoneNumber(reservation.cooperative_telephone_orange)} <br />
                                - {formatPhoneNumber(reservation.cooperative_telephone_airtel)} <br />
                            </p>
                            <p>{reservation.trajet_designation}</p>
                            <p>Date du voyage : {moment(reservation.date_depart).format('D MMMM YYYY [à] HH[h] mm')}</p>
                            <p>{`Place${reservation.nombre_de_places > 1 ? 's' : ''}`} : {reservation.places + " (" + reservation.nombre_de_places + ")"}</p>
                            {reservation.cout_total &&
                                <p>Coût total : {formatPrice(reservation.cout_total)}</p>
                            }
                            {reservation.total_payer &&
                                <p>Montant payé : {formatPrice(reservation.total_payer)}</p>
                            }
                            {reservation.reste_a_payer > 0 &&
                                <p>Reste à payer : {formatPrice(reservation.reste_a_payer)}</p>
                            }
                            {reservation.total_payer > reservation.cout_total &&
                                <p>A remboursé : {formatPrice(reservation.total_payer - reservation.cout_total)}</p>
                            }
                            <ActionReservationClient auth={auth} reservation={reservation} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="guichet_id" clientId={reservation.client_id} voyageId={reservation.voyage_id} voyageStatus={reservation.voyage_status} />
                    </div >
                }
            </>

    )
}
