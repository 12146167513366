import { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import Logo from '../../assets/LOGO/LOGO_BLACKBG.svg'
import LogoMD from '../../assets/LOGO/LOGO_MD_BLACKBG.svg'
import useScreenSize from '../../hooks/useScreenSize'
import useClickOutside from '../../hooks/useClickOutside'
import { IoMdMenu } from 'react-icons/io'
import { IoClose } from 'react-icons/io5'
import { FaRegBell } from 'react-icons/fa6'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import { FiLogOut } from "react-icons/fi"
import { MdOutlineChatBubbleOutline } from "react-icons/md"
import { HiOutlineUserCircle } from "react-icons/hi"
import { TbEdit } from "react-icons/tb"
import ConfirmModal from '../../components/modal/ConfirmModal'
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import USER_GROUP from '../../assets/PROFILE/USER_GROUP.png'
import moment from 'moment'

export default function Navbar({ auth, showSidebar, setShowSidebar }) {
    const navigate = useNavigate()
    const locationSearch = useLocation().search
    const tokenHeaders = useToken()
    const screenSize = useScreenSize()
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [notifications, setNotifications] = useState([])
    const [notifNumber, setNotifNumber] = useState("")
    const [conversations, setConversations] = useState([])
    const [conversationNumber, setConversationNumber] = useState("")
    const [showLogoutModal, setShowLogoutModal] = useState(false)
    const [activeDropDown, setActiveDropDown] = useState("")
    const [showDropDown, setShowDropDown] = useState(false)
    const outsideDropdownRef = useRef(null)
    useClickOutside(outsideDropdownRef, () => { setShowDropDown(false); setActiveDropDown("") })

    const [notificationUnread, setNotificationUnread] = useState(false)
    const [conversationUnread, setConversationUnread] = useState(false)

    const handleLogout = () => {
        useApi.post('/logout', {}, tokenHeaders)
            .then((res) => {
                localStorage.removeItem('token')
                window.location.reload()
            })
    }

    const updateData = async () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        if (notificationUnread) params.set("notification_unread", notificationUnread)
        if (conversationUnread) params.set("conversation_unread", conversationUnread)
        try {
            const res = await useApi.get('/notification/latest' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setNotifications(res.data.notifications)
                    setNotifNumber(res.data.unread_notification_count)
                    setConversations(res.data.conversations)
                    setConversationNumber(res.data.unread_conversation_count)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }
        return () => { isMounted = false }
    }

    useEffect(() => {
        updateData()
    }, [pathname, notificationUnread, conversationUnread])

    useEffect(() => {
        updateData()
    }, [])

    useEffect(() => {
        window.Echo.channel('notification-channel')
            .listen('.created', (event) => {
                updateData()

            })
            .listen('.updated', (event) => {
                updateData()
            })
        window.Echo.channel('conversation-channel')
            .listen('.created', (event) => {
                updateData()
                setConversations(prevConversations => {
                    if (!prevConversations.some(conversation => conversation.id === event.conversation.id)) {
                        return [event.conversation, ...prevConversations]
                    }
                    return prevConversations
                });

            })
            .listen('.updated', (event) => {
                updateData()
                setConversations(prevConversations => prevConversations.map(conversation => {
                    if (conversation.id === event.conversation.id) {
                        return event.conversation
                    } else {
                        return conversation
                    }
                }))
            })
    }, [])

    const handleMarkSeen = async (objectType, ObjectId) => {
        setLoading(true)
        try {
            const response = await useApi.post(`/seen/mark_as_seen/${objectType}/${ObjectId}`, {}, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setLoading(false)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                updateData()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            setLoading(false)
        }
    }

    const handleNavigateConversation = (conversation) => {
        navigate(`/app/conversation?id=${conversation.id}`)
        setShowDropDown(false)
        setActiveDropDown(false)
    }

    const handleNavigateNotification = (notification) => {
        switch (true) {
            case Boolean(notification.blog_id):
                navigate(`/app/blog?id=${notification.blog_id}`);
                break;
            case Boolean(notification.voyage_id):
                navigate(`/app/voyage?id=${notification.voyage_id}`);
                break;
            case Boolean(notification.trajet_id):
                navigate(`/app/trajet?id=${notification.trajet_id}`);
                break;
            case Boolean(notification.tarif_id):
                navigate(`/app/tarif?id=${notification.tarif_id}`);
                break;
            case Boolean(notification.reservation_id):
                navigate(`/app/reservation?id=${notification.reservation_id}`);
                break;
            case Boolean(notification.paiement_id):
                navigate(`/app/paiement?id=${notification.paiement_id}`);
                break;
            case Boolean(notification.vehicule_id):
                navigate(`/app/vehicule?id=${notification.vehicule_id}`);
                break;
            case Boolean(notification.ville_id):
                navigate(`/app/ville?id=${notification.ville_id}`);
                break;
            case Boolean(notification.site_id):
                navigate(`/app/site?id=${notification.site_id}`);
                break;
            case Boolean(notification.cooperative_id):
                navigate(`/app/cooperative?id=${notification.cooperative_id}`);
                break;
            case Boolean(notification.utilisateur_id):
                navigate(`/app/user?id=${notification.utilisateur_id}`);
                break;
            default:
                console.error('Aucune rédirection trouvé');
        }
        setShowDropDown(false)
        setActiveDropDown(false)
    }

    return (
        <>
            {showLogoutModal &&
                <ConfirmModal
                    title="Déconnexion"
                    message="Voulez-vous vraiment vous déconnecter ? "
                    onConfirm={() => handleLogout()}
                    onCancel={() => setShowLogoutModal(false)} />
            }

            <div className='navbar'>
                <div className="navbar-container">
                    <div className="navbar-logo">
                        <img onClick={() => navigate('/app')} style={{ width: ["sm", "md", "lg"].includes(screenSize) ? "60px" : "15%" }} src={["sm", "md", "lg"].includes(screenSize) ? Logo : LogoMD} alt='logo' />
                        {(["sm", "md", "lg"].includes(screenSize) && !auth.must_change_password && !auth.must_complete_profil) && (
                            showSidebar ?
                                <IoClose onClick={() => setShowSidebar(false)} /> :
                                <IoMdMenu onClick={() => setShowSidebar(true)} />
                        )}
                    </div>
                    <div className="navbar-menu" ref={outsideDropdownRef}>
                        <div className={`navbar-menu-item ${(activeDropDown === "message" || ["/app/conversation"].includes(pathname)) ? "navbar-menu-item-active" : ""}`} ref={outsideDropdownRef} onClick={() => {
                            setActiveDropDown(activeDropDown === "message" ? "" : "message");
                            setShowDropDown(activeDropDown === "message" ? false : true);
                            setNotificationUnread(false); setConversationUnread(false);
                        }}>
                            <MdOutlineChatBubbleOutline size={23} />
                            {conversationNumber > 0 &&
                                <span>{conversationNumber}</span>
                            }

                        </div>
                        <div className={`navbar-menu-item ${activeDropDown === "notification" || ["/app/notification"].includes(pathname) ? "navbar-menu-item-active" : ""}`} ref={outsideDropdownRef} onClick={() => {
                            setActiveDropDown(activeDropDown === "notification" ? "" : "notification");
                            setShowDropDown(activeDropDown === "notification" ? false : true);
                            setNotificationUnread(false); setConversationUnread(false);
                        }}>
                            <FaRegBell size={23} />
                            {notifNumber > 0 &&
                                <span>{notifNumber}</span>
                            }

                        </div>
                        <div className="navbar-user">
                            {auth.prenom &&
                                <span>{auth.prenom.charAt(0)}</span>
                            }
                            {!["sm", "md", "lg"].includes(screenSize) &&
                                <div className='navbar-user-info'>
                                    <span className='capitalize'>{auth.role}</span>
                                    <span className='email'>{auth.email}</span>
                                </div>
                            }
                            {activeDropDown === "user" && <IoMdArrowDropup onClick={() => { setActiveDropDown(""); setShowDropDown(false) }} size={18} />}
                            {(!showDropDown || (showDropDown && activeDropDown !== "user")) && <IoMdArrowDropdown size={18} onClick={() => { setActiveDropDown("user"); setShowDropDown(true) }} />}
                            {showDropDown &&
                                <div className="navbar-dropdown-container" style={{ width: activeDropDown !== "user" ? "350px" : "200px" }}>
                                    {activeDropDown === "user" ?
                                        <>
                                            {["sm", "md", "lg"].includes(screenSize) &&
                                                <div className="navbar-dropdown-title">
                                                    <div className='navbar-user-info'>
                                                        <span className='capitalize'>{auth.role}</span>
                                                        <span className='email'>{auth.email}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="navbar-dropdown-item" onClick={() => { setShowDropDown(false); setActiveDropDown(""); navigate('/app/profile') }}>
                                                <HiOutlineUserCircle size={22} /> Voir profil
                                            </div>
                                            <div className="navbar-dropdown-item" onClick={() => setShowLogoutModal(true)}>
                                                <FiLogOut size={22} /> Se déconnecter
                                            </div>
                                        </>
                                        : activeDropDown === "notification" ?
                                            <>
                                                <div className="navbar-dropdownlist-header">
                                                    <div>
                                                        <h3>Notification</h3>
                                                    </div>
                                                </div>
                                                <div className="navbar-dropdownlist-tabs">
                                                    <span className={!notificationUnread ? "navbar-dropdownlist-active" : ""} onClick={() => setNotificationUnread(false)}>Tous</span>
                                                    <span className={notificationUnread ? "navbar-dropdownlist-active" : ""} onClick={() => setNotificationUnread(true)}>Non Lu {notifNumber > 0 ? `(${notifNumber})` : ''}</span>
                                                </div>
                                                {notifications.length === 0 ? <h3 className='justify-center'>Aucune notification</h3>
                                                    :
                                                    <>
                                                        {notifications.map((not) => (
                                                            <div className="navbar-dropdownlist-item" key={not.id} onClick={() => { handleMarkSeen("notification_id", not.id); handleNavigateNotification(not) }}>
                                                                <div className="img-container">
                                                                    <img src={
                                                                        not.createur_photo ? useFileUrl + not.createur_photo
                                                                            : (not.createur_sexe === "homme" ? HOMME : FEMME)
                                                                    } alt="img" />
                                                                </div>
                                                                <div className='detail-container'>
                                                                    <h4>{not.createur_prenom}</h4>
                                                                    <p className={!not.seen ? `unread` : ''}>
                                                                        {not.message}
                                                                    </p>
                                                                    <span>{moment(not.created_at).fromNow()}</span>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                                {notifications.length >= 10 &&
                                                    <div className="navbar-dropdown-bottom">
                                                        <span onClick={() => {
                                                            navigate("/app/notification"); setShowDropDown(false); setActiveDropDown(false);
                                                        }}>Afficher plus</span>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="navbar-dropdownlist-header">
                                                    <div>
                                                        <h3>Message</h3>
                                                        <TbEdit size={22} onClick={() => { navigate('/app/conversation/add'); setShowDropDown(false); setActiveDropDown(false) }} />
                                                    </div>
                                                </div>
                                                <div className="navbar-dropdownlist-tabs">
                                                    <span className={!conversationUnread ? "navbar-dropdownlist-active" : ""} onClick={() => setConversationUnread(false)}>Tous</span>
                                                    <span className={conversationUnread ? "navbar-dropdownlist-active" : ""} onClick={() => setConversationUnread(true)}>Non Lu {conversationNumber > 0 ? `(${conversationNumber})` : ''}</span>
                                                </div>
                                                {conversations.length === 0 ? <h3 className='justify-center'>Aucun message</h3>
                                                    :
                                                    <>
                                                        {conversations.map((conv, index) => (
                                                            <div className="navbar-dropdownlist-item" key={index} onClick={() => { handleMarkSeen("conversation_id", conv.id); handleNavigateConversation(conv) }}>
                                                                <div className="img-container">
                                                                    <img src={
                                                                        conv.recipient_id ?
                                                                            (
                                                                                conv.recipient_id === auth.id ?
                                                                                    (conv.createur_photo ? useFileUrl + conv.createur_photo
                                                                                        : (conv.createur_sexe === "homme" ? HOMME : FEMME))
                                                                                    :
                                                                                    (conv.recipient_photo ? useFileUrl + conv.recipient_photo
                                                                                        : (conv.recipient_sexe === "homme" ? HOMME : FEMME))
                                                                            )
                                                                            :
                                                                            USER_GROUP
                                                                    } alt="img" />
                                                                </div>
                                                                <div className='detail-container'>
                                                                    <h4>{conv.recipient_id ? (conv.recipient_id === auth.id ? conv.createur_prenom : conv.recipient_prenom) : conv.titre}</h4>
                                                                    <p className={(conv.seen || conv.message_user_id === auth.id) ? '' : 'unread'}>
                                                                        {conv.contenu}
                                                                    </p>
                                                                    <span>{moment(conv.created_at).fromNow()}</span>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                                {conversations.length >= 10 &&
                                                    <div className="navbar-dropdown-bottom">
                                                        <span onClick={() => {
                                                            navigate("/app/message"); setShowDropDown(false); setActiveDropDown(false);
                                                        }}>Afficher plus</span>
                                                    </div>
                                                }
                                            </>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
