import { Link } from 'react-router-dom'

export default function ActionTarif({ auth, tarif }) {

    return (
        <div className='action-container'>
            {
                (["superadmin", "admin"].includes(auth.role)) &&
                <span>
                    <Link to={"/app/tarif/edit/" + tarif.id}>Editer</Link>
                </span>
            }
        </div>
    )
}