import { Outlet,Navigate } from "react-router-dom"

export default function TarifRoute({auth}) {
    return (
        ["superadmin","admin","admin_site"].includes(auth.role) ?
            <Outlet/>
        : 
            <Navigate to="/app"/>
    )
}
