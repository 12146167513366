import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import PageLoader from '../../components/loader/PageLoader'
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"
import useApi from '../../hooks/useApi'
import useFileUrl from '../../hooks/useFileUrl'

export default function Commentaire({ voyageId }) {
    const navigate = useNavigate()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [commentaires, setCommentaires] = useState([])
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        params.set('voyage_id', voyageId)

        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", commentaires.length)
        }

        try {
            const res = await useApi.get('note_commentaire?' + params)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setCommentaires(res.data.commentaires)
                    } else {
                        const list = commentaires.slice().concat(res.data.commentaires)
                        setCommentaires(list)
                    }
                    setallDataLoaded(res.data.commentaires.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('note-channel')
            .listen('.created', (event) => {
                setCommentaires(prevCommentaires => {
                    if (!prevCommentaires.some(commentaire => commentaire.id === event.commentaire.id)) {
                        return [event.commentaire, ...prevCommentaires]
                    }
                    return prevCommentaires
                });

            })
            .listen('.updated', (event) => {
                setCommentaires(prevCommentaires => prevCommentaires.map(commentaire => {
                    if (commentaire.id === event.commentaire.id) {
                        return event.commentaire
                    } else {
                        return commentaire
                    }
                }))
            })
    }, [commentaires])

    return (
        <>
            {loading ? <PageLoader />
                :
                <>
                    {commentaires.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={commentaires.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                commentaires.map((co) => (
                                    <div className='note-commentaire-container' key={co.id}>
                                        <div className='note-commentaire-header' onClick={() => navigate(`/app/profile?user_id=${co.user_id}`)}>
                                            <div className="img-container">
                                                <img src={co.createur_photo ? useFileUrl + co.createur_photo : (co.createur_sexe == "homme" ? Homme : Femme)} alt="img" />
                                            </div>
                                            <span>{co.createur_prenom + " <" + co.createur_email + ">"}</span>
                                        </div>
                                        <p className='note-commentaire'>
                                            {co.commentaire}
                                        </p>
                                        <div className='note-commentaire-footer'>
                                            <span>{moment(co.created_at).format("DD MMM YY à HH:mm")}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
