import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatNumber from '../../hooks/useFormatNumber'
import PageLoader from '../../components/loader/PageLoader'
import MultiChart from '../../components/chart/MultiChart'
import DoughnutChart from '../../components/chart/DoughnutChart'
import Btn from '../../components/button/Btn'
import { ImMakeGroup } from 'react-icons/im'
import { GiHorizonRoad } from 'react-icons/gi'
import { FaShop } from "react-icons/fa6"
import { BsBusFront } from "react-icons/bs"

export default function HomeSuperAdmin({ auth }) {
    const tokenHeaders = useToken()
    const formatNumber = useFormatNumber()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [voyagesEtReservations, setVoyagesEtReservations] = useState({})
    const [totalReservations, setTotalReservations] = useState(0)
    const [totalVoyages, setTotalVoyages] = useState(0)
    const [totalSites, setTotalSites] = useState(0)
    const [totalVehicules, setTotalVehicules] = useState(0)
    const [activeFilter, setActiveFilter] = useState("30")

    const updateData = async () => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (activeFilter) params.set("start_date", activeFilter)
        try {
            const res = await useApi.get('/home/superadmin' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error)
                } else {
                    const data = res.data
                    setData(data)
                    const combinedData = {
                        Voyages: data.voyages,
                        Reservations: data.reservations,
                    }
                    setVoyagesEtReservations(combinedData)
                    const totalReservationsCount = data.reservations.reduce((acc, cur) => acc + cur.count, 0)
                    const totalVoyagesCount = data.voyages.reduce((acc, cur) => acc + cur.count, 0)
                    const totalSitesCount = data.sites.reduce((acc, cur) => acc + cur.count, 0)
                    const totalVehiculesCount = data.vehicules.reduce((acc, cur) => acc + cur.count, 0)
                    setTotalReservations(totalReservationsCount)
                    setTotalVoyages(totalVoyagesCount)
                    setTotalSites(totalSitesCount)
                    setTotalVehicules(totalVehiculesCount)
                    setLoading(false)
                }
                setLoading(false)
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error)
            setLoading(false)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (activeFilter !== '') {
            timeoutId = setTimeout(() => {
                updateData()
            }, 500)
        } else {
            updateData()
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, activeFilter])

    return (
        loading ? <PageLoader />
            :
            <div className='home'>
                <div className="home-chart-container">
                    <div className="left-info">
                        <div className="card-voyage-reservation">
                            <div className="data-count-voyage-reservation">
                                <div className="block">
                                    <h3>Vue d'ensemble</h3>
                                    <span>voyages et réservations</span>
                                    <Btn
                                        label="Exporter"
                                        className="btn btn-outline" />
                                </div>
                                <div className="block">
                                    <h1>{formatNumber(totalReservations)}</h1>
                                    <span>Réservations de places</span>
                                </div>
                                <div className="block">
                                    <h1>{formatNumber(totalVoyages)}</h1>
                                    <span>Voyages créer</span>
                                </div>

                            </div>
                            <div className="chart-voyage-reservation-container">
                                <div className="multichart-tabs-filter">
                                    <div className="multichart-tabs-left">
                                        <span onClick={() => setActiveFilter("30")} className={activeFilter == "30" ? "active-filter" : ""}>Mensuel</span>
                                        <span onClick={() => setActiveFilter("7")} className={activeFilter == "7" ? "active-filter" : ""}>Hebdomadaire</span>
                                        <span onClick={() => setActiveFilter("1")} className={activeFilter == "1" ? "active-filter" : ""}>Journalié</span>
                                    </div>
                                </div>
                                <div className="multichart-voyage-reservation">
                                    <MultiChart data={voyagesEtReservations} type="line" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="right-info">
                        <h3>Utilisateurs</h3>
                        <div className="doughnut-chart">
                            <DoughnutChart
                                label="Utilisateurs"
                                data={data.utilisateurRoles}
                                hideLegend
                            />
                        </div>
                        <div className="doughnut-chart-info">
                            {data.utilisateurRoles.map((u, index) => (
                                <div key={index}>
                                    <h4>{formatNumber(u.count)}</h4>
                                    <span>{u.role}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="home-grid-container">
                    <div className="home-grid-card">
                        <h4>Total Coopératives</h4>
                        <div className="grid-detail">
                            <ImMakeGroup size={30} />
                            <h1>{formatNumber(data.cooperativeNb)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Sites</h4>
                        <div className="grid-detail">
                            <FaShop size={30} />
                            <h1>{formatNumber(totalSites)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Véhicules</h4>
                        <div className="grid-detail">
                            <BsBusFront size={30} />
                            <h1>{formatNumber(totalVehicules)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total voyages</h4>
                        <div className="grid-detail">
                            <GiHorizonRoad size={30} />
                            <h1>{formatNumber(data.voyageNb)}</h1>
                        </div>
                    </div>
                </div>
            </div>
    )
}