import React, { useEffect, useState } from 'react'
import Story from '../components/story/Story'
import ReservationItem from '../pages/reservation/ReservationItem'
import ReservationGuichetItem from '../pages/reservation/ReservationGuichetItem'
import ReservationTraveler from '../pages/reservation/ReservationTraveler'
import PaiementItem from '../pages/paiement/PaiementItem'
import GuichetPaiementItem from '../pages/paiement/GuichetPaiementItem'
import VehiculeControle from '../pages/vehicule/VehiculeControle'
import NoteVoyage from '../pages/note/NoteVoyage'
import Commentaire from '../pages/commentaire/Commentaire'

export default function ShowTab({
    auth,
    name,
    value,
    updateData,
    voyage,
    clientId,
    voyageId,
    chauffeurSecondId,
    voyageStatus
}) {
    const [activeMenu, setActiveMenu] = useState("")
    useEffect(() => {
        setActiveMenu(
            (name == 'user_id') ? 'tracabilite'
                : (name == 'cooperative_id') ? 'tracabilite'
                    : (name == 'gare_routiere_id') ? 'tracabilite'
                        : (name == 'site_id') ? 'tracabilite'
                            : (name == 'ville_id') ? 'tracabilite'
                                : (name == 'trajet_id') ? 'tracabilite'
                                    : (name == 'tarif_id') ? 'tracabilite'
                                        : (name == 'vehicule_id') ? 'vehicule_controle'
                                            : (name == 'voyage_id') ? 'reservation'
                                                : (name == 'guichet_id') ? 'guichet_paiement'
                                                    : "tracabilite"
        )
    }, [])

    return (
        <div className='tab-view-container'>
            <div className='tab-view-list'>
                {name == "voyage_id" &&
                    <>
                        <div onClick={() => setActiveMenu('reservation')} className={activeMenu == 'reservation' ? 'active' : ''}>
                            Réservations
                        </div>
                        <div onClick={() => setActiveMenu('voyageur')} className={activeMenu == 'voyageur' ? 'active' : ''}>
                            Voyageurs
                        </div>
                        <div onClick={() => setActiveMenu('paiement')} className={activeMenu == 'paiement' ? 'active' : ''}>
                            Paiements
                        </div>
                        {(["ARRIVE"].includes(voyageStatus)) &&
                            <>
                                <div onClick={() => setActiveMenu('note')} className={activeMenu == 'note' ? 'active' : ''}>
                                    Notes
                                </div>
                                <div onClick={() => setActiveMenu('commentaire')} className={activeMenu == 'commentaire' ? 'active' : ''}>
                                    Commentaires
                                </div>
                            </>
                        }
                    </>
                }
                {name === "guichet_id" &&
                    <>
                        <div onClick={() => setActiveMenu('guichet_paiement')} className={activeMenu == 'guichet_paiement' ? 'active' : ''}>
                            Paiements
                        </div>
                        <div onClick={() => setActiveMenu('guichet_reservation')} className={activeMenu == 'guichet_reservation' ? 'active' : ''}>
                            Réservations
                        </div>
                        {(["ARRIVE"].includes(voyageStatus)) &&
                            <>
                                <div onClick={() => setActiveMenu('note')} className={activeMenu == 'note' ? 'active' : ''}>
                                    Notes
                                </div>
                                <div onClick={() => setActiveMenu('commentaire')} className={activeMenu == 'commentaire' ? 'active' : ''}>
                                    Commentaires
                                </div>
                            </>
                        }
                        <div onClick={() => setActiveMenu('tracabilite_guichet')} className={activeMenu == 'tracabilite_guichet' ? 'active' : ''}>
                            Traçabilité
                        </div>
                    </>
                }

                {name === "vehicule_id" &&
                    <div onClick={() => setActiveMenu('vehicule_controle')} className={activeMenu == 'vehicule_controle' ? 'active' : ''}>
                        Contrôles
                    </div>
                }
                {name !== "guichet_id" &&
                    <div onClick={() => setActiveMenu('tracabilite')} className={activeMenu == 'tracabilite' ? 'active' : ''}>
                        Traçabilité
                    </div>
                }

            </div>
            <div className='tab-content'>
                {
                    ['tracabilite', 'tracabilite_guichet'].includes(activeMenu) &&
                    <Story auth={auth} name={name} value={value} updateData={updateData} clientId={clientId} voyageId={voyageId} />
                }
                {
                    activeMenu === "guichet_reservation" &&
                    <ReservationGuichetItem auth={auth} clientId={clientId} voyageId={voyageId} />
                }
                {
                    activeMenu === "reservation" &&
                    <ReservationItem auth={auth} name={name} value={value} updateData={updateData} voyage={voyage} />
                }
                {
                    activeMenu === "voyageur" &&
                    <ReservationTraveler auth={auth} name={name} value={value} voyage={voyage} />
                }
                {
                    activeMenu === "paiement" &&
                    <PaiementItem auth={auth} name={name} value={value} />
                }
                {
                    activeMenu === "guichet_paiement" &&
                    <GuichetPaiementItem auth={auth} clientId={clientId} voyageId={voyageId} />
                }
                {
                    activeMenu === "vehicule_controle" &&
                    <VehiculeControle auth={auth} name={name} value={value} />
                }
                {
                    (activeMenu === "note") &&
                    <NoteVoyage auth={auth} voyageId={voyageId} chauffeurSecondId={chauffeurSecondId} />
                }
                {
                    (activeMenu === "commentaire") &&
                    <Commentaire voyageId={voyageId} />
                }
            </div>
        </div >
    )
}