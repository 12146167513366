import Btn from '../../components/button/Btn'

export default function Condition({ setShowCondition, acceptCondition, setAcceptCondition }) {
    return (
        <div className='modal'>
            <div>
                <div className="space-between">
                    <h3>Conditions et termes d'utilisation</h3>
                </div>
                <p className='p-preline'>
                    Merci d'utiliser notre application de gestion de taxi-brousse et de réservation.
                    En accédant à notre application et en utilisant nos services, vous acceptez les présentes
                    Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement.
                </p>
                <h3>1. Acceptation des Conditions</h3>
                <p className='p-preline'>
                    En utilisant notre application, vous acceptez les présentes CGU.
                    Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre application.
                </p>
                <h3>2. Services Offerts</h3>
                <p className='p-preline'>
                    Notre application permet la gestion de taxis-brousse et la réservation de places
                    pour des voyages. Les utilisateurs peuvent réserver des voyages, donner des avis
                    sur les services, et gérer les détails de leurs voyages.
                </p>
                <h3>3. Compte Utilisateur</h3>
                <p className='p-preline'>
                    Pour accéder à certaines fonctionnalités, vous devez créer un compte utilisateur.
                    Vous êtes responsable de maintenir la confidentialité de vos informations de connexion
                    et de toutes les activités effectuées sous votre compte.
                </p>
                <h3>4. Réservations</h3>
                <p className='p-preline'>
                    Toutes les réservations effectuées via notre application sont soumises à disponibilité.
                    Nous nous réservons le droit d'annuler ou de refuser toute réservation à tout moment
                    pour des raisons valables.
                </p>
                <h3>5. Paiements</h3>
                <p className='p-preline'>
                    Les paiements doivent être effectués conformément aux instructions fournies dans l'application.
                    Les frais peuvent inclure des taxes applicables. Toutes les transactions sont traitées
                    de manière sécurisée.
                </p>
                <h3>6. Avis et Notes</h3>
                <p className='p-preline'>
                    Les utilisateurs peuvent donner des avis et des notes sur les services fournis.
                    Ces avis doivent être honnêtes et basés sur des expériences personnelles.
                    Nous nous réservons le droit de supprimer tout avis inapproprié ou frauduleux.
                </p>
                <h3>7. Confidentialité</h3>
                <p className='p-preline'>
                    Nous nous engageons à protéger votre vie privée. Veuillez consulter notre Politique
                    de Confidentialité pour plus d'informations sur la manière dont nous collectons,
                    utilisons et protégeons vos données personnelles.
                </p>
                <h3>8. Limitation de Responsabilité</h3>
                <p className='p-preline'>
                    Nous ne serons pas responsables des dommages indirects, spéciaux ou consécutifs résultant
                    de l'utilisation de notre application. Notre responsabilité totale pour toute réclamation
                    liée à l'utilisation de notre application sera limitée au montant payé par l'utilisateur
                    pour l'accès aux services.
                </p>
                <h3>9. Modifications des CGU</h3>
                <p className='p-preline'>
                    Nous nous réservons le droit de modifier ces CGU à tout moment. Les modifications seront
                    effectives dès leur publication sur notre application. En continuant à utiliser notre application
                    après les modifications, vous acceptez les nouvelles CGU.
                </p>
                <h3>10. Résiliation</h3>
                <p className='p-preline'>
                    Nous nous réservons le droit de suspendre ou de résilier votre accès à notre application
                    à tout moment, sans préavis, en cas de violation des présentes CGU.
                </p>
                <h3>11. Droit Applicable</h3>
                <p className='p-preline'>
                    Ces CGU sont régies par les lois en vigueur dans le pays où nous opérons.
                    Tout litige sera soumis à la compétence exclusive des tribunaux de cette juridiction.
                </p>
                <h3>12. Contact</h3>
                <p className='p-preline'>
                    Pour toute question concernant ces CGU, veuillez nous contacter à "contact@evoyage.mg" .
                </p>
                <div className="justify-center">
                    {acceptCondition ?
                        <Btn
                            label="Fermer"
                            className='btn-outline small'
                            onClick={() => { setShowCondition(false); setAcceptCondition(true) }}
                        />
                        :
                        <Btn
                            label="J'accepte"
                            className='btn-primary small'
                            onClick={() => { setShowCondition(false); setAcceptCondition(true) }}
                        />
                    }

                </div>
            </div>

        </div>
    )
}
