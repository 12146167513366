import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionCooperative from './ActionCooperative'
import ShowTab from '../../view/ShowTab'
import COOPERATIVE from '../../assets/PROFILE/COOPERATIVE.png'
import { IoMdInformationCircleOutline } from "react-icons/io"
import { MdOutlinePhoneForwarded, MdOutlineMailOutline } from "react-icons/md"
import { PiUserCirclePlusFill } from "react-icons/pi"

export default function ShowCooperative({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [cooperative, setCooperative] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/cooperative/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setCooperative(res.data.cooperative)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(cooperative)
    }, [cooperative]);

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('cooperative-channel')
            .listen('.updated', (event) => {
                setCooperative(prevCooperative => {
                    if (prevCooperative && prevCooperative.id === event.cooperative.id) {
                        return { ...prevCooperative, ...event.cooperative }
                    }
                    return prevCooperative
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {cooperative &&
                    <div className='view-show-container'>
                        <ShowHeader data={cooperative} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={cooperative.logo ? useFileUrl + cooperative.logo : COOPERATIVE} alt="img" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{cooperative.designation}</h3>
                                    <span>{cooperative.adresse}</span>
                                    <p>
                                        <span>
                                            {
                                                cooperative.status == "suspendu" ?
                                                    <span className="badge-outline badge-red">
                                                        suspendu
                                                    </span>
                                                    :
                                                    <span className={"badge-outline badge-green"}>
                                                        {cooperative.status}
                                                    </span>
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <p><IoMdInformationCircleOutline size={16} className='svg-text' /> Nif : <span className='text'>{cooperative.nif}</span>, Stat : <span className='text'>{cooperative.stat}</span></p>
                            <p><MdOutlineMailOutline size={16} className='svg-text' />  <span className='text'>{cooperative.email}</span></p>
                            <p><MdOutlinePhoneForwarded size={16} className='svg-text' /> <span className='text'>{formatPhoneNumber(cooperative.telephone)}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{cooperative.createur_prenom + " <" + cooperative.createur_email + ">"}</span></p>
                            <ActionCooperative auth={auth} cooperative={cooperative} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="cooperative_id" value={cooperative.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
