import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionVehicule from './ActionVehicule'
import ShowTab from '../../view/ShowTab'
import { IoMdInformationCircleOutline } from "react-icons/io"
import { PiUserCirclePlusFill } from "react-icons/pi"
import VEHICULE from '../../assets/PROFILE/VEHICULE.png'

export default function ShowVehicule({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [vehicule, setVehicule] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/vehicule/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setVehicule(res.data.vehicule)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(vehicule)
    }, [vehicule]);

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('vehicule-channel')
            .listen('.updated', (event) => {
                setVehicule(prevVehicule => {
                    if (prevVehicule && prevVehicule.id === event.vehicule.id) {
                        return { ...prevVehicule, ...event.vehicule }
                    }
                    return prevVehicule
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {vehicule &&
                    <div className='view-show-container'>
                        <ShowHeader data={vehicule} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={vehicule.photo ? useFileUrl + vehicule.photo : VEHICULE} alt="" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{vehicule.marque + " " + vehicule.matricule}</h3>
                                    <span>{vehicule.categorie}</span>
                                    <p>
                                        <span>
                                            <span className={
                                                vehicule.status == "HORS SERVICE" ? "badge-outline badge-red"
                                                    : vehicule.status == "EN REPARATION" ? "badge-outline badge-orange"
                                                        : vehicule.status == "EN VOYAGE" ? "badge-outline badge-purple"
                                                            : "badge-outline badge-green"
                                            }>
                                                {vehicule.status}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {auth.role === "superadmin" &&
                                <p className="important">{vehicule.cooperative}</p>
                            }

                            {(vehicule.modele || vehicule.annee) &&
                                <p><IoMdInformationCircleOutline size={16} className='svg-text' /> {vehicule.modele && <>Modèle <span className='text'>{vehicule.modele}</span>, </>}{vehicule.annee && <> Année <span className='text'>{vehicule.annee}</span></>}</p>
                            }
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{vehicule.createur_prenom + " <" + vehicule.createur_email + ">"}</span></p>
                            <ActionVehicule auth={auth} vehicule={vehicule} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="vehicule_id" value={vehicule.id} updateData={updateData} />
                    </div >
                }
            </>

    )
}
