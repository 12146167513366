import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import COOPERATIVE from '../../assets/PROFILE/COOPERATIVE.png'
import { LuTimerReset } from "react-icons/lu"

export default function Cooperative({ auth, cooperatives, setCooperatives, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    const formatDates = (cooperatives) => {
        const formattedDates = cooperatives.map(co => moment(co.created_at).from(co.datetime))
        setFormattedDates(formattedDates)
    }

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", cooperatives.length)
        }

        try {
            const res = await useApi.get('/cooperative' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setCooperatives(res.data.cooperatives)
                    } else {
                        const list = cooperatives.slice().concat(res.data.cooperatives)
                        setCooperatives(list)
                    }
                    setallDataLoaded(res.data.cooperatives.length < 30)
                    formatDates(res.data.cooperatives)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(cooperatives)
        const interval = setInterval(() => {
            formatDates(cooperatives)
        }, 60000)
        return () => clearInterval(interval)
    }, [cooperatives])

    useEffect(() => {
        window.Echo.channel('cooperative-channel')
            .listen('.created', (event) => {
                setCooperatives(prevCooperatives => {
                    if (!prevCooperatives.some(cooperative => cooperative.id === event.cooperative.id)) {
                        return [event.cooperative, ...prevCooperatives];
                    }
                    return prevCooperatives;
                });

            })
            .listen('.updated', (event) => {
                setCooperatives(prevCooperatives => prevCooperatives.map(cooperative => {
                    if (cooperative.id === event.cooperative.id) {
                        return event.cooperative;
                    } else {
                        return cooperative;
                    }
                }));
            })
    }, [cooperatives])

    return (
        <>
            <ViewHeader
                label="Coopératives"
                searchItems={searchItems}
                link="/app/cooperative/add"
                authorisation={["superadmin"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {cooperatives.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={cooperatives.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                cooperatives.map((co, index) => (
                                    <div className={`view-card-container ${currentId && currentId == co.id ? 'selected' : ''}`} key={co.id} onClick={() => setCurrentId(co.id)}>
                                        <div className="view-card-space-between">
                                            <div className="view-card-with-img">
                                                <div className="img-container">
                                                    <img src={co.logo ? useFileUrl + co.logo : COOPERATIVE} alt="img" />
                                                </div>
                                                <div className="card-with-img-detail">
                                                    <div className="badge">
                                                        <div
                                                            className={`badge-shape ${co.status == "suspendu" ?
                                                                "bg-red"
                                                                :
                                                                "bg-green"
                                                                }`
                                                            }
                                                        />
                                                        {co.status}
                                                    </div>
                                                    <h2>{co.designation}</h2>
                                                    <p>{co.adresse}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-between contact-and-date">
                                            <span className='text'>{" <" + co.createur_email + ">"}</span>
                                            <p className='view-card-timing'><LuTimerReset size={15} />{formattedDates[index]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
