import { useNavigate } from 'react-router-dom'
import Btn from '../components/button/Btn'
import SearchBar from '../components/search/SearchBar'

export default function ViewHeader({ label, link, authorisation, searchItems, statusList }) {
    const navigate = useNavigate()
    return (
        <div className='view-list-header'>
            <div className="space-between">
                <h3>{label}</h3>
                {authorisation &&
                    <Btn
                        label="Nouveau"
                        className='btn-primary small'
                        onClick={() => navigate(link)} />
                }

            </div>
            <SearchBar searchItems={searchItems} statusList={statusList} />
        </div>
    )
}
