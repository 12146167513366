import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputFile from '../../components/input/InputFile'
import Btn from '../../components/button/Btn'


export default function EditCooperative({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [file, setFile] = useState("")
    const [tmpFile, setTmpFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [designation, setDesignation] = useState("")
    const [adresse, setAdresse] = useState("")
    const [nif, setNif] = useState("")
    const [stat, setStat] = useState("")
    const [email, setEmail] = useState("")
    const [telephone, setTelephone] = useState("")
    const validEmail = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const validPhone = /^(032|033|034|038)\d{7}$/
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(!designation.trim() || !adresse.trim() || !nif.trim() || !stat.trim()
            || !validEmail.test(email) || !validPhone.test(telephone) || loading
        )
    }, [designation, adresse, nif, stat, email, telephone, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('fichier', file)
        if (['/app/cooperative/edit/' + params.id].includes(pathname)) {
            data.append('fichier_id', fileId)
        }
        data.append('level', 1)
        data.append('designation', designation)
        data.append('adresse', adresse)
        data.append('nif', nif)
        data.append('stat', stat)
        data.append('email', email)
        data.append('telephone', telephone)

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : ""),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setFile("")
                setDesignation("")
                setAdresse("")
                setNif("")
                setStat("")
                setEmail("")
                setTelephone("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/cooperative')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/cooperative/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const cooperative = res.data.cooperative
                    if (cooperative) {
                        if (cooperative.logo) setTmpFile(cooperative.logo)
                        if (cooperative.logo_id) setFileId(cooperative.logo_id)
                        if (cooperative.designation) setDesignation(cooperative.designation)
                        if (cooperative.adresse) setAdresse(cooperative.adresse)
                        if (cooperative.nif) setNif(cooperative.nif)
                        if (cooperative.stat) setStat(cooperative.stat)
                        if (cooperative.email) setEmail(cooperative.email)
                        if (cooperative.telephone) setTelephone(cooperative.telephone)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/cooperative')
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    <div style={{ paddingRight: "10px" }} onClick={goBack}>
                        <IoMdArrowBack size={26} className='pointer' />
                    </div>
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid-one">
                        <InputFile
                            label="Logo"
                            file={file}
                            setFile={setFile}
                            tmpFile={tmpFile}
                            setTmpFile={setTmpFile}
                            multiple={false}
                            id="logo"
                            required
                        />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label='Designation'
                            placeholder='Saisir la désignation'
                            value={designation}
                            onChange={setDesignation}
                            className='large'
                            required />
                        <InputText
                            label='Adresse'
                            placeholder="Saisir l'adresse"
                            value={adresse}
                            onChange={setAdresse}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label='Nif'
                            placeholder='Saisir le nif'
                            value={nif}
                            onChange={setNif}
                            className='large'
                            required />
                        <InputText
                            label='Stat'
                            placeholder="Saisir le stat"
                            value={stat}
                            onChange={setStat}
                            className='large'
                            required />
                    </div>
                    <div className="grid-two">
                        <InputText
                            label='Email'
                            placeholder="Saisir l'email"
                            value={email}
                            onChange={setEmail}
                            className='large'
                            required />
                        <InputText
                            label='Téléphone'
                            placeholder="Saisir le telephone"
                            value={telephone}
                            onChange={setTelephone}
                            className='large'
                            required />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/cooperative/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
