import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'

export default function Gare({ auth, gares, setGares, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", gares.length)
        }

        try {
            const res = await useApi.get('/gare_routiere' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setGares(res.data.gare_routieres)
                    } else {
                        const list = gares.slice().concat(res.data.gare_routieres)
                        setGares(list)
                    }
                    setallDataLoaded(res.data.gare_routieres.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('gare-routiere-channel')
            .listen('.created', (event) => {
                setGares(prevGares => {
                    if (!prevGares.some(gare => gare.id === event.gare.id)) {
                        return [event.gare, ...prevGares]
                    }
                    return prevGares
                });

            })
            .listen('.updated', (event) => {
                setGares(prevGares => prevGares.map(gare => {
                    if (gare.id === event.gare.id) {
                        return event.gare
                    } else {
                        return gare
                    }
                }))
            })
    }, [gares])

    return (
        <>
            <ViewHeader
                label="Gares Routières"
                searchItems={searchItems}
                link="/app/gare/add"
                authorisation={["superadmin"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {gares.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={gares.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Désignation</th>
                                        <th className='table-ellipsis'>Province</th>
                                        <th>Ville</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        gares.map((ga) => (
                                            <tr className={`${currentId && currentId == ga.id ? 'selected' : ''}`} key={ga.id} onClick={() => setCurrentId(ga.id)}>
                                                <td className='table-ellipsis'>{ga.designation}</td>
                                                <td className='table-ellipsis'>{ga.province}</td>
                                                <td>{ga.ville}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
