import {IoMdClose} from 'react-icons/io'

export default function ConfirmModal({ title,message, onConfirm, onCancel }) {
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="confirm-header">
                    <h4>{title}</h4>
                    <IoMdClose onClick={onCancel}/>
                </div>
                <div className="confirm-body">
                    <p>{message}</p>
                </div>
                <div className="confirm-footer">
                    <span onClick={onCancel} className="btn-cancel">Annuler</span>
                    <span onClick={onConfirm} className="btn-ok">OK</span>
                </div>
            </div>
        </div>
    )
}
