import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { APIProvider, Map, Marker, useAdvancedMarkerRef, InfoWindow } from '@vis.gl/react-google-maps'
import { IoArrowBackOutline } from "react-icons/io5"
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useReactMapApiKey from '../../hooks/useMapApiKey'
import MyPostion from '../../assets/MARKER/MyPosition.png'
import Position from '../../assets/MARKER/Position.png'
import InputText from '../../components/input/InputText'
import Btn from '../../components/button/Btn'
import Direction from './Direction'
import PageLoader from '../../components/loader/PageLoader'

export default function ShowEditMap({ auth, action, title }) {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const getQueryParamValue = (paramName) => {
        return searchParams.get(paramName)
    }
    const objectName = Array.from(searchParams.keys())[0]
    const objectValue = getQueryParamValue(objectName)
    const idLocalisation = Array.from(searchParams.keys())[1]
    const idLocalisationValue = getQueryParamValue(idLocalisation)

    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const locationSearch = useLocation().search
    const apiKey = useReactMapApiKey()
    const fallbackPosition = { lat: -18.919463, lng: 47.526067 }
    const [userPosition, setUserPosition] = useState(null)
    const [selectedPosition, setSelectedPosition] = useState(null)
    const [mapZoom, setMapZoom] = useState(12)
    const [myMarkerRef, myMarker] = useAdvancedMarkerRef()
    const [positionSelectedRef, positionSelected] = useAdvancedMarkerRef()
    const [showMyInfo, setShowMyInfo] = useState(true)
    const [showPositionInfo, setShowPositionInfo] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [designation, setDesignation] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [mapLoaded, setMapLoaded] = useState(false)

    const handleGoBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (
            (['/app/map/add'].includes(pathname) && !objectValue) ||
            (['/app/map/edit', '/app/map/show'].includes(pathname) && (!objectValue || !idLocalisationValue))
        ) {
            navigate('/app/map')
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    setUserPosition({ lat: latitude, lng: longitude })
                },
                (error) => {
                    console.error('Error getting user position:', error)
                    setUserPosition(fallbackPosition)
                }
            )
        } else {
            console.error('Geolocation is not supported by this browser.')
            setUserPosition(fallbackPosition)
        }
    }, [])

    const handleMapClick = (e) => {
        if (['/app/map/show'].includes(pathname)) {
            return
        }
        setShowModal(true)
        const newPosition = {
            lat: e.detail.latLng.lat,
            lng: e.detail.latLng.lng
        }
        setSelectedPosition(newPosition)
        setMapZoom(18)
        setLatitude(e.detail.latLng.lat)
        setLongitude(e.detail.latLng.lng)
        setShowModal(true)
    }

    const handleCancel = () => {
        setLatitude("")
        setLongitude("")
        setSelectedPosition(null)
        setShowModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            latitude: selectedPosition.lat,
            longitude: selectedPosition.lng,
            [objectName]: objectValue
        }
        try {
            const response = await useApi.post(
                action + (['/app/map/edit'].includes(pathname) ? `/${idLocalisationValue}` : ''),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                toast.success(response.data.success)
                setLoading(false)
                setShowModal(false)
                handleGoBack()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true)

            if (!selectedPosition && objectName === 'site_id') {
                const response = await useApi.get(`/site/show/${objectValue}`, tokenHeaders)
                const data = response.data

                const site = data.site
                if (site) {
                    const { latitude, longitude, designation } = site
                    if (latitude && longitude && !['/app/map/add'].includes(pathname)) {
                        setSelectedPosition({ lat: latitude, lng: longitude })
                    }
                    if (designation) {
                        setDesignation(designation)
                    }
                } else {
                    navigate('/app/map')
                }
            }

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [pathname, objectName, objectValue, idLocalisation])

    useEffect(() => {
        if (mapLoaded) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [mapLoaded])

    return (
        (loading) ? <PageLoader />
            :
            <>
                <div className="map-title">
                    <IoArrowBackOutline size={22} onClick={handleGoBack} />
                    <h3>{title}</h3>
                </div>
                <div className="map-container">
                    <APIProvider apiKey={apiKey}>
                        <Map
                            defaultCenter={userPosition}
                            defaultZoom={14}
                            gestureHandling={'greedy'}
                            fullscreenControl={false}
                            onClick={handleMapClick}
                            onLoad={() => setMapLoaded(true)}
                        >
                            <>
                                {userPosition &&
                                    <>
                                        <Marker
                                            ref={myMarkerRef}
                                            position={userPosition}
                                            icon={{ url: MyPostion, scaledSize: { width: 23, height: 30 } }}
                                            onClick={() => setShowMyInfo(true)} />
                                        {showMyInfo &&
                                            <InfoWindow
                                                onCloseClick={() => setShowMyInfo(false)}
                                                anchor={myMarker}>
                                                <p>Votre position</p>
                                            </InfoWindow>
                                        }
                                    </>
                                }
                                {selectedPosition &&
                                    <>
                                        <Marker
                                            ref={positionSelectedRef}
                                            position={selectedPosition}
                                            icon={{ url: Position, scaledSize: { width: 23, height: 30 } }}
                                            onClick={() => setShowPositionInfo(true)} />
                                        {showPositionInfo &&
                                            <InfoWindow
                                                onCloseClick={() => setShowPositionInfo(false)}
                                                anchor={positionSelected}>
                                                <p>{designation ? designation : "Position sélectionné"}</p>
                                            </InfoWindow>
                                        }
                                    </>
                                }
                                {(['/app/map/show'].includes(pathname) && userPosition && selectedPosition) && <Direction origin={userPosition} destination={selectedPosition} color="#FF8B04" />}
                            </>
                        </Map>
                    </APIProvider>
                    {(showModal && ['/app/map/edit', '/app/map/add'].includes(pathname)) &&
                        <div className="modal">
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <InputText
                                        label='Désignation'
                                        placeholder='designation'
                                        value={designation}
                                        onChange={setDesignation}
                                        className='regular'
                                        readOnly
                                        required />
                                    <InputText
                                        label='Latitude'
                                        placeholder='Latitude'
                                        value={latitude}
                                        onChange={setLatitude}
                                        className='regular'
                                        readOnly
                                        required />
                                    <InputText
                                        label='Longitude'
                                        placeholder='Longitude'
                                        value={longitude}
                                        onChange={setLongitude}
                                        className='regular'
                                        readOnly
                                        required />
                                    <div className="justify-right">
                                        <Btn
                                            label={loading ? "Chargement..." : (['/app/map/edit'].includes(pathname) ? "Modifier" : "Ajouter")}
                                            className='btn-primary small btn-margin'
                                            type='submit'
                                            disabled={loading} />
                                        <Btn
                                            label="Annuler"
                                            className='btn-outline small'
                                            onClick={handleCancel}
                                            disabled={loading} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </>
    )
}
