import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useClickOutside from '../../hooks/useClickOutside'
import useScreenSize from '../../hooks/useScreenSize'
import usePrice from '../../hooks/usePrice'
import useFormatNumber from '../../hooks/useFormatNumber'
import ScreenLoader from '../../components/loader/ScreenLoader'
import moment from 'moment'
import { CgClose } from 'react-icons/cg'
import { ImNewspaper } from 'react-icons/im'
import { GiHorizonRoad } from 'react-icons/gi'
import { MdBook } from 'react-icons/md'

export default function HomePassager({ auth }) {
    const tokenHeaders = useToken()
    const screenSize = useScreenSize()
    const formatPrice = usePrice()
    const formatNumber = useFormatNumber()
    const locationSearch = useLocation().search
    const localizer = momentLocalizer(moment)
    const [events, setEvents] = useState([])
    const [tooltipStyle, setTooltipStyle] = useState({})
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [nbVoyage, setNbVoyage] = useState(0)
    const [nbReservation, setNbReservation] = useState(0)
    const [nbBlog, setNbBlog] = useState(0)
    const [totalPaiement, setTotalPaiement] = useState(0)
    const [loading, setLoading] = useState(false)
    const outsideDropdownRef = useRef(null)
    useClickOutside(outsideDropdownRef, () => setSelectedEvent(null))

    useEffect(() => {
        setSelectedEvent(null)
    }, [screenSize])

    const messages = {
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
        allDay: 'Toute la journée',
        week: 'Semaine',
        work_week: 'Semaine de travail',
        day: 'Jour',
        month: 'Mois',
        previous: 'Précédent',
        next: 'Suivant',
        yesterday: 'Hier',
        tomorrow: 'Demain',
        today: "Aujourd'hui",
        agenda: 'Agenda',
        noEventsInRange: "Aucun événement dans cette période.",
        showMore: total => `+ Voir plus (${total})`
    }

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        useApi.get('/home/passager', tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const reservations = res.data.reservations.map(reservation => ({
                        title: reservation.voyage_designation,
                        start: new Date(reservation.voyage_date_depart),
                        end: new Date(reservation.voyage_date_depart),
                        ...reservation
                    }))
                    setEvents(reservations)
                    setNbVoyage(res.data.nb_voyage)
                    setNbReservation(res.data.nb_reservation)
                    if (res.data.total_paiement) setTotalPaiement(res.data.total_paiement)
                    setNbBlog(res.data.nb_blog)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        updateData()
    }, [])

    const eventPropGetter = (event, start, end, isSelected) => {
        return {
            className: 'custom-event',
            style: {
                backgroundColor: (
                    event.voyage_status === "EN ATTENTE" ? '#9c27b0'
                        : event.voyage_status === "VALIDE" ? '#0400ff'
                            : event.voyage_status === "ANNULE" ? '#ff4646'
                                : event.voyage_status === "REPORTE" ? '#ff0066'
                                    : event.voyage_status === "EN ROUTE" ? '#fff700'
                                        : event.voyage_status === "EN RETARD" ? '#ff5e1f'
                                            : '#09ff00'
                )
                ,
                color: '#fff',
                border: '1px solid #ddd'
            }
        }
    }

    const handleSelectEvent = (event, e) => {

        const tooltipX = (["sm"].includes(screenSize) ? e.clientX : e.clientX - 250)
        const tooltipY = (e.clientY - 50)

        setTooltipStyle({
            top: tooltipY,
            left: tooltipX,
            display: 'block',
            border: (event.voyage_status === "EN ATTENTE" ? '1px solid #9c27b0'
                : event.voyage_status === "VALIDE" ? '1px solid #0400ff'
                    : event.voyage_status === "ANNULE" ? '1px solid #ff4646'
                        : event.voyage_status === "REPORTE" ? '1px solid #ff0066'
                            : event.voyage_status === "EN ROUTE" ? '1px solid #fff700'
                                : event.voyage_status === "EN RETARD" ? '1px solid #ff5e1f'
                                    : '1px solid #09ff00')
        })
        setSelectedEvent(event)
    }

    const closeTooltip = () => {
        setTooltipStyle({ display: 'none' })
        setSelectedEvent(null)
    }

    return (
        loading ? <ScreenLoader />
            :
            <div className='home-passager'>
                {selectedEvent && (
                    <div className="tooltip" style={tooltipStyle} ref={outsideDropdownRef}>
                        <CgClose className="close-tooltip" onClick={closeTooltip} />
                        <h3>{selectedEvent.voyage_cooperative}</h3>
                        <div>{selectedEvent.title}</div>
                        <div>{moment(selectedEvent.start).format('DD MMMM YYYY [à] HH[h] mm')}</div>
                        <div>{`Place${selectedEvent.nombre_de_places > 1 ? 's' : ''} : ${selectedEvent.places + " (" + selectedEvent.nombre_de_places + ")"}`}</div>
                        <div>Status du voyage : {selectedEvent.voyage_status}</div>
                    </div>
                )}
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={handleSelectEvent}
                    messages={messages}
                    eventPropGetter={eventPropGetter}
                />
                <div className="home-grid-container">
                    <div className="home-grid-card">
                        <h4>Total Paiements</h4>
                        <div className="grid-detail">
                            <h1 style={{ fontSize: '1.5rem' }}>{formatPrice(parseInt(totalPaiement))}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Voyages</h4>
                        <div className="grid-detail">
                            <GiHorizonRoad size={30} />
                            <h1>{formatNumber(nbVoyage)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Reservations</h4>
                        <div className="grid-detail">
                            <MdBook size={30} />
                            <h1>{formatNumber(nbReservation)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Publications</h4>
                        <div className="grid-detail">
                            <ImNewspaper size={30} />
                            <h1>{formatNumber(nbBlog)}</h1>
                        </div>
                    </div>
                </div>
            </div>
    )
}
