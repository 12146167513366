import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"
import { LuTimerReset } from "react-icons/lu"
import { FaRegIdBadge } from "react-icons/fa6"
import InputUserMultiple from '../../components/input/InputUserMultiple'
import Btn from '../../components/button/Btn'
import Badge from '../../components/badge/Badge'

export default function User({ auth, users, setUsers, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [employes, setEmployes] = useState([])
    const [showModalUsers, setShowModalUsers] = useState(false)
    const [showBadge, setShowBadge] = useState(false)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    const formatDates = (users) => {
        const formattedDates = users.map(u => moment(u.created_at).from(u.datetime))
        setFormattedDates(formattedDates)
    }

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Utilisateur', name: 'account', type: 'string' },
        { label: 'Coopérative', name: 'cooperative_id', type: 'string' },
        { label: 'Site', name: 'site_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", users.length)
        }

        try {
            const res = await useApi.get('/utilisateur' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setUsers(res.data.utilisateurs)
                    } else {
                        const list = users.slice().concat(res.data.utilisateurs)
                        setUsers(list)
                    }
                    setallDataLoaded(res.data.utilisateurs.length < 30)
                    formatDates(res.data.utilisateurs)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(users)
        const interval = setInterval(() => {
            formatDates(users)
        }, 60000)
        return () => clearInterval(interval)
    }, [users])

    useEffect(() => {
        window.Echo.channel('utilisateur-channel')
            .listen('.created', (event) => {
                setUsers(prevUtilisateurs => {
                    if (!prevUtilisateurs.some(utilisateur => utilisateur.id === event.utilisateur.id)) {
                        return [event.utilisateur, ...prevUtilisateurs]
                    }
                    return prevUtilisateurs
                });

            })
            .listen('.updated', (event) => {
                setUsers(prevUtilisateurs => prevUtilisateurs.map(utilisateur => {
                    if (utilisateur.id === event.utilisateur.id) {
                        return event.utilisateur
                    } else {
                        return utilisateur
                    }
                }))
            })
    }, [users])

    return (
        <>
            <ViewHeader
                label="Utilisateurs"
                searchItems={searchItems}
                link="/app/user/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                        <div className="view-print-badge">
                            <div className="view-print-badge-button" onClick={() => setShowModalUsers(true)}>
                                <FaRegIdBadge size={22} /> <span>Imprimer badge</span>
                            </div>
                        </div>
                    }
                    {showModalUsers &&
                        <div className='input-modal'>
                            <div>
                                <div className="grid-one">
                                    <InputUserMultiple
                                        label="Employé(s)"
                                        values={employes}
                                        onChange={setEmployes}
                                        required
                                    />
                                </div>
                                <div className="justify-right">
                                    <Btn
                                        label="Imprimer"
                                        className='btn-primary small'
                                        disabled={!(employes && employes.length > 0)}
                                        onClick={() => setShowBadge(true)}
                                    />
                                    <Btn
                                        style={{ marginLeft: '10px' }}
                                        label="Annuler"
                                        className='btn-outline small'
                                        onClick={() => { setEmployes([]); setShowModalUsers(false) }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {showBadge &&
                        <Badge
                            selectedUsers={employes}
                            closeModal={() => setShowBadge(false)}
                        />
                    }

                    {users.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={users.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                users.map((u, index) => (
                                    <div className={`view-card-container ${currentId && currentId == u.id ? 'selected' : ''}`} key={u.id} onClick={() => setCurrentId(u.id)}>
                                        <div className="view-card-space-between">
                                            <div className="view-card-with-img">
                                                <div className="img-container">
                                                    <img src={u.profil ? useFileUrl + u.profil : (u.sexe == "homme" ? Homme : Femme)} alt="img" />
                                                </div>
                                                <div className="card-with-img-detail">
                                                    <div className="badge">
                                                        <div
                                                            className={`badge-shape ${u.type == "fictif" ?
                                                                "bg-purple"
                                                                :
                                                                "bg-green"
                                                                }`
                                                            }
                                                        />
                                                        {u.type}
                                                    </div>
                                                    <h2>{u.nom + " " + u.prenom}</h2>
                                                    <p>{u.email}</p>
                                                    <p>{u.role.toUpperCase()}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="space-between contact-and-date ">
                                            <span className='text'>{" <" + u.createur_email + ">"}</span>
                                            <p className='view-card-timing'><LuTimerReset size={15} />{formattedDates[index]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
