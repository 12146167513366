import { useState, useEffect } from "react"
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom"

import { ToastContainer } from 'react-toastify'
import { Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

/*** CSS ***/
import './styles/app.css'
import './styles/input.css'
import './styles/button.css'
import './styles/loader.css'
import './styles/modal.css'
import './styles/input_modal.css'
import './styles/sidebar.css'
import './styles/navbar.css'
import './styles/badge.css'
import './styles/form.css'
import './styles/searchbar.css'
import './styles/auth.css'
import './styles/complete_profile.css'
import './styles/profile.css'
import './styles/view.css'
import './styles/story.css'
import './styles/carseat.css'
import './styles/reservation.css'
import './styles/print.css'
import './styles/map.css'
import './styles/table.css'
import './styles/voyage.css'
import './styles/chart.css'
import './styles/home.css'
import './styles/background_color.css'
import './styles/blog.css'
import './styles/conversation.css'
import './styles/badge_employe.css'
import './styles/rate_star.css'

/** COMPONENTS */

//ROUTES
import GuestRoute from './route/GuestRoute'
import PrivateRoute from './route/PrivateRoute'
import ProfileRoute from './route/ProfileRoute'
import UserRoute from './route/UserRoute'
import CooperativeRoute from './route/CooperativeRoute'
import GareRoute from './route/GareRoute'
import SiteRoute from './route/SiteRoute'
import VilleRoute from './route/VilleRoute'
import TrajetRoute from './route/TrajetRoute'
import VehiculeRoute from './route/VehiculeRoute'
import TarifRoute from './route/TarifRoute'
import VoyageRoute from './route/VoyageRoute'
import MapRoute from './route/MapRoute'
import PassagerRoute from './route/PassagerRoute'
import BlogRoute from './route/BlogRoute'
import ConversationRoute from './route/ConversationRoute'
import GuichetRoute from './route/GuichetRoute'

//PAGES
import NotFound from './pages/notfound/NotFound'
import Authentication from './pages/auth/Authentication'
import Home from './pages/home/Home'
import Profile from './pages/profile/Profile'
import View from './view/View'
import EditUser from "./pages/user/EditUser"
import EditCooperative from './pages/cooperative/EditCooperative'
import EditGare from './pages/gare/EditGare'
import EditSite from './pages/site/EditSite'
import EditVille from './pages/ville/EditVille'
import EditTrajet from './pages/trajet/EditTrajet'
import EditVehicule from './pages/vehicule/EditVehicule'
import EditTarif from './pages/tarif/EditTarif'
import EditVoyage from './pages/voyage/EditVoyage'
import MapView from './pages/map/MapView'
import ShowEditMap from "./pages/map/ShowEditMap"
import VoyageClient from './pages/voyage/VoyageClient'
import Blog from './pages/blog/Blog'
import EditBlog from './pages/blog/EditBlog'
import ShowBlog from './pages/blog/ShowBlog'
import ConversationAdd from "./pages/conversation/ConversationAdd"

const Echo = require('laravel-echo').default
const Pusher = require('pusher-js')

export default function App() {
    const [auth, setAuth] = useState()

    useEffect(() => {
        window.Pusher = Pusher
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            forceTLS: false,
            wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
            wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
            disableStats: true,
            enabledTransports: ['ws', 'wss']
        })

        return () => {
            if (window.Echo) {
                window.Echo.disconnect()
            }
        }
    }, [])

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<GuestRoute auth={auth} setAuth={setAuth} />}>
                        <Route index element={<Authentication />} />
                        <Route path="app" element={<PrivateRoute auth={auth} />}>
                            <Route path="profile" element={<ProfileRoute auth={auth} />}>
                                <Route path="edit" element={<EditUser auth={auth} action={"/utilisateur/complete_profile"} title="Information à remplir" />} />
                                <Route index element={<Profile auth={auth} />} />
                            </Route>
                            <Route path='blog' element={<BlogRoute auth={auth} />}>
                                <Route path="add" element={<EditBlog auth={auth} action="/blog/add" title="Ajout d'un blog" />} />
                                <Route path="edit/:id" element={<EditBlog auth={auth} action="/blog/edit" title="Modification d'un blog" />} />
                                <Route path="show/:id" element={<ShowBlog auth={auth} />} />
                                <Route index element={<Blog auth={auth} />} />
                            </Route>
                            <Route path='user' element={<UserRoute auth={auth} />}>
                                <Route path="add" element={<EditUser auth={auth} action="/utilisateur/add" title="Ajout d'utilisateur" />} />
                                <Route path="edit/:id" element={<EditUser auth={auth} action="/utilisateur/edit" title="Modification d'utilisateur" />} />
                                <Route index element={<View name="user" auth={auth} />} />
                            </Route>
                            <Route path='cooperative' element={<CooperativeRoute auth={auth} />}>
                                <Route path="add" element={<EditCooperative auth={auth} action="/cooperative/add" title="Ajout d'une coopérative" />} />
                                <Route path="edit/:id" element={<EditCooperative auth={auth} action="/cooperative/edit" title="Modification de la coopérative" />} />
                                <Route index element={<View name="cooperative" auth={auth} />} />
                            </Route>
                            <Route path='gare' element={<GareRoute auth={auth} />}>
                                <Route path="add" element={<EditGare auth={auth} action="/gare_routiere/add" title="Ajout d'une gare routière" />} />
                                <Route path="edit/:id" element={<EditGare auth={auth} action="/gare_routiere/edit" title="Modification de la gare routière" />} />
                                <Route index element={<View name="gare" auth={auth} />} />
                            </Route>
                            <Route path='site' element={<SiteRoute auth={auth} />}>
                                <Route path="add" element={<EditSite auth={auth} action="/site/add" title="Ajout d'un site" />} />
                                <Route path="edit/:id" element={<EditSite auth={auth} action="/site/edit" title="Modification du site" />} />
                                <Route index element={<View name="site" auth={auth} />} />
                            </Route>
                            <Route path='ville' element={<VilleRoute auth={auth} />}>
                                <Route path="add" element={<EditVille auth={auth} action="/ville/add" title="Ajout d'une ville" />} />
                                <Route path="edit/:id" element={<EditVille auth={auth} action="/ville/edit" title="Modification de la ville" />} />
                                <Route index element={<View name="ville" auth={auth} />} />
                            </Route>
                            <Route path='trajet' element={<TrajetRoute auth={auth} />}>
                                <Route path="add" element={<EditTrajet auth={auth} action="/trajet/add" title="Ajout d'un trajet" />} />
                                <Route path="edit/:id" element={<EditTrajet auth={auth} action="/trajet/edit" title="Modification du trajet" />} />
                                <Route index element={<View name="trajet" auth={auth} />} />
                            </Route>
                            <Route path='tarif' element={<TarifRoute auth={auth} />}>
                                <Route path="add" element={<EditTarif auth={auth} action="/tarif/add" title="Ajout d'un tarif" />} />
                                <Route path="edit/:id" element={<EditTarif auth={auth} action="/tarif/edit" title="Modification du tarif" />} />
                                <Route index element={<View name="tarif" auth={auth} />} />
                            </Route>
                            <Route path='vehicule' element={<VehiculeRoute auth={auth} />}>
                                <Route path="add" element={<EditVehicule auth={auth} action="/vehicule/add" title="Ajout d'un véhicule" />} />
                                <Route path="edit/:id" element={<EditVehicule auth={auth} action="/vehicule/edit" title="Modification du véhicule" />} />
                                <Route index element={<View name="vehicule" auth={auth} />} />
                            </Route>
                            <Route path='voyage' element={<VoyageRoute auth={auth} />}>
                                <Route path="add" element={<EditVoyage auth={auth} action="/voyage/add" title="Ajout d'un voyage" />} />
                                <Route path="edit/:id" element={<EditVoyage auth={auth} action="/voyage/edit" title="Modification du voyage" />} />
                                <Route index element={<View name="voyage" auth={auth} />} />
                            </Route>
                            <Route path='map' element={<MapRoute auth={auth} />}>
                                <Route path="add" element={<ShowEditMap auth={auth} action="/localisation/add" title="Ajout position" />} />
                                <Route path="edit" element={<ShowEditMap auth={auth} action="/localisation/edit" title="Modification position" />} />
                                <Route path="show" element={<ShowEditMap auth={auth} title="Affichage position" />} />
                                <Route index element={<MapView auth={auth} />} />
                            </Route>
                            <Route path='auth' element={<PassagerRoute auth={auth} />}>
                                <Route path="voyage" element={<VoyageClient auth={auth} />} />
                                <Route path="reservation" element={<PassagerRoute auth={auth} />} >
                                    <Route index element={<View name="reservation_client" auth={auth} />} />
                                </Route>
                            </Route>
                            <Route path='conversation' element={<ConversationRoute auth={auth} />}>
                                <Route path="add" element={<ConversationAdd auth={auth} action="/message/add" title="Création d'une conversation" />} />
                                <Route index element={<View name="conversation" auth={auth} />} />
                            </Route>
                            <Route path='guichet' element={<GuichetRoute auth={auth} />}>
                                <Route index element={<View name="guichet" auth={auth} />} />
                            </Route>
                            <Route path='notification' element={<View name="notification" auth={auth} />} />

                            <Route index element={<Home auth={auth} />} />
                        </Route>
                    </Route>
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
        </>
    )
}

