import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import moment from 'moment'

export default function PaiementItem({ auth, clientId, voyageId }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const formatPrice = usePrice()
    const [paiements, setPaiements] = useState([])
    const [client, setClient] = useState([])
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")

    const updateData = async () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        params.set("client_id", clientId)
        params.set("voyage_id", voyageId)
        try {
            const res = await useApi.get('/paiement/guichet_client' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setPaiements(res.data.paiements)
                    setClient(res.data.client)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        updateData()
    }, [clientId, voyageId])

    return (
        loading ? <PageLoader />
            :
            <>
                {paiements.length === 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                    :
                    <div style={{ borderTop: '1px dashed #ddd', marginTop: '10px' }}>
                        <h3>{`${(client.sexe == "homme" ? "Mr " : "Mdm ") + client.nom} ${client.prenom} - ${formatPhoneNumber(client.telephone)}`}</h3>
                        <h4>{`Nombre de paiement : ${paiements.length}`}</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th className='table-ellipsis'>Montant</th>
                                    <th>Type de paiement</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paiements.map((pa) => (
                                    <tr key={pa.id}>
                                        <td>{formatPrice(pa.montant)}</td>
                                        <td>{pa.type_paiement}</td>
                                        <td>{moment(pa.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </>
    )
}
