import { useState } from 'react'
import StateGuichet from '../guichet/StateGuichet'

export default function ActionReservationClient({ auth, reservation, updateData }) {
    const [showPaiment, setShowPaiment] = useState(false)

    return (
        <>
            {showPaiment &&
                <StateGuichet
                    auth={auth}
                    guichet={reservation}
                    updateData={() => updateData()}
                    closeModal={() => setShowPaiment(false)} />
            }
            <div className='action-container'>
                {
                    (reservation.reste_a_payer > 0) &&
                    <span onClick={() => { setShowPaiment(true) }}>
                        Compléter
                    </span>
                }
            </div>
        </>
    )
}