import { useState, useRef } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { IoMdSend } from "react-icons/io"
import { MdOutlineEmojiEmotions } from "react-icons/md"
import useClickOutside from '../../hooks/useClickOutside'

export default function InputSendWithEmoji({ value, onChange, placeholder, handleSubmit, loading, top }) {
    const inputRef = useRef(null)
    const [showEmoji, setShowEmoji] = useState(false)
    const emojiRef = useRef(null)
    useClickOutside(emojiRef, () => setShowEmoji(false))
    const handleEmojiClick = (event) => {
        const { emoji } = event
        const position = inputRef.current.selectionStart
        const debut = value.slice(0, position)
        const fin = value.slice(position)
        onChange(debut + emoji + fin)
        setShowEmoji(false)
    }

    return (
        <div className="input-send-container" ref={emojiRef}>
            <textarea
                ref={inputRef}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                rows="3"
            >
            </textarea>
            <div className="input-send-btn-container">
                <span onClick={() => setShowEmoji(!showEmoji)}><MdOutlineEmojiEmotions size={22} className={showEmoji ? "input-send-btn-active" : ""} /></span>
                <span onClick={handleSubmit}><IoMdSend size={22} className={value.length > 0 ? "input-send-btn-active" : ""} /></span>
            </div>
            {showEmoji &&
                <div className={`emoji-container ${top ? 'emoji-container-top' : 'emoji-container-bottom'}`}>
                    <EmojiPicker onEmojiClick={handleEmojiClick} height={400} />
                </div>
            }
        </div>
    )
}
