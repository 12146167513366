import Logo from '../../assets/LOGO/LOGO_MD_BLACKBG.svg'
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"
import QRCode from 'react-qr-code'
import useFileUrl from '../../hooks/useFileUrl'

export default function BadgeItem({ user }) {
    return (
        <div className='badge-item'>
            <div className="badge-item-header">
                <img className='logo' src={Logo} alt="logo" />
                <h4>{(user.cooperative)?.toUpperCase()}</h4>
            </div>
            <div className="badge-item-detail">
                <div className="badge-profile-container">
                    <img src={user.profil ? useFileUrl + user.profil : (user.sexe === "homme" ? Homme : Femme)} alt="img" />
                </div>
                <h4>{user?.nom?.toUpperCase() + ' ' + user?.prenom}</h4>
                <span>{(user.role).toUpperCase()}</span>
            </div>
            <div className="qr-code" style={{ backgroundColor: "black" }}>
                <div className="content-qr">
                    <QRCode
                        size={30}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={"Cooperative: " + user.cooperative + "\nReference: " + user.reference + "\nNom: " + user.nom + " " + user.prenom + "\nRole: " + user.role + "\nweb.e-voyage.mg "}
                    />
                </div>
                <span className="name-plateforme">
                    E-VOYAGE
                </span>
            </div>

        </div>
    )
}
