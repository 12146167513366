import { useState, useEffect } from 'react'

export default function useFormatNumber() {
    const formatNumber = (number) => {
        let formattedNumber = ''

        if (number >= 1 && number <= 9) {
            formattedNumber = `0${number}`
        } else {
            formattedNumber = number.toString()
        }

        if (formattedNumber.length > 3) {
            if (formattedNumber.length <= 6) {
                formattedNumber = `${(formattedNumber / 1000).toFixed(1)}K`
            } else if (formattedNumber.length <= 9) {
                formattedNumber = `${(formattedNumber / 1000000).toFixed(1)}M`
            }
        }

        return formattedNumber
    }

    return formatNumber
}