import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import InputCooperative from '../../components/input/InputCooperative'
import InputTrajet from '../../components/input/InputTrajet'
import Btn from '../../components/button/Btn'


export default function EditTarif({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    useEffect(() => {
        if (!["superadmin", "admin"]) {
            navigate(-1)
        }
    }, [])

    const categorieList = [
        'ECONOMIQUE',
        'PREMIUM',
        'VIP'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [categorie, setCategorie] = useState("")
    const [cooperative, setCooperative] = useState(null)
    const [trajet, setTrajet] = useState(null)
    const [prix, setPrix] = useState("")
    const validUnsignedNumber = /^[0-9]+$/
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(
            auth.role === "superadmin" ?
                (!validUnsignedNumber.test(prix) || !categorie.trim() || (cooperative === null || cooperative.length === 0)
                    || (trajet === null || trajet.length === 0) || loading)
                :
                (!validUnsignedNumber.test(prix) || !categorie.trim() || (trajet === null || trajet.length === 0) || loading)

        )
    }, [categorie, cooperative, trajet, prix, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            categorie: categorie,
            cooperative_id: cooperative ? cooperative.id : '',
            trajet_id: trajet ? trajet.id : '',
            prix: prix
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/tarif/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setCategorie("")
                setCooperative(null)
                setTrajet(null)
                setPrix(null)
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/tarif')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/tarif/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const tarif = res.data.tarif
                    if (tarif) {
                        if (tarif.categorie) setCategorie(tarif.categorie)
                        if (tarif.cooperative) setCooperative({
                            id: tarif.cooperative_id,
                            designation: tarif.cooperative,
                        })
                        if (tarif.trajet) setTrajet({
                            id: tarif.trajet_id,
                            designation: tarif.trajet,
                        })
                        if (tarif.prix) setPrix(tarif.prix)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/tarif')
    }

    return (
        <div className="view-content">
            <div className='view-regular'>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <InputSelect
                        label='Catégorie'
                        selected={categorie}
                        setSelected={setCategorie}
                        options={categorieList}
                        className='large'
                        required />
                    {auth.role == "superadmin" &&
                        <InputCooperative
                            value={cooperative}
                            onChange={setCooperative} />
                    }

                    <InputTrajet
                        label="Trajet"
                        value={trajet}
                        onChange={setTrajet} />
                    <InputText
                        label="Prix"
                        placeholder="Saisir le prix du tarif"
                        type="number"
                        min="0"
                        value={prix}
                        onChange={setPrix}
                        className='large'
                        required />
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/tarif/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
