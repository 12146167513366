import React, { useRef, useState } from 'react'
import useFileUrl from '../../hooks/useFileUrl'
import { CgClose } from 'react-icons/cg'
import { MdCloudUpload } from 'react-icons/md'

export default function InputFile({ label, file, setFile, tmpFile, setTmpFile, className, id, required, multiple, accept }) {
    const inputFileRef = useRef(null)
    const [dragging, setDragging] = useState(false)

    const handleChange = (event) => {
        const filesArray = Array.from(event.target.files)

        setFile(multiple ? [...file, ...filesArray] : filesArray[0])
        if (tmpFile) {
            setTmpFile('')
        }
    }

    const handleDelete = (index) => {
        if (multiple) {
            const updatedFiles = [...file]
            updatedFiles.splice(index, 1)
            setFile(updatedFiles)
        } else {
            setFile('')
        }
        inputFileRef.current.value = ''
    }

    const handleDragOver = (event) => {
        event.preventDefault()
        setDragging(true)
    }

    const handleDragEnter = (event) => {
        event.preventDefault()
        setDragging(true)
    }

    const handleDragLeave = () => {
        setDragging(false)
    }

    const handleDrop = (event) => {
        event.preventDefault()
        setDragging(false)
        const droppedFiles = Array.from(event.dataTransfer.files)
        if (multiple) {
            setFile([...file, ...droppedFiles])
        } else {
            setFile(droppedFiles[0])
        }
    }

    return (
        <div className={'input-file-container ' + className}>
            <div className="input-file-label">{label} {required && <span className='danger'>*</span>}</div>
            <div className="input-file">
                <label
                    htmlFor={id}
                    className={`label-img ${dragging ? 'dragging' : ''}`}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <MdCloudUpload size={35} />
                    {(file || tmpFile) ? "Cliquer ici pour changer le fichier" : "Cliquer ici pour joindre le fichier"}
                </label>
                <input
                    id={id}
                    ref={inputFileRef}
                    type="file"
                    onChange={handleChange}
                    //accept=".jpg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, image/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain"
                    accept={accept ? accept : ".jpg, .png"}
                    multiple={multiple}
                />
            </div>
            {(file || tmpFile) && (
                <div className="input-file-list">
                    {multiple ? (
                        file.map((f, index) => ((f.name) &&
                            <div key={index} className='input-file-list-view'>
                                {f.name && (
                                    ((f.name && [".jpg", ".png"].includes(f.name.slice(-4).toLowerCase())) || (tmpFile && [".jpg", ".png"].includes(tmpFile.slice(-4).toLowerCase()))) ? (
                                        <img src={tmpFile ? useFileUrl + tmpFile : URL.createObjectURL(f)} alt="Preview" />
                                    ) : (
                                        <span>{f.name} <CgClose onClick={() => handleDelete(index)} size={22} /></span>
                                    )
                                )}
                                {((!tmpFile && [".jpg", ".png"].includes(f.name.slice(-4).toLowerCase())) || (!tmpFile && [".jpg", ".png"].includes(tmpFile.slice(-4).toLowerCase()))) &&
                                    <CgClose onClick={() => handleDelete(index)} size={22} />
                                }
                            </div>
                        ))
                    ) : ((file.name || tmpFile) &&
                        <div className='input-file-list-view'>
                            {
                                ((file.name && [".jpg", ".png"].includes(file.name.slice(-4).toLowerCase())) || (tmpFile && [".jpg", ".png"].includes(tmpFile.slice(-4).toLowerCase()))) ?
                                    <img src={tmpFile ? useFileUrl + tmpFile : URL.createObjectURL(file)} alt="Preview" />
                                    :
                                    <span>{file.name} <CgClose onClick={handleDelete} size={22} /></span>
                            }
                            {((!tmpFile && [".jpg", ".png"].includes(file.name.slice(-4).toLowerCase())) || (!tmpFile && [".jpg", ".png"].includes(tmpFile.slice(-4).toLowerCase()))) &&
                                <CgClose onClick={handleDelete} size={22} />
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}