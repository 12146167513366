import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputSendWithEmoji from '../../components/input/InputSendWithEmoji'
import InputUserMultiple from '../../components/input/InputUserMultiple'
import Btn from '../../components/button/Btn'

export default function ConversationAdd({ auth, action, title }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const location = useLocation()
    const tokenHeaders = useToken()
    const [recipientIds, setRecipientIds] = useState([])
    const [contenu, setContenu] = useState("")
    const [files, setFiles] = useState([])
    const [titre, setTitre] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [selectedUser, setSelectedUser] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        let params = new URLSearchParams(location.search)
        useApi.get('/utilisateur/show/' + params.get('recipient_id'), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setRecipientIds([res.data.utilisateur])
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    console.log(recipientIds)

    useEffect(() => {
        let params = new URLSearchParams(location.search)
        if (params.get('recipient_id')) {
            updateData()
        }
    }, [location])

    useEffect(() => {
        setBtnDisabled(!recipientIds.length > 0 || !contenu.trim() || loading)
    }, [recipientIds, contenu, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        files.forEach((file, index) => {
            data.append(`fichiers[${index}]`, file)
        })
        recipientIds.forEach(user => data.append('recipient_ids[]', user.id))
        data.append('contenu', contenu)
        data.append('titre', titre)
        try {
            const response = await useApi.post(
                action,
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setRecipientIds([])
                setFiles([])
                setContenu("")
                setTitre("")
                setLoading(false)
                toast.success(response.data.success)
                navigate(`/app/conversation?id=${response.data.conversation_id}`)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="view-content">
            <div style={{ maxWidth: '500px' }}>
                <div className="vertical-align">
                    <div style={{ paddingRight: "10px" }} onClick={goBack}>
                        <IoMdArrowBack size={26} className='pointer' />
                    </div>
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="grid-one">
                        <InputUserMultiple
                            label="Destinataire(s)"
                            values={recipientIds}
                            onChange={setRecipientIds}
                            selectedUser={selectedUser}
                            required
                        />
                    </div>
                    {(recipientIds && recipientIds.length > 1) &&
                        <div className="grid-one">
                            <InputText
                                label='Titre'
                                placeholder="Saisir le titre"
                                value={titre}
                                onChange={setTitre}
                                className='large' />
                        </div>
                    }
                    <div className="grid-one">
                        <InputSendWithEmoji
                            value={contenu}
                            onChange={setContenu}
                            placeholder="Saisir votre message ici"
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
