import { useState, useEffect } from 'react'
import useApi from '../../hooks/useApi'
import InputText from '../../components/input/InputText'
import Btn from '../../components/button/Btn'
import InputCheckbox from '../../components/input/InputCheckbox'
import Condition from './Condition'
import { toast } from 'react-toastify'

export default function Register() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("")
    const [acceptCondition, setAcceptCondition] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showCondition, setShowCondition] = useState(false)
    const validEmail = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const validPassword = /^.{6}/
    const [secondsRemaining, setSecondsRemaining] = useState(0)

    useEffect(() => {
        if (secondsRemaining > 0) {
            const timer = setInterval(() => {
                setSecondsRemaining(prevSeconds => prevSeconds - 1)
            }, 1000)

            return () => clearInterval(timer)
        }
    }, [secondsRemaining])

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
    }

    useEffect(() => {
        if (showConfirmation) {
            setBtnDisabled(!email.trim() || !validEmail.test(email) || !password.trim() || !validPassword.test(password)
                || !passwordConfirmation.trim() || password !== passwordConfirmation || !validPassword.test(confirmationCode) || loading
            )
        } else {
            setBtnDisabled(!email.trim() || !validEmail.test(email) || !password.trim() || !validPassword.test(password)
                || !passwordConfirmation.trim() || password !== passwordConfirmation || !acceptCondition || loading
            )
        }

    }, [email, password, passwordConfirmation, confirmationCode, acceptCondition, showConfirmation, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = showConfirmation ?
            {
                email: email,
                password: password,
                password_confirmation: passwordConfirmation,
                email_verification_code: confirmationCode
            }
            :
            {
                email: email,
                password: password,
                password_confirmation: passwordConfirmation
            }

        try {
            const response = await useApi.post(
                showConfirmation ? '/register_confirmation' : '/register'
                , data)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                if (!showConfirmation) {
                    setShowConfirmation(true)
                    setSecondsRemaining(60)
                    toast.success(response.data.success)
                } else {
                    toast.success(response.data.success)
                    localStorage.setItem("token", response.data.token)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setBtnDisabled(false)
        }
    }

    const handleResend = async () => {
        setLoading(true)

        const data = {
            email: email,
            password: password
        }

        try {
            const response = await useApi.post('/resend_confirmation', data)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                setSecondsRemaining(60)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setBtnDisabled(false)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            {showCondition &&
                <Condition
                    setShowCondition={setShowCondition}
                    acceptCondition={acceptCondition}
                    setAcceptCondition={setAcceptCondition} />
            }
            <InputText
                label='Email'
                placeholder='Saisir votre adresse email'
                type='email'
                value={email}
                onChange={setEmail}
                className='large'
                required
                disabled={showConfirmation} />
            <InputText
                label='Mot de passe'
                placeholder='Saisir votre mot de passe'
                type='password'
                value={password}
                onChange={setPassword}
                className='large'
                required
                disabled={showConfirmation} />
            <InputText
                label='Confirmation mot de passe'
                placeholder='Confirmer votre mot de passe'
                type='password'
                value={passwordConfirmation}
                onChange={setPasswordConfirmation}
                className='large'
                required
                disabled={showConfirmation} />
            {!showConfirmation &&
                <InputCheckbox
                    label={<>J'accepte les <span onClick={() => setShowCondition(true)} style={{ color: '#ff3300', fontSize: 'inherit' }}>termes et conditions</span> d'utilisation</>}
                    name="condition"
                    checked={acceptCondition}
                    onChange={setAcceptCondition}
                    disabled
                />
            }

            {showConfirmation &&
                <InputText
                    label='Code de confirmation'
                    placeholder='Saisir le code de confirmation'
                    value={confirmationCode}
                    onChange={setConfirmationCode}
                    className='large'
                    required />
            }
            {secondsRemaining > 0 &&
                <div className='auth-forgot'>
                    <span>{formatTime(secondsRemaining)}</span>
                </div>
            }
            {(showConfirmation && secondsRemaining === 0 && !loading) &&
                <div className='auth-forgot'>
                    <div onClick={() => handleResend()}>Renvoyer code de confirmation</div>
                </div>
            }

            <Btn
                label={loading ? "Chargement..." : (showConfirmation ? "Confirmer" : "S'inscrire")}
                className='btn-primary large'
                type='submit'
                disabled={btnDisabled || loading}
            />
        </form>
    )
}
