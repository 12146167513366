import { useState, useEffect } from 'react'
import { Outlet } from "react-router-dom"
import useScreenSize from '../hooks/useScreenSize'
import EditUser from '../pages/user/EditUser'
import Sidebar from "./sidebar/Sidebar"
import SidebarModal from './sidebar/SidebarModal'

import NavBar from './navbar/Navbar'
import ChangePassword from '../pages/user/ChangePassword'

export default function PrivateRoute({ auth }) {

    const screenSize = useScreenSize()
    const [showSidebar, setShowSidebar] = useState(false)
    const [showLogoutModal, setShowLogoutModal] = useState(false)

    useEffect(() => {
        setShowSidebar(false)
    }, [screenSize])

    return (
        <>
            <NavBar auth={auth} showSidebar={showSidebar} setShowSidebar={setShowSidebar} showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} />
            {(!["sm", "md", "lg"].includes(screenSize) && !auth.must_change_password && !auth.must_complete_profil) &&
                <Sidebar
                    auth={auth} showSidebar={showSidebar} setShowSidebar={setShowSidebar} setShowLogoutModal={setShowLogoutModal} />
            }
            {(showSidebar && ["sm", "md", "lg"].includes(screenSize)) &&
                <SidebarModal auth={auth} showSidebar={showSidebar} setShowSidebar={setShowSidebar} setShowLogoutModal={setShowLogoutModal} />
            }
            <div className='main-content'>
                <div className="main-container">

                    {
                        auth.must_change_password ? <ChangePassword />
                            : auth.must_complete_profil ? <EditUser auth={auth} title="Information à remplir" action={"/utilisateur/complete_profile/" + auth.id} />
                                : <Outlet />
                    }
                </div>
            </div>
        </>

    )
}
