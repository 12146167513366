import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import Btn from '../../components/button/Btn'
import { FiSearch } from "react-icons/fi"
import { IoIosClose } from "react-icons/io"
import { FaCircle } from "react-icons/fa"
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import BLOG from '../../assets/PROFILE/BLOG.jpg'
import moment from 'moment/moment'

export default function Blog({ auth }) {
    const navigate = useNavigate()
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const [formattedDates, setFormattedDates] = useState([])

    const formatDates = (blogs) => {
        const formattedDates = blogs.map(bl => moment(bl.created_at).from(bl.datetime))
        setFormattedDates(formattedDates)
    }

    const [blogs, setBlogs] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [showMyBlog, setShowMyBlog] = useState(false)

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", blogs.length)
        }
        if (showMyBlog) {
            params.set("me", showMyBlog)
        }

        if (searchValue) {
            params.set("detail", searchValue)
        }

        try {
            const res = await useApi.get('/blog' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setBlogs(res.data.blogs)
                    } else {
                        const list = blogs.slice().concat(res.data.blogs)
                        setBlogs(list)
                    }
                    setallDataLoaded(res.data.blogs.length < 30)
                    formatDates(res.data.blogs)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 1000)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue, showMyBlog])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('blog-channel')
            .listen('.created', (event) => {
                setBlogs(prevBlogs => {
                    if (!prevBlogs.some(blog => blog.id === event.blog.id)) {
                        return [event.blog, ...prevBlogs]
                    }
                    return prevBlogs
                });

            })
            .listen('.updated', (event) => {
                setBlogs(prevBlogs => prevBlogs.map(blog => {
                    if (blog.id === event.blog.id) {
                        return event.blog
                    } else {
                        return blog
                    }
                }))
            })
    }, [blogs])

    return (
        <div className='blog'>
            <div className="blog-view-header">
                <h1>Découvrez nos blogs</h1>
                <p>
                    Plongez dans l'univers des blogs et partagez vos idées, remarque et expériences avec la communauté.
                </p>
                <Btn
                    className='btn btn-outline'
                    label="Nouveau"
                    onClick={() => navigate('/app/blog/add')} />
                <div className="input-blog-filter">
                    <span><FiSearch size={22} /></span>
                    <input
                        placeholder="Saisir votre recherche ici"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {searchValue &&
                        <IoIosClose size={22} onClick={() => setSearchValue("")} />
                    }
                </div>
            </div>
            <div className="blog-list-tabs">
                <span onClick={() => setShowMyBlog(false)} className={!showMyBlog ? "blog-list-tabs-active" : ""}>Tous</span>
                <span onClick={() => setShowMyBlog(true)} className={showMyBlog ? "blog-list-tabs-active" : ""}>Mes blogs</span>
            </div>

            {loading ? <PageLoader />
                :
                <>
                    {blogs.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <>
                            {blogs.slice(0, 1).map((bl, index) => (
                                <div className="blog-top4-first" key={index}>
                                    <div className="img-container">
                                        <img src={bl.photo ? useFileUrl + bl.photo : BLOG} alt="img" />
                                    </div>
                                    <div className="blog-details">
                                        <div className='categorie'>
                                            <span className='important'>{bl.categorie}</span>
                                            <FaCircle size={5} className='separator' />
                                        </div>
                                        <h1 onClick={() => navigate('/app/blog/show/' + bl.id)}>{bl.titre}</h1>
                                        <p>{bl.contenu}</p>
                                        <div className="blog-details-footer">
                                            <div className="img-container">
                                                <img src={
                                                    bl.createur_photo ? useFileUrl + bl.createur_photo
                                                        : (bl.createur_sexe == "homme" ? HOMME : FEMME)
                                                } alt="img" />
                                            </div>
                                            <p>
                                                {bl.createur_prenom}
                                                <FaCircle size={5} className='separator' />
                                                {moment(bl.created_at).fromNow()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <InfiniteScroll
                                dataLength={blogs.length}
                                next={fetchMoreData}
                                hasMore={!allDataLoaded}
                                loader={<PageLoader />}
                            >
                                <div className="blog-list-container">
                                    {
                                        blogs.slice(1).map((bl, index) => (
                                            <div className="blog-card" key={bl.id}>
                                                <div className="img-container">
                                                    <img src={bl.photo ? useFileUrl + bl.photo : BLOG} alt="img" />
                                                </div>
                                                <div className="card-blog-details">
                                                    <div className='categorie'>
                                                        <span className='important'>{bl.categorie}</span>
                                                        <FaCircle size={5} className='separator' />
                                                    </div>
                                                    <h1 onClick={() => navigate('/app/blog/show/' + bl.id)}>{bl.titre}</h1>
                                                    <p>{bl.contenu}</p>
                                                    <div className="blog-details-footer">
                                                        <div className="img-container">
                                                            <img src={
                                                                bl.createur_photo ? useFileUrl + bl.createur_photo
                                                                    : (bl.createur_sexe == "homme" ? HOMME : FEMME)
                                                            } alt="img" />
                                                        </div>
                                                        <p>
                                                            {bl.createur_prenom}
                                                            <FaCircle size={5} className='separator' />
                                                            {moment(bl.created_at).fromNow()}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </InfiniteScroll>
                        </>
                    }
                </>
            }

        </div>
    )
}
