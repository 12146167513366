import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import useScreenSize from '../../hooks/useScreenSize'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import FEMME from "../../assets/PROFILE/femme.jpg"
import HOMME from "../../assets/PROFILE/homme.jpg"
import InputSendWithEmoji from '../../components/input/InputSendWithEmoji'
import { IoMdArrowBack } from 'react-icons/io'

export default function ShowConversation({ auth, currentId, setCurrentId, messages, setMessages }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const scrollableDivRef = useRef(null)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const screenSize = useScreenSize()
    const [conversation, setConversation] = useState([])
    const [currentMessageId, setCurrentMessageId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    const [contenu, setContenu] = useState("")
    const [files, setFiles] = useState([])
    const [sendLoading, setSendLoading] = useState(false)
    const [sendError, setSendError] = useState("")

    const formatDates = (messages) => {
        const formattedDates = messages.map(mes => moment(mes.created_at).from(mes.datetime))
        setFormattedDates(formattedDates)
    }

    const handleCoBack = () => {
        setCurrentId(null)
        const params = new URLSearchParams(locationSearch)
        if (params) {
            params.delete("id")
            navigate({
                pathname: pathname,
                search: params.toString()
            })
        }
    }

    useEffect(() => {
        setContenu("")
    }, [currentId])

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        params.set("conversation_id", currentId)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", messages.length)
        }

        try {
            const res = await useApi.get('/message' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setConversation(res.data.conversation_titre)
                    if (initial) {
                        setMessages(res.data.messages)
                    } else {
                        const list = messages.slice().concat(res.data.messages)
                        setMessages(list)
                    }
                    setallDataLoaded(res.data.messages.length < 30)
                    formatDates(res.data.messages)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch, currentId])

    const fetchMoreData = () => {
        console.log("fetch more")
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(messages)
        const interval = setInterval(() => {
            formatDates(messages)
        }, 60000)
        return () => clearInterval(interval)
    }, [messages])

    useEffect(() => {
        window.Echo.channel('message-channel')
            .listen('.created', (event) => {
                setMessages(prevMessages => {
                    if (!prevMessages.some(message => message.id === event.message.id)) {
                        return [event.message, ...prevMessages]
                    }
                    return prevMessages
                });

            })
            .listen('.updated', (event) => {
                setMessages(prevMessages => prevMessages.map(message => {
                    if (message.id === event.message.id) {
                        return event.message
                    } else {
                        return message
                    }
                }))
            })
    }, [messages])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSendLoading(true)

        const data = new FormData()
        files.forEach((file, index) => {
            data.append(`fichiers[${index}]`, file)
        })
        data.append('conversation_id', currentId)
        data.append('conversation_link', true)
        data.append('contenu', contenu)
        try {
            const response = await useApi.post('/message/add',
                data, tokenHeaders)

            if (response.data.error) {
                setSendError(response.data.error)
                setSendLoading(false)
            } else if (response.data.success) {
                setSendError(null)
                setFiles([])
                setContenu("")
                setSendLoading(false)
                updateData(true)
            }
        } catch (error) {
            console.error(error)
            setSendError("Erreur d'envoi, réessayez.")
            setSendLoading(false)
        }
    }

    return (
        <div className='show-conversation'>
            {loading ? <PageLoader />
                :
                <>
                    <div className="show-conversation-header">
                        {["sm", "md"].includes(screenSize) &&
                            <div style={{ paddingRight: "10px" }} onClick={() => handleCoBack()}>
                                <IoMdArrowBack size={26} className='pointer' />
                            </div>
                        }
                        <h4>{conversation && conversation.recipient_id ? (conversation.recipient_id === auth.id ? conversation.createur_prenom : conversation.recipient_prenom) : conversation.titre}</h4>
                    </div>
                    <div className="show-conversation-liste" id="show-conversation-liste">
                        {messages.length == 0 ? <h3 className='justify-center'>Aucun message trouvé</h3>
                            :
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={fetchMoreData}
                                hasMore={!allDataLoaded}
                                inverse={true}
                                loader={<PageLoader />}
                                scrollableTarget="show-conversation-liste"

                            >
                                {
                                    messages.map((mes, index) => (
                                        <div className={`show-conversation-container ${mes.user_id === auth.id ? 'conversation-show-me' : 'conversation-show-other'}`} key={index}>
                                            <div className={`show-conversation-card ${mes.user_id === auth.id ? 'conversation-card-me' : 'conversation-card-other'}`}>
                                                <div className="conversation-message">
                                                    {mes.user_id !== auth.id &&
                                                        <div className="img-container">
                                                            <img src={mes.createur_photo ? useFileUrl + mes.createur_photo : (mes.createur_sexe === "homme" ? HOMME : FEMME)} alt="img" />
                                                        </div>
                                                    }
                                                    <p onClick={() => { setCurrentMessageId(mes.id); }} className={`${mes.user_id === auth.id ? 'msg-me' : 'msg-other'}`}>
                                                        {mes.contenu}
                                                    </p>
                                                </div>
                                                {currentMessageId === mes.id &&
                                                    <span className={`${mes.user_id === auth.id ? 'msg-date-me' : 'msg-date-other'}`}>{moment(mes.created_at).fromNow()}</span>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </InfiniteScroll>
                        }
                    </div>
                    <div className="show-message-footer">
                        <InputSendWithEmoji
                            value={contenu}
                            onChange={setContenu}
                            placeholder="Saisir votre message ici"
                            handleSubmit={handleSubmit}
                            top
                        />
                    </div>
                </>
            }
        </div >
    )
}
