import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment/moment';

export default function LineChart({ data, displayTitle, hideLegend, labelPosition }) {
    const canvasRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (data) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const dates = data.map(item => moment(item.date).format("DD/MM/YYYY"));
            const dataset = {
                label: 'Data',
                data: data.map(item => item.count),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.5,
            };

            const ctx = canvasRef.current.getContext('2d');
            chartInstance.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: dates,
                    datasets: [dataset],
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    animation: {
                        duration: 1000,
                    },
                    plugins: {
                        legend: hideLegend ? false : {
                            position: labelPosition ? labelPosition : 'top',
                            labels: {
                                boxWidth: 12,
                            },
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: displayTitle ? true : false,
                                text: 'Date',
                            },
                        },
                        y: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: displayTitle ? true : false,
                                text: 'Nombre',
                            },
                        },
                    },
                },
            });
        }
    }, [data, displayTitle, hideLegend, labelPosition]);

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%', maxWidth: '100%' }} />;
}
