import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputText from '../input/InputText'
import Btn from '../button/Btn'
import PageLoader from '../loader/PageLoader'

export default function InputTarif({ label, value, onChange, required, hideInput, closeModal, disabled, useLink }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const location = useLocation()
    const locationSearch = useLocation().search
    const [showModal, setShowModal] = useState(false)
    const [tarifs, setTarifs] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const handleSelectTarif = (ta) => {
        if (useLink) {
            let params = new URLSearchParams(location.search)
            params.set("tarif_id", ta.id)
            navigate(location.pathname + "?" + params)
        }
        setShowModal(false)
        onChange(ta)
        if (closeModal) closeModal()
    }

    const handleCloseModal = () => {
        setShowModal(false)
        if (closeModal) closeModal()
    }

    const updateData = (initial) => {
        let isMounted = true;
        const params = new URLSearchParams(locationSearch)
        params.set("search", searchValue)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        }
        else
            params.set("offset", tarifs.length)
        useApi.get('/tarif'
            + '?' + params, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    if (res.data.error)
                        console.error(res.data.error)
                    else {
                        if (initial)
                            setTarifs(res.data.tarifs)
                        else {
                            const list = tarifs.slice().concat(res.data.tarifs)
                            setTarifs(list)
                        }
                        setallDataLoaded(res.data.tarifs.length < 30)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
            })
        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])


    //LOAD MORE DATA
    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('tarif-channel')
            .listen('.created', (event) => {
                setTarifs(prevTarifs => {
                    if (!prevTarifs.some(tarif => tarif.id === event.tarif.id)) {
                        return [event.tarif, ...prevTarifs]
                    }
                    return prevTarifs
                })

            })
            .listen('.updated', (event) => {
                setTarifs(prevTarifs => prevTarifs.map(tarif => {
                    if (tarif.id === event.tarif.id) {
                        return event.tarif
                    } else {
                        return tarif
                    }
                }))
            })
    }, [tarifs])


    return (
        <>
            {!hideInput &&
                <InputText
                    label={label ? label : "Tarif"}
                    placeholder='Cliquer pour chercher'
                    value={value ? value.prix + " MGA [ " + value.categorie + " ]" : ''}
                    disabled={disabled}
                    className='large'
                    required={required}
                    readOnly
                    onClick={() => setShowModal(true)} />
            }
            {(hideInput || showModal) &&
                <div className='input-modal'>
                    <div>
                        <div>
                            <InputText
                                label={label ? label : "Tarif"}
                                placeholder='Saisir votre recherche'
                                value={searchValue}
                                onChange={setSearchValue}
                                className='large'
                                required />
                            <div className="justify-right">
                                <Btn
                                    label="Annuler"
                                    className='btn-outline small'
                                    onClick={handleCloseModal} />
                            </div>
                        </div>
                        {loading ? <PageLoader />
                            :
                            <>
                                {tarifs.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                                    :
                                    <div className="list-modal-container">
                                        <InfiniteScroll
                                            dataLength={tarifs.length}
                                            next={fetchMoreData}
                                            hasMore={!allDataLoaded}
                                            loader={<PageLoader />}
                                        >
                                            {
                                                tarifs.map((ta) => (
                                                    <div className="list-modal" key={ta.id} onClick={() => handleSelectTarif(ta)}>
                                                        <h3>{ta.trajet}</h3>
                                                        <span>{ta.prix + " [" + ta.categorie + "]"}</span>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            }

        </>
    )
}
