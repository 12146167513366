import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import { IoMdClose } from "react-icons/io"
import InputText from '../../components/input/InputText'
import ReservationCancel from './ReservationCancel'


export default function ReservationTraveler({ auth, name, value, voyage }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const [currentId, setCurrentId] = useState([])
    const [reservations, setReservations] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [selectedReservations, setSelectedReservations] = useState({})
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [motif, setMotif] = useState("")

    const updateData = async () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        params.set("detail", searchValue)
        params.append(name, value)
        try {
            const res = await useApi.get('/reservation/tab' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setReservations(res.data.reservations.filter(reservation => reservation.status !== "NON ASSIGNE" && reservation.client_id !== null))
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])


    useEffect(() => {
        window.Echo.channel('reservation-channel')
            .listen('.created', (event) => {
                setReservations(prevReservations => {
                    if (!prevReservations.some(reservation => reservation.id === event.reservation.id)) {
                        return [event.reservation, ...prevReservations]
                    }
                    return prevReservations
                });

            })
            .listen('.updated', (event) => {
                setReservations(prevReservations => prevReservations.map(reservation => {
                    if (reservation.id === event.reservation.id) {
                        return event.reservation
                    } else {
                        return reservation
                    }
                }))
            })
    }, [reservations])

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])

    //SELECTION MULTIPLE
    const toggleReservationSelection = (reservationId, clientId) => {
        setSelectedReservations(prevSelected => {
            const updatedSelection = { ...prevSelected }

            // Désélectionne les réservations du client précédent
            Object.keys(prevSelected).forEach(key => {
                if (key !== clientId) {
                    updatedSelection[key] = []
                }
            });

            // Sélectionne ou désélectionne la réservation actuelle
            if (updatedSelection[clientId]?.includes(reservationId)) {
                updatedSelection[clientId] = updatedSelection[clientId].filter(id => id !== reservationId)
            } else {
                updatedSelection[clientId] = [...(updatedSelection[clientId] || []), reservationId]
            }

            return updatedSelection
        })
    }

    // Grouper les réservations par client_id
    const groupedReservations = reservations.reduce((acc, reservation) => {
        const clientId = reservation.client_id
        if (!acc[clientId]) {
            acc[clientId] = [reservation]
        } else {
            acc[clientId].push(reservation)
        }
        return acc
    }, {})

    const doAnnule = async () => {
        setLoading(true)
        const data = {
            selected_reservations: selectedReservations,
            motif: motif,
            voyage_id: voyage.id
        }
        try {
            const response = await useApi.post("/reservation/do_annule", data, tokenHeaders)
            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                updateData()
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                setSelectedReservations({})
                setShowConfirmModal(false)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const closeModal = () => {
        setMotif("")
        setShowConfirmModal(false)
    }

    return (
        loading ? <PageLoader />
            :
            <>
                {showConfirmModal &&
                    <ReservationCancel
                        motif={motif}
                        setMotif={setMotif}
                        more={selectedReservations.length > 1}
                        loading={loading}
                        confirm={doAnnule}
                        closeModal={closeModal} />
                }
                <InputText
                    value={searchValue}
                    onChange={setSearchValue}
                    className='large'
                    placeholder="Tapez votre recherche" />
                {reservations.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                    :
                    Object.keys(groupedReservations).map((clientId) => (
                        <div key={clientId} style={{ borderTop: '1px dashed #ddd', marginTop: '10px' }}>
                            <h3>{`${(groupedReservations[clientId][0].client_sexe == "homme" ? "Mr " : (groupedReservations[clientId][0].client_sexe == "femme" ? "Mdm " : "")) + groupedReservations[clientId][0].client_nom} ${groupedReservations[clientId][0].client_prenom} - ${formatPhoneNumber(groupedReservations[clientId][0].client_telephone)}`}</h3>
                            <h4>{`Nombre de places : ${groupedReservations[clientId].length}`}</h4>
                            {selectedReservations[clientId] && selectedReservations[clientId].length > 0 &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px 12px',
                                        background: '#f9f9f9',
                                        borderRadius: '3px',
                                        cursor: 'pointer'
                                    }} onClick={() => setSelectedReservations({})}>
                                        {`${selectedReservations[clientId].length} séléctionné${selectedReservations[clientId].length > 1 ? 's' : ''} `}
                                        <IoMdClose size={16} style={{ transform: 'translateY(-1px)', marginLeft: '5px' }} />
                                    </div>
                                    <div className="action-container" >
                                        <span onClick={() => setShowConfirmModal(true)}>{`Annuler réservation${selectedReservations[clientId].length > 1 ? 's' : ''}`}</span>
                                    </div>
                                </div>
                            }
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='table-ellipsis'>{'Designation Place'}</th>
                                        <th style={{ textAlign: 'center' }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedReservations[clientId].map((re) => (
                                        <tr className={`${selectedReservations[clientId]?.includes(re.id) ? 'selected' : ''}`} key={re.id}
                                            onClick={() => {
                                                toggleReservationSelection(re.id, clientId);
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedReservations[clientId]?.includes(re.id) || ''}
                                                    onChange={() => {
                                                        toggleReservationSelection(re.id, clientId)
                                                    }}
                                                />
                                            </td>
                                            <td>{re.designation_place}</td>
                                            <td style={{ display: 'flex', justifyContent: 'center' }}><div className={re.status == "PAYE" ? "badge-round badge-round-green" : "badge-round badge-round-orange"} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))
                }
            </>
    )
}
