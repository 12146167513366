import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionGare from './ActionGare'
import ShowTab from '../../view/ShowTab'
import { MdOutlineLocationOn } from "react-icons/md"
import { MdOutlinePhoneForwarded } from "react-icons/md"
import { FaUserTie } from "react-icons/fa"
import { PiUserCirclePlusFill } from "react-icons/pi"
import { MdOutlinePayment } from "react-icons/md"

export default function ShowGare({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [gare, setGare] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/gare_routiere/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setGare(res.data.gare_routiere)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(gare)
    }, [gare])

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('gare-routiere-channel')
            .listen('.updated', (event) => {
                setGare(prevGare => {
                    if (prevGare && prevGare.id === event.gare.id) {
                        return { ...prevGare, ...event.gare }
                    }
                    return prevGare
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {gare &&
                    <div className='view-show-container'>
                        <ShowHeader data={gare} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <h3>{gare.designation}</h3>
                            <p><MdOutlineLocationOn size={16} className='svg-text' /> <span className='text'>{gare.adresse}</span>, Ville : <span className='text'>{gare.ville}</span>, Province : <span className='text'>{gare.province}</span></p>
                            <p><FaUserTie size={16} className='svg-text' /> <span className='text'>{gare.resp_gare_nom + " " + gare.resp_gare_prenom + " <" + gare.resp_gare_email + ">"}</span></p>
                            <p><MdOutlinePhoneForwarded size={16} className='svg-text' /> <span className='text'>{formatPhoneNumber(gare.resp_gare_telephone)}</span></p>
                            <p><MdOutlinePayment size={16} className='svg-text' />
                                Mvola : <span className='text'>{formatPhoneNumber(gare.telephone_telma)}</span>,
                                Orange Money : <span className='text'>{formatPhoneNumber(gare.telephone_orange)}</span>,
                                Airtel Money : <span className='text'>{formatPhoneNumber(gare.telephone_airtel)}</span>,
                            </p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{gare.createur_prenom + " <" + gare.createur_email + ">"}</span></p>
                            <ActionGare auth={auth} gare={gare} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="gare_routiere_id" value={gare.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
