import { Link } from 'react-router-dom'

export default function ActionSite({ auth, site }) {

    return (
        <div className='action-container'>
            {
                (["superadmin", "admin", "admin_site"].includes(auth.role)) &&
                <span>
                    <Link to={"/app/site/edit/" + site.id}>Editer</Link>
                </span>
            }
            {
                (["superadmin", "admin", "admin_site"].includes(auth.role) && site.localisation_id) &&
                <span>
                    <Link to={`/app/map/edit?site_id=${site.id}&id=${site.localisation_id}`}>Modifier localisation</Link>
                </span>
            }
            {
                (auth && !site.localisation_id) &&
                <span>
                    <Link to={`/app/map/add?site_id=${site.id}`}>Ajouter localisation</Link>
                </span>
            }
            {
                (auth && site.localisation_id) &&
                <span>
                    <Link to={`/app/map/show?site_id=${site.id}&id=${site.localisation_id}`}>localisation</Link>
                </span>
            }
        </div>
    )
}