import moment from 'moment'
import usePrice from '../../hooks/usePrice'
import useFormatPhone from '../../hooks/useFormatPhone'
import CarseatPrint from '../../components/carseat/CarseatPrint'
import PRINT_BG from '../../assets/LOGO/PRINT_BG.png'

export default function ReservationTicket({
    reservationTicketRef,
    selectedClient,
    voyage,
    selectedSeats,
    setSelectedSeats,
    reservations,
    setShowDetail,
    setSelectedClient,
    handleUserSelection,
    clientPaiement
}) {
    const formatPrice = usePrice()
    const formatPhoneNumber = useFormatPhone()

    return (
        <div className="print-container">
            <div className="reservation-ticket" ref={reservationTicketRef}>
                <img src={PRINT_BG} alt="bg" style={{ position: 'absolute', right: '5%', Top: '0', opacity: '0.5', width: '100%', height: '95%', zIndex: '-1' }} />
                <div className='ticket-title'><h4>Coopérative {moment(voyage.cooperative_created_at).format("YYYY") + "/" + ("00000" + voyage.cooperative_reference).slice(-6)}</h4><div /></div>
                <div className="ticket-detail">
                    <h4>{voyage.cooperative}</h4>
                    <p>Nif: <span >{voyage.cooperative_nif}</span>, Stat: <span >{voyage.cooperative_stat}</span></p>
                    <p>Contact: <span >{formatPhoneNumber(voyage.cooperative_telephone) + " / " + voyage.cooperative_email} </span></p>
                </div>
                <div className='ticket-title'><h4>Client {moment(selectedClient.client_created_at).format("YYYY") + "/" + ("00000" + selectedClient.client_reference).slice(-6)}</h4><div /></div>
                <div className="ticket-detail">
                    <h4>{selectedClient.client_nom + " " + selectedClient.client_prenom}</h4>
                    <p>Contact: <span >{formatPhoneNumber(selectedClient.client_telephone)}</span></p>
                </div>
                <div className='ticket-title'><h4>Voyage</h4><div /></div>
                <div className="ticket-detail" style={{ marginBottom: "10px" }}>
                    <p>{voyage.designation}</p>
                    <p>Date de départ : <span >{moment(voyage.date_depart).format('D MMMM YYYY')}</span></p>
                    <p>Heure de départ : <span >{moment(voyage.date_depart).format('HH[h] mm')}</span></p>
                </div>
                <CarseatPrint
                    selectedSeats={selectedSeats}
                    setSelectedSeats={setSelectedSeats}
                    seats={reservations}
                    setShowDetail={setShowDetail}
                    reservations={reservations}
                    setSelectedClient={setSelectedClient}
                    handleUserSelection={handleUserSelection} />
                <div className='ticket-title'><h4>Montant</h4><div /></div>
                <div className="ticket-detail">
                    {clientPaiement.cout_total &&
                        <p> Total : <span >{formatPrice(clientPaiement.cout_total) + " / " + clientPaiement.nombre_de_places + (clientPaiement.nombre_de_places > 1 ? " places" : " place")}</span></p>
                    }
                    {clientPaiement.total_payer &&
                        <p>Montant payé : <span >{formatPrice(clientPaiement.total_payer)}</span></p>
                    }
                    {clientPaiement.reste_a_payer > 0 &&
                        <p>Reste à payer : <span>{formatPrice(clientPaiement.reste_a_payer)}</span></p>
                    }
                    {clientPaiement.total_payer > clientPaiement.cout_total &&
                        <p>A remboursé : <span>{formatPrice(clientPaiement.total_payer - clientPaiement.cout_total)}</span></p>
                    }
                </div>
                <div style={{ position: 'fixed', bottom: '20px', opacity: '0.5', width: '100%', display: 'flex', justifyContent: 'center' }}>DECOUVREZ - RESERVEZ - VOYAGEZ</div>
            </div>
        </div >
    )
}
