import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import InputText from '../../components/input/InputText'
import Btn from '../../components/button/Btn'
import { toast } from 'react-toastify'

export default function Login() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [telephone, setTelephone] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("")
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [showResendConfirmation, setShowResendConfirmation] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const validEmail = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const validPassword = /^.{6}/
    const validPhone = /^(032|033|034|038)\d{7}$/
    const [secondsRemaining, setSecondsRemaining] = useState(0)

    useEffect(() => {
        if (secondsRemaining > 0) {
            const timer = setInterval(() => {
                setSecondsRemaining(prevSeconds => prevSeconds - 1)
            }, 1000)

            return () => clearInterval(timer)
        }
    }, [secondsRemaining])

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
    }

    useEffect(() => {
        if (showForgotPassword) {
            setBtnDisabled(!validEmail.test(email) || !validPhone.test(telephone))
        } else if (showResendConfirmation) {
            setBtnDisabled(!validEmail.test(email) || !validPassword.test(password) || !validPassword.test(confirmationCode))
        } else {
            setBtnDisabled(!email.trim() || !validEmail.test(email) || !password.trim() || !validPassword.test(password))
        }

    }, [email, password, telephone, confirmationCode])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = showForgotPassword ?
            {
                email: email,
                telephone: telephone,
            }
            :
            (showResendConfirmation ?
                {
                    email: email,
                    password: password,
                    password_confirmation: password,
                    email_verification_code: confirmationCode
                }
                :
                {
                    email: email,
                    password: password,
                }
            )

        try {
            const response = await useApi.post(showForgotPassword ? '/forgot_password' : (showResendConfirmation ? '/register_confirmation' : '/login'), data)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.error_confirmation) {
                setShowResendConfirmation(true)
                setLoading(false)
            } else if (response.data.token || response.data.success) {
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                if (showForgotPassword) {
                    setShowForgotPassword(false)
                    setTelephone("")
                    setPassword("")
                } else {
                    localStorage.setItem("token", response.data.token)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setBtnDisabled(false)
        }
    }

    const handleResend = async () => {
        setLoading(true)

        const data = {
            email: email,
            password: password
        }

        try {
            const response = await useApi.post('/resend_confirmation', data)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                setSecondsRemaining(60)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setBtnDisabled(false)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <InputText
                label='Email'
                placeholder='Saisir votre adresse email'
                type='email'
                value={email}
                onChange={setEmail}
                className='large'
                required />
            {!showForgotPassword &&
                <InputText
                    label='Mot de passe'
                    placeholder='Saisir votre mot de passe'
                    type='password'
                    value={password}
                    onChange={setPassword}
                    className='large'
                    required />
            }
            {showForgotPassword &&
                <InputText
                    label='Téléphone'
                    placeholder='Saisir votre n° téléphone'
                    value={telephone}
                    onChange={setTelephone}
                    className='large'
                    required />
            }
            {showResendConfirmation &&
                <InputText
                    label='Code de confirmation'
                    placeholder='Saisir le code de confirmation'
                    value={confirmationCode}
                    onChange={setConfirmationCode}
                    className='large'
                    required />
            }


            {!showResendConfirmation &&
                <div className='auth-forgot'>
                    <>
                        {showForgotPassword ?
                            <div onClick={() => { setShowForgotPassword(false); setShowResendConfirmation(false); setConfirmationCode(""); setTelephone("") }}>Revenir à la connexion</div>
                            :
                            <div onClick={() => setShowForgotPassword(true)}>Mot de passe oublié?</div>
                        }

                    </>
                </div>
            }

            {secondsRemaining > 0 &&
                <div className='auth-forgot'>
                    <span>{formatTime(secondsRemaining)}</span>
                </div>
            }
            {(showResendConfirmation && secondsRemaining === 0 && !loading) &&
                <div className='auth-forgot'>
                    <div onClick={() => handleResend()}>Renvoyer code de confirmation</div>
                </div>
            }

            <Btn
                label={loading ? "Chargement..." : (showForgotPassword ? "Réinitialiser" : "Se connecter")}
                className='btn-primary large'
                type='submit'
                disabled={btnDisabled || loading}
            />
        </form>
    )
}
