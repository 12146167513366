const initialState = {
    vehicules: []
}

const vehiculeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VEHICULES':
            return {
                ...state,
                vehicules: action.payload
            }
        case 'ADD_VEHICULE':
            return {
                ...state,
                vehicules: [action.payload, ...state.vehicules]
            }
        case 'UPDATE_VEHICULE':
            return {
                ...state,
                vehicules: state.vehicules.map(ve => ve.id === action.payload.id ? action.payload : ve)
            }
        default:
            return state
    }
}

export default vehiculeReducer
