import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import usePrice from '../../hooks/usePrice'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"
import moment from 'moment'

export default function Guichet({ auth, guichets, setGuichets, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const formatPhoneNumber = useFormatPhone()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let searchItems = [
        { label: 'Client', name: 'client_id', type: 'string' },
        { label: 'Coopérative', name: 'cooperative_id', type: 'string' },
        { label: 'Date de départ', name: 'date_depart', type: 'string' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", guichets.length)
        }

        try {
            const res = await useApi.get('/paiement/guichet' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setGuichets(res.data.guichets)
                    } else {
                        const list = guichets.slice().concat(res.data.guichets)
                        setGuichets(list)
                    }
                    setallDataLoaded(res.data.guichets.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    return (
        <>
            <ViewHeader
                label="Guichet"
                searchItems={searchItems}
                authorisation={false} />
            {loading ? <PageLoader />
                :
                <>
                    {guichets.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={guichets.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                guichets.map((gu, index) => (
                                    <div className={`view-card-container ${currentId && currentId == gu.id ? 'selected' : ''}`} key={gu.id} onClick={() => setCurrentId(gu.id)}>
                                        <div className="view-card-space-between">
                                            <div className="view-card-with-img">
                                                <div className="img-container">
                                                    <img src={gu.client_profil ? useFileUrl + gu.client_profil : (gu.client_sexe == "homme" ? Homme : Femme)} alt="img" />
                                                </div>
                                                <div className="card-with-img-detail">
                                                    <div className="badge">
                                                        <div
                                                            className={`badge-shape ${gu.total_payer === null ?
                                                                "bg-purple"
                                                                : gu.reste_a_payer > 0 ? "bg-orange" : "bg-green"
                                                                }`
                                                            }
                                                        />
                                                        {gu.total_payer === null ?
                                                            "PAIEMENT EN ATTENTE"
                                                            : gu.reste_a_payer > 0 ? "AVANCE" : "PAYE"
                                                        }
                                                    </div>
                                                    <h2 style={{ margin: '0' }}>{gu.cooperative}</h2>
                                                    <p>Client : {gu.client_nom + " " + gu.client_prenom}</p>
                                                    <p>Contact client : {formatPhoneNumber(gu.client_telephone)}</p>
                                                    <p>{gu.trajet_designation}</p>
                                                    <p>Date du voyage : {moment(gu.date_depart).format('D MMMM YYYY [à] HH[h] mm')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
