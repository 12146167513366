import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import { BiMessageSquareEdit } from "react-icons/bi"
import { FaRegShareFromSquare } from "react-icons/fa6"
import { RiDeleteBin5Line } from "react-icons/ri"
import { IoArchiveOutline } from "react-icons/io5"
import { FaRegCommentDots } from "react-icons/fa"
import { MdOutlineLibraryAdd } from "react-icons/md"
import { IoIosClose } from "react-icons/io"
import { TbListSearch } from "react-icons/tb"
import { FiSearch } from 'react-icons/fi'
import { LuTimerReset } from 'react-icons/lu'
import BLOG from '../../assets/PROFILE/BLOG.jpg'
import moment from 'moment/moment'
import BlogCommentaire from '../blog/BlogCommentaire'
import StateBlog from '../blog/StateBlog'

export default function ProfileBlog({ auth, user }) {
    const navigate = useNavigate()
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [showContenuDetails, setShowContenuDetails] = useState({})
    const [showCommentaires, setShowCommentaires] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState("")
    const [blog, setBlog] = useState([])

    const formatDates = (blogs) => {
        const formattedDates = blogs.map(bl => moment(bl.created_at).from(bl.datetime))
        setFormattedDates(formattedDates)
    }

    const [blogs, setBlogs] = useState([])
    const [showSearch, setShowSearch] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [showMyBlog, setShowMyBlog] = useState(false)

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", blogs.length)
        }
        if (auth.id === user.id) {
            params.set("me", showMyBlog)
        } else {
            params.set("user_id", user.id)
        }

        if (searchValue) {
            params.set("detail", searchValue)
        }

        try {
            const res = await useApi.get('/blog' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setBlogs(res.data.blogs)
                    } else {
                        const list = blogs.slice().concat(res.data.blogs)
                        setBlogs(list)
                    }
                    setallDataLoaded(res.data.blogs.length < 30)
                    formatDates(res.data.blogs)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 1000)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue, showMyBlog])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('blog-channel')
            .listen('.created', (event) => {
                setBlogs(prevBlogs => {
                    if (!prevBlogs.some(blog => blog.id === event.blog.id)) {
                        return [event.blog, ...prevBlogs]
                    }
                    return prevBlogs
                });

            })
            .listen('.updated', (event) => {
                setBlogs(prevBlogs => prevBlogs.map(blog => {
                    if (blog.id === event.blog.id) {
                        return event.blog
                    } else {
                        return blog
                    }
                }))
            })
    }, [blogs])

    const toggleDetails = (id) => {
        setShowContenuDetails(prevState => {
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false
                return acc
            }, {})
            newState[id] = !prevState[id]
            return newState
        })
    }

    return (
        loading ? <PageLoader />
            :
            <>
                {auth.id === user.id &&
                    <div className="blogprofile-post">
                        <div className="post-header">
                            <span onClick={() => navigate('/app/blog/add')}><MdOutlineLibraryAdd size={22} />Ajouter un nouveau post</span>
                            <TbListSearch size={22} onClick={() => { setShowSearch(!showSearch); setSearchValue("") }} />
                        </div>
                        {showSearch &&
                            <div className="input-blog-filter">
                                <span><FiSearch size={22} /></span>
                                <input
                                    placeholder="Saisir votre recherche ici"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                {searchValue &&
                                    <IoIosClose size={22} onClick={() => setSearchValue("")} />
                                }
                            </div>
                        }
                    </div>
                }

                {blogs.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                    :
                    <>
                        <InfiniteScroll
                            dataLength={blogs.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <div className="blogprofile-list-container">
                                {
                                    blogs.map((bl, index) => (
                                        <div className="blogprofile-card" key={bl.id} onClick={() => setCurrentId(bl.id)}>
                                            <div className="card-blogprofile-details">
                                                <div className="blogprofil-header">
                                                    <h3 onClick={() => navigate('/app/blog/show/' + bl.id)}>{bl.titre}</h3>
                                                    <BiMessageSquareEdit size={18} onClick={() => navigate('/app/blog/edit/' + bl.id)} />
                                                </div>
                                                <p className='view-card-timing'><LuTimerReset size={15} />{formattedDates[index]}</p>
                                                <div className="blogprofile-detail">
                                                    <p className={showContenuDetails[bl.id] ? "blog-text" : "blog-all-text"}>
                                                        {bl.contenu}
                                                    </p>
                                                    {showContenuDetails[bl.id] ?
                                                        <span onClick={() => toggleDetails(bl.id)} className='pointer'>Afficher moins</span>
                                                        :
                                                        <span onClick={() => toggleDetails(bl.id)} className='pointer'>Afficher plus</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="img-container">
                                                <img src={bl.photo ? useFileUrl + bl.photo : BLOG} alt="img" />
                                            </div>
                                            <div className="blogprofile-action">
                                                <div className="action-btn" onClick={() => setShowCommentaires(!showCommentaires)}>
                                                    <FaRegCommentDots size={18} />
                                                    <span>Commentaires</span>
                                                </div>
                                                {(['BROUILLON'].includes(bl.status) && bl.user_id === auth.id) &&
                                                    <div className="action-btn" onClick={() => { setBlog(bl); setActionType('do_draft'); setShowConfirmModal(true) }}>
                                                        <FaRegShareFromSquare size={18} />
                                                        <span>Publier</span>
                                                    </div>
                                                }
                                                {(['PUBLIE'].includes(bl.status) && bl.user_id === auth.id) &&
                                                    <div className="action-btn" onClick={() => { setBlog(bl); setActionType('do_publish'); setShowConfirmModal(true) }}>
                                                        <IoArchiveOutline size={18} />
                                                        <span>Brouillon</span>
                                                    </div>
                                                }
                                                {bl.user_id === auth.id &&
                                                    <div className="action-btn" onClick={() => { setBlog(bl); setActionType('do_delete'); setShowConfirmModal(true) }}>
                                                        <RiDeleteBin5Line size={18} />
                                                        <span>Supprimer</span>
                                                    </div>
                                                }

                                            </div>
                                            {(showCommentaires && currentId === bl.id) &&
                                                <BlogCommentaire auth={auth} blogId={bl.id} />
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {showConfirmModal &&
                                <StateBlog
                                    actionType={actionType}
                                    blog={blog}
                                    updateData={() => updateData(true)}
                                    closeModal={() => setShowConfirmModal(false)} />
                            }
                        </InfiniteScroll>
                    </>
                }
            </>
    )
}
