import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputText from '../input/InputText'
import Btn from '../button/Btn'
import PageLoader from '../loader/PageLoader'

export default function InputGare({ label, value, onChange, required, hideInput, closeModal, disabled, useLink }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const location = useLocation()
    const locationSearch = useLocation().search
    const [showModal, setShowModal] = useState(false)
    const [gares, setGares] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const handleSelectGare = (ga) => {
        if (useLink) {
            let params = new URLSearchParams(location.search)
            params.set("gare_routiere_id", ga.id)
            navigate(location.pathname + "?" + params)
        }
        setShowModal(false)
        onChange(ga)
        if (closeModal) closeModal()
    }

    const handleCloseModal = () => {
        setShowModal(false)
        if (closeModal) closeModal()
    }

    const updateData = (initial) => {
        let isMounted = true;
        const params = new URLSearchParams(locationSearch)
        params.set("search", searchValue)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        }
        else
            params.set("offset", gares.length)
        useApi.get('/gare_routiere'
            + '?' + params, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    if (res.data.error)
                        console.error(res.data.error)
                    else {
                        if (initial)
                            setGares(res.data.gare_routieres)
                        else {
                            const list = gares.slice().concat(res.data.gare_routieres)
                            setGares(list)
                        }
                        setallDataLoaded(res.data.gare_routieres.length < 30)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
            })
        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])


    //LOAD MORE DATA
    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('gare-channel')
            .listen('.created', (event) => {
                setGares(prevGares => {
                    if (!prevGares.some(gare => gare.id === event.gare.id)) {
                        return [event.gare, ...prevGares]
                    }
                    return prevGares
                });
            })
            .listen('.updated', (event) => {
                setGares(prevGares => prevGares.map(gare => {
                    if (gare.id === event.gare.id) {
                        return event.gare
                    } else {
                        return gare
                    }
                }))
            })
    }, [gares])

    return (
        <>
            {!hideInput &&
                <InputText
                    label={label ? label : "Gare Routière"}
                    placeholder='Cliquer pour chercher'
                    value={value ? value.designation : ''}
                    disabled={disabled}
                    className='large'
                    required={required}
                    readOnly
                    onClick={() => setShowModal(true)} />
            }
            {(hideInput || showModal) &&
                <div className='input-modal'>
                    <div>
                        <div>
                            <InputText
                                label={label ? label : "Gare Routière"}
                                placeholder='Saisir votre recherche'
                                value={searchValue}
                                onChange={setSearchValue}
                                className='large'
                                required />
                            <div className="justify-right">
                                <Btn
                                    label="Annuler"
                                    className='btn-outline small'
                                    onClick={handleCloseModal} />
                            </div>
                        </div>
                        {loading ? <PageLoader />
                            :
                            <>
                                {gares.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                                    :
                                    <div className="list-modal-container">
                                        <InfiniteScroll
                                            dataLength={gares.length}
                                            next={fetchMoreData}
                                            hasMore={!allDataLoaded}
                                            loader={<PageLoader />}
                                        >
                                            {
                                                gares.map((ga) => (
                                                    <div className="list-modal" key={ga.id} onClick={() => handleSelectGare(ga)}>
                                                        <h3>{ga.designation}</h3>
                                                        <span>{ga.adresse + " [" + ga.ville + "]"}</span>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            }

        </>
    )
}
