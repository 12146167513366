import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputCheckBox from '../../components/input/InputCheckbox'
import Btn from '../../components/button/Btn'

export default function EditNotification({ auth, user, updateData, closeModal }) {

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [notifEmail, setNotifEmail] = useState(user.notification_email)
    const [notifSms, setNotifSms] = useState(user.notification_sms)
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            notification_email: notifEmail,
            notification_sms: notifSms
        }
        try {
            const response = await useApi.post("/utilisateur/notification",
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                toast.success(response.data.success)
                setError(null)
                setNotifEmail(false)
                setNotifSms(false)
                updateData()
                setLoading(false)
                closeModal()

            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>Option de notification</h3>
                    <InputCheckBox
                        label="Notification par e-mail"
                        name="email"
                        checked={notifEmail}
                        onChange={setNotifEmail}
                    />
                    <InputCheckBox
                        label="Notification par sms"
                        name="sms"
                        checked={notifSms}
                        onChange={setNotifSms}
                    />
                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "Valider"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading} />
                    </div>
                </form>
            </div>

        </div>
    )
}
