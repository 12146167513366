import moment from 'moment'
import { CgClose } from 'react-icons/cg'
import { IoMdArrowBack } from 'react-icons/io'
import useScreenSize from '../hooks/useScreenSize'

export default function ShowHeader({ data, closeDetail }) {
    const screenSize = useScreenSize()

    return <div className="show-header-container">
        {
            ["sm", "md"].includes(screenSize) ?
                <div className='vertical-align'>
                    <div style={{ paddingRight: "10px" }} onClick={() => closeDetail()}>
                        <IoMdArrowBack size={26} className='pointer' />
                    </div>
                    <h2>
                        Ref :
                        {data.reference ?
                            " " + moment(data.created_at).format("YYYY") + "/" + ("00000" + data.reference).slice(-6)
                            :
                            " " + ("00000" + data.id).slice(-6)
                        }
                    </h2>
                </div>
                :
                <div className='space-between'>
                    <h2>
                        Ref :
                        {data.reference ?
                            " " + moment(data.created_at).format("YYYY") + "/" + ("00000" + data.reference).slice(-6)
                            :
                            " " + ("00000" + data.id).slice(-6)
                        }
                    </h2>
                    <CgClose className='pointer' size={30} onClick={() => closeDetail()} />
                </div>
        }
    </div>;
}
