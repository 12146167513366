import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import ReactToPrint from "react-to-print"
import useApi from '../../hooks/useApi'
import usePrice from '../../hooks/usePrice'
import useFormatPhone from '../../hooks/useFormatPhone'
import useToken from '../../hooks/useToken'
import Carseat from '../../components/carseat/Carseat'
import PageLoader from '../../components/loader/PageLoader'
import ReservationTicket from './ReservationTicket'

export default function ReservationGuichetItem({ auth, clientId, voyageId }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const formatPhoneNumber = useFormatPhone()
    const [showDetail, setShowDetail] = useState(false)
    const [reservations, setReservations] = useState([])
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [selectedSeats, setSelectedSeats] = useState([])
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [numberReservationUser, setNumberReservationUser] = useState("")
    const [totalAmount, setTotalAmount] = useState(0)
    const [clientPaiement, setClientPaiement] = useState([])
    const [loadingClient, setLoadingClient] = useState(false)
    const [voyage, setVoyage] = useState([])
    const reservationTicketRef = useRef()

    const handleUserSelection = (userId) => {
        setSelectedUserId(userId)
    }

    const getVoyage = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/voyage/show/' + voyageId, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setVoyage(res.data.voyage)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        getVoyage()
    }, [voyageId])

    const updateData = async () => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        params.append('voyage_id', voyageId)
        try {
            const res = await useApi.get('/reservation/tab' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error)
                } else {
                    setReservations(res.data.reservations)

                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        window.Echo.channel('reservation-channel')
            .listen('.created', (event) => {
                setReservations(prevReservations => {
                    if (!prevReservations.some(reservation => reservation.id === event.reservation.id)) {
                        return [event.reservation, ...prevReservations]
                    }
                    return prevReservations
                })

            })
            .listen('.updated', (event) => {
                setReservations(prevReservations => prevReservations.map(reservation => {
                    if (reservation.id === event.reservation.id) {
                        return event.reservation
                    } else {
                        return reservation
                    }
                }))
            })
    }, [reservations])

    useEffect(() => {
        updateData()
    }, [])

    const handleCancel = () => {
        setSelectedSeats([])
    }

    const refreshData = () => {
        setSelectedClient([])
        setShowDetail(false)
        setSelectedSeats([])
        updateData()
    }

    const handlePrint = () => {
        if (reservationTicketRef.current) {
            const printContents = reservationTicketRef.current.innerHTML
            const originalContents = document.body.innerHTML

            document.body.innerHTML = printContents

            window.print()

            document.body.innerHTML = originalContents
        }
    }

    const updateClientPaiement = () => {
        let isMounted = true
        setLoadingClient(true)
        const params = new URLSearchParams(locationSearch)
        useApi.get(`/reservation/client_paiement/${selectedClient.client_id}/${voyageId}`, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setClientPaiement(res.data.client_paiement)
                    setLoadingClient(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoadingClient(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (selectedClient.id && voyageId) {
            updateClientPaiement()
        }
    }, [selectedClient, voyageId])

    return (
        loading ? <div>Chargement...</div>
            :
            <>
                {(showDetail && clientPaiement) &&
                    <ReservationTicket
                        reservationTicketRef={reservationTicketRef}
                        selectedClient={selectedClient}
                        voyage={voyage}
                        numberReservationUser={numberReservationUser}
                        totalAmount={totalAmount}
                        seats={reservations}
                        selectedSeats={selectedSeats}
                        setSelectedSeats={setSelectedSeats}
                        setShowDetail={setShowDetail}
                        reservations={reservations}
                        setSelectedClient={setSelectedClient}
                        handleUserSelection={handleUserSelection}
                        clientPaiement={clientPaiement}
                    />
                }

                <div className="count-reservation">
                    <Carseat
                        auth={auth}
                        selectedSeats={selectedSeats}
                        setSelectedSeats={setSelectedSeats}
                        seats={reservations}
                        setShowDetail={setShowDetail}
                        reservations={reservations}
                        setSelectedClient={setSelectedClient}
                        handleUserSelection={handleUserSelection}
                        clientId={clientId} />
                    <div className='grid-legend'>
                        {loadingClient ? <PageLoader />
                            :
                            (showDetail && clientPaiement) &&
                            <div>
                                {(clientPaiement.client_nom && clientPaiement.client_prenom) &&
                                    <h3>{clientPaiement.client_nom + " " + clientPaiement.client_prenom}</h3>
                                }
                                {clientPaiement.client_telephone &&
                                    <p>Contact : <span className='text'>{formatPhoneNumber(clientPaiement.client_telephone) + (clientPaiement.client_email ? " / " + clientPaiement.client_email : "")}</span></p>
                                }
                                {clientPaiement.client_adresse &&
                                    <p>Adresse : <span className='text'>{clientPaiement.client_adresse}</span></p>
                                }
                                {clientPaiement.cout_total &&
                                    <p>Total : <span className='text'>{formatPrice(clientPaiement.cout_total) + " / " + clientPaiement.nombre_de_places + (clientPaiement.nombre_de_places > 1 ? " places" : " place")}</span></p>
                                }
                                {clientPaiement.total_payer &&
                                    <p>Montant payé : <span className='text'>{formatPrice(clientPaiement.total_payer)}</span></p>
                                }
                                {clientPaiement.reste_a_payer > 0 &&
                                    <p>Reste à payer : <span className='text'>{formatPrice(clientPaiement.reste_a_payer)}</span></p>
                                }
                                {clientPaiement.total_payer > clientPaiement.cout_total &&
                                    <p>A remboursé : <span className='text'>{formatPrice(clientPaiement.total_payer - clientPaiement.cout_total)}</span></p>
                                }
                                {clientPaiement.total_payer > 0 &&
                                    <ReactToPrint
                                        bodyClass="print-agreement"
                                        content={() => reservationTicketRef.current}
                                        pageStyle={`@page { size: A8 } @media print { body { margin: 0 } }`}
                                        trigger={() => (
                                            <span className="reservation-btn-cancel">Imprimer</span>
                                        )}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </>
    )
}
