import { useState } from 'react'
import { Link } from 'react-router-dom'
import StateVehicule from './StateVehicule'

export default function ActionVehicule({auth, vehicule, updateData}) {
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState("")
    
    return (
        <>
            {showConfirmModal && 
                <StateVehicule
                    actionType={actionType}
                    vehicule={vehicule} 
                    updateData={() => updateData()} 
                    closeModal={() => setShowConfirmModal(false)}/> 
            }
            <div className='action-container'>
                {
                    (["superadmin","admin","admin_site"].includes(auth.role)) && 
                    <span>
                        <Link to={"/app/vehicule/edit/" + vehicule.id}>Editer</Link>
                    </span>
                }
                {
                    (["superadmin","admin","admin_site"].includes(auth.role) && ["EN REPARATION","HORS SERVICE"].includes(vehicule.status)) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_activated")}}>
                        Activé
                    </span>
                }
                {
                    (["superadmin","admin","admin_site"].includes(auth.role) && ["DISPONIBLE"].includes(vehicule.status)) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_repair")}}>
                        Mettre en réparation
                    </span>
                }
                {
                    (["superadmin","admin","admin_site"].includes(auth.role) && ["EN REPARATION","DISPONIBLE"].includes(vehicule.status)) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_out_of_service")}}>
                        Mettre hors service
                    </span>
                }
            </div>
        </>
    )
}