import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import useFileUrl from '../../hooks/useFileUrl'
import usePrice from '../../hooks/usePrice'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"
import ActionGuichet from './ActionGuichet'
import ShowTab from '../../view/ShowTab'
import moment from 'moment'

export default function ShowGuichet({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const formatPrice = usePrice()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [guichet, setGuichet] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/paiement/guichet/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setGuichet(res.data.guichet)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(guichet)
    }, [guichet]);

    useEffect(updateData, [currentId])

    return (
        loading ? <PageLoader />
            :
            <>
                {guichet &&
                    <div className='view-show-container'>
                        <ShowHeader data={guichet} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={guichet.client_profil ? useFileUrl + guichet.client_profil : (guichet.client_sexe === "homme" ? Homme : Femme)} alt="img" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{guichet.cooperative}</h3>
                                    <p>
                                        <span>
                                            {
                                                guichet.total_payer === null ?
                                                    <span className="badge-outline badge-purple">
                                                        PAIEMENT EN ATTENTE
                                                    </span>
                                                    :
                                                    <span className={`badge-outline ${guichet.reste_a_payer > 0 ? 'badge-orange' : 'badge-green'}`}>
                                                        {guichet.reste_a_payer > 0 ? "AVANCE" : "PAYE"}
                                                    </span>
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <p>Client : {guichet.client_nom + " " + guichet.client_prenom}</p>
                            <p>Contact client : {formatPhoneNumber(guichet.client_telephone)}</p>
                            <p>{guichet.trajet_designation}</p>
                            <p>Date du voyage : {moment(guichet.date_depart).format('D MMMM YYYY [à] HH[h] mm')}</p>
                            <p>{`Place${guichet.nombre_de_places > 1 ? 's' : ''}`} : {guichet.places + " (" + guichet.nombre_de_places + ")"}</p>
                            {guichet.cout_total &&
                                <p>Coût total : {formatPrice(guichet.cout_total)}</p>
                            }
                            {guichet.total_payer &&
                                <p>Montant payé : {formatPrice(guichet.total_payer)}</p>
                            }
                            {guichet.reste_a_payer > 0 &&
                                <p>Reste à payer : {formatPrice(guichet.reste_a_payer)}</p>
                            }
                            {guichet.total_payer > guichet.cout_total &&
                                <p>A remboursé : {formatPrice(guichet.total_payer - guichet.cout_total)}</p>
                            }
                            <ActionGuichet auth={auth} guichet={guichet} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="guichet_id" clientId={guichet.client_id} voyageId={guichet.voyage_id} voyageStatus={guichet.voyage_status} />
                    </div>
                }
            </>

    )
}
