import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import VEHICULE from '../../assets/PROFILE/VEHICULE.png'

export default function Vehicule({ auth, vehicules, setVehicules, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let statusList = [
        { id: 1, designation: 'DISPONIBLE' },
        { id: 2, designation: 'RESERVE' },
        { id: 3, designation: 'EN VOYAGE' },
        { id: 4, designation: 'EN REPARATION' },
        { id: 5, designation: 'HORS SERVICE' }
    ]

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Status', name: 'status', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Coopérative', name: 'cooperative_id', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", vehicules.length)
        }

        try {
            const res = await useApi.get('/vehicule' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setVehicules(res.data.vehicules)
                    } else {
                        const list = vehicules.slice().concat(res.data.vehicules)
                        setVehicules(list)
                    }
                    setallDataLoaded(res.data.vehicules.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('vehicule-channel')
            .listen('.created', (event) => {
                setVehicules(prevVehicules => {
                    if (!prevVehicules.some(vehicule => vehicule.id === event.vehicule.id)) {
                        return [event.vehicule, ...prevVehicules]
                    }
                    return prevVehicules
                });

            })
            .listen('.updated', (event) => {
                setVehicules(prevVehicules => prevVehicules.map(vehicule => {
                    if (vehicule.id === event.vehicule.id) {
                        return event.vehicule
                    } else {
                        return vehicule
                    }
                }))
            })
    }, [vehicules])

    return (
        <>
            <ViewHeader
                label="Vehicules"
                searchItems={searchItems}
                link="/app/vehicule/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)}
                statusList={statusList} />
            {loading ? <PageLoader />
                :
                <>
                    {vehicules.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={vehicules.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Photo</th>
                                        <th className='table-ellipsis'>Matricule</th>
                                        <th><div className='badge-round' /></th>
                                        <th>Marque</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        vehicules.map((ve) => (
                                            <tr className={`${currentId && currentId == ve.id ? 'selected' : ''}`} key={ve.id} onClick={() => setCurrentId(ve.id)}>
                                                <td>
                                                    <div className="img-container">
                                                        <img src={ve.photo ? useFileUrl + ve.photo : VEHICULE} alt="" />
                                                    </div>
                                                </td>
                                                <td className='table-ellipsis'>{ve.matricule}</td>
                                                <td><div className={
                                                    ve.status == "HORS SERVICE" ? "badge-round badge-round-red"
                                                        : ve.status == "EN REPARATION" ? "badge-round badge-round-orange"
                                                            : ve.status == "EN VOYAGE" ? "badge-round badge-round-purple"
                                                                : "badge-round badge-round-green"
                                                } /></td>
                                                <td className='table-ellipsis'>{ve.marque}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
