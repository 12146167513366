import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import { FiMoreVertical } from 'react-icons/fi'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'

export default function Tarif({ auth, tarifs, setTarifs, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Coopérative', name: 'cooperative_id', type: 'string' },
        { label: 'Trajet', name: 'trajet_id', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", tarifs.length)
        }

        try {
            const res = await useApi.get('/tarif' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setTarifs(res.data.tarifs)
                    } else {
                        const list = tarifs.slice().concat(res.data.tarifs)
                        setTarifs(list)
                    }
                    setallDataLoaded(res.data.tarifs.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('tarif-channel')
            .listen('.created', (event) => {
                setTarifs(prevTarifs => {
                    if (!prevTarifs.some(tarif => tarif.id === event.tarif.id)) {
                        return [event.tarif, ...prevTarifs]
                    }
                    return prevTarifs
                })

            })
            .listen('.updated', (event) => {
                setTarifs(prevTarifs => prevTarifs.map(tarif => {
                    if (tarif.id === event.tarif.id) {
                        return event.tarif
                    } else {
                        return tarif
                    }
                }))
            })
    }, [tarifs])

    return (
        <>
            <ViewHeader
                label="Tarifs"
                searchItems={searchItems}
                link="/app/tarif/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {tarifs.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={tarifs.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Trajet</th>
                                        <th className='table-ellipsis'>Coopérative</th>
                                        <th>Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tarifs.map((ta) => (
                                            <tr className={`${currentId && currentId == ta.id ? 'selected' : ''}`} key={ta.id} onClick={() => setCurrentId(ta.id)}>
                                                <td className='table-ellipsis'>{ta.trajet}</td>
                                                <td className='table-ellipsis'>{ta.cooperative}</td>
                                                <td>{formatPrice(ta.prix)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
