export default function useFormatPhone() {
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '')
        const formatted = cleaned.replace(/^(\d{3})(\d{2})(\d{3})(\d{2})$/, '$1 $2 $3 $4')
        
        return formatted;
    }

    return formatPhoneNumber
}
