import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionUser from './ActionUser'
import ShowTab from '../../view/ShowTab'
import { RiUserLocationLine } from 'react-icons/ri'
import { MdOutlinePhoneForwarded, MdOutlineManageAccounts } from "react-icons/md"
import { PiUserCirclePlusFill } from "react-icons/pi"
import Femme from "../../assets/PROFILE/femme.jpg"
import Homme from "../../assets/PROFILE/homme.jpg"

export default function ShowUser({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/utilisateur/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setUser(res.data.utilisateur)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(user)
    }, [user])

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('utilisateur-channel')
            .listen('.updated', (event) => {
                setUser(prevUtilisateur => {
                    if (prevUtilisateur && prevUtilisateur.id === event.utilisateur.id) {
                        return { ...prevUtilisateur, ...event.utilisateur }
                    }
                    return prevUtilisateur
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {user &&
                    <div className='view-show-container'>
                        <ShowHeader data={user} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={user.profil ? useFileUrl + user.profil : (user.sexe == "homme" ? Homme : Femme)} alt="" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{user.nom + " " + user.prenom}</h3>
                                    <span>{user.email}</span>
                                    <p>
                                        <span>
                                            {
                                                user.blocked ?
                                                    <span className="badge-outline badge-red">
                                                        désactivé
                                                    </span>
                                                    :
                                                    <span className={user.type == "fictif" ? "badge-outline badge-purple" : "badge-outline badge-green"}>
                                                        {user.type}
                                                    </span>
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {auth.role === "superadmin" &&
                                <p className="important">{user.cooperative}</p>
                            }
                            <p><RiUserLocationLine size={16} className='svg-text' /><span className='text'>{user.adresse}</span></p>
                            <p><MdOutlinePhoneForwarded size={16} className='svg-text' /> <span className='text'>{formatPhoneNumber(user.telephone)}</span></p>
                            <p><MdOutlineManageAccounts size={16} className='svg-text' /> <span className='text uppercase'>{user.role}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{(user.user_id ? user.createur_prenom : user.prenom) + " <" + (user.user_id ? user.createur_email : user.email) + ">"}</span></p>
                            <ActionUser auth={auth} user={user} updateData={updateData} />
                        </div>
                        {["superadmin", "admin", "admin_site", "operateur"].includes(auth.role) &&
                            <ShowTab auth={auth} name="user_id" value={user.id} updateData={updateData} />
                        }
                    </div>
                }
            </>

    )
}
