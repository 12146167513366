import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputTextArea from '../../components/input/InputTextArea'
import Btn from '../../components/button/Btn'

export default function StateVehicule({actionType,vehicule, updateData, closeModal}) {

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [motif, setMotif] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            motif: motif
        }
        try {
            const response = await useApi.post("/vehicule/"
                + actionType
                + "/" + vehicule.id, 
                data, tokenHeaders)
    
            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setMotif("")
                updateData(true)
                setLoading(false)
                toast.success(response.data.success)
                closeModal()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    useEffect(() => {
        setBtnDisabled(!motif)
    }, [motif])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>
                        {
                            actionType == "do_activated" ? "Activation du véhicule"
                            : actionType == "do_repair" ? "Mise en réparation du véhicule"
                            : actionType == "do_out_of_service" ? "Mise en hors service du véhicule"
                            : ""
                        }
                    </h3>
                    <InputTextArea
                        label="Motif" 
                        placeholder="Décrivez la motif"
                        value={motif}
                        onChange={setMotif}
                        className='large'
                        required/>
                    <div className="justify-right">
                        <Btn 
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading}/>
                        <Btn 
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading}/>
                    </div>
                </form>
            </div>
            
        </div>
    )
}
