import { useEffect, useState } from 'react'
import InputTextArea from '../../components/input/InputTextArea'
import Btn from '../../components/button/Btn'

export default function ReservationCancel({ motif, setMotif, loading, multiple, confirm, closeModal }) {
    const [btnDisabled, setBtnDisabled] = useState(true)
    useEffect(() => {
        setBtnDisabled(!motif)
    }, [motif])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={confirm}>
                    <h3>{`Annulation Réservation${multiple ? 's' : ''}`}</h3>
                    <InputTextArea
                        label="Motif"
                        placeholder="Décrivez la motif"
                        value={motif}
                        onChange={setMotif}
                        className='large'
                        required />
                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading} />
                    </div>
                </form>
            </div>

        </div>
    )
}
