import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import { IoMdClose } from "react-icons/io"
import InputText from '../../components/input/InputText'
import moment from 'moment'

export default function PaiementItem({ auth, name, value }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const formatPrice = usePrice()
    const [paiements, setPaiements] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [selectedPaiements, setSelectedPaiements] = useState({})
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [motif, setMotif] = useState("")

    const updateData = async () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        params.set("detail", searchValue)
        params.append(name, value)
        try {
            const res = await useApi.get('/paiement/tab' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setPaiements(res.data.paiements)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])


    useEffect(() => {
        window.Echo.channel('paiement-channel')
            .listen('.created', (event) => {
                setPaiements(prevPaiements => {
                    if (!prevPaiements.some(paiement => paiement.id === event.paiement.id)) {
                        return [event.paiement, ...prevPaiements]
                    }
                    return prevPaiements
                });

            })
            .listen('.updated', (event) => {
                setPaiements(prevPaiements => prevPaiements.map(paiement => {
                    if (paiement.id === event.paiement.id) {
                        return event.paiement
                    } else {
                        return paiement
                    }
                }))
            })
    }, [paiements])

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])

    //SELECTION MULTIPLE
    const togglePaiementSelection = (paiementId, clientId) => {
        setSelectedPaiements(prevSelected => {
            const updatedSelection = { ...prevSelected }

            // Désélectionne les réservations du client précédent
            Object.keys(prevSelected).forEach(key => {
                if (key !== clientId) {
                    updatedSelection[key] = []
                }
            });

            // Sélectionne ou désélectionne la réservation actuelle
            if (updatedSelection[clientId]?.includes(paiementId)) {
                updatedSelection[clientId] = updatedSelection[clientId].filter(id => id !== paiementId)
            } else {
                updatedSelection[clientId] = [...(updatedSelection[clientId] || []), paiementId]
            }

            return updatedSelection
        })
    }

    // Grouper les réservations par client_id
    const groupedPaiements = paiements.reduce((acc, paiement) => {
        const clientId = paiement.client_id
        if (!acc[clientId]) {
            acc[clientId] = [paiement]
        } else {
            acc[clientId].push(paiement)
        }
        return acc
    }, {})

    return (
        loading ? <PageLoader />
            :
            <>
                <InputText
                    value={searchValue}
                    onChange={setSearchValue}
                    className='large'
                    placeholder="Tapez votre recherche" />
                {paiements.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                    :
                    Object.keys(groupedPaiements).map((clientId) => (
                        <div key={clientId} style={{ borderTop: '1px dashed #ddd', marginTop: '10px' }}>
                            <h3>{`${(groupedPaiements[clientId][0].client_sexe == "homme" ? "Mr " : (groupedPaiements[clientId][0].client_sexe == "femme" ? "Mdm " : "")) + groupedPaiements[clientId][0].client_nom} ${groupedPaiements[clientId][0].client_prenom} - ${formatPhoneNumber(groupedPaiements[clientId][0].client_telephone)}`}</h3>
                            <h4>{`Nombre de paiement : ${groupedPaiements[clientId].length}`}</h4>
                            {selectedPaiements[clientId] && selectedPaiements[clientId].length > 0 &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px 12px',
                                        background: '#f9f9f9',
                                        borderRadius: '3px',
                                        cursor: 'pointer'
                                    }} onClick={() => setSelectedPaiements({})}>
                                        {`${selectedPaiements[clientId].length} séléctionné${selectedPaiements[clientId].length > 1 ? 's' : ''} `}
                                        <IoMdClose size={16} style={{ transform: 'translateY(-1px)', marginLeft: '5px' }} />
                                    </div>
                                    <div className="action-container" >
                                        <span>{`Encaisser paiement${selectedPaiements[clientId].length > 1 ? 's' : ''}`}</span>
                                        <span>{`Rembourser paiement${selectedPaiements[clientId].length > 1 ? 's' : ''}`}</span>
                                    </div>
                                </div>
                            }
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Montant</th>
                                        <th>Type de paiement</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedPaiements[clientId].map((pa) => (
                                        <tr key={pa.id}>
                                            <td>{formatPrice(pa.montant)}</td>
                                            <td>{pa.type_paiement}</td>
                                            <td>{moment(pa.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))
                }
            </>
    )
}
