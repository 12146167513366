import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

export default function DoughnutChart({ data, label, hideLegend, labelPosition }) {
    Chart.defaults.font.family = 'Bahnschrift'
    const canvasRef = useRef(null)
    const chartInstance = useRef(null)

    const generateRandomColor = () => {
        const baseColor = [255, 139, 4] // Couleur de base en RGB
        const alpha = 0.5 // Opacité de la couleur de base

        const variation = 60 // Variation maximale pour chaque composante RGB

        const randomColor = baseColor.map((component, index) =>
            Math.min(255, Math.max(0, component + (Math.random() * variation * 2 - variation)))
        )

        return `rgba(${randomColor[0]}, ${randomColor[1]}, ${randomColor[2]}, ${alpha})`
    }

    useEffect(() => {
        if (data && data.length > 0) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const ctx = canvasRef.current.getContext('2d');
            chartInstance.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: data.map((item) => Object.values(item)[0]),
                    datasets: [
                        {
                            label: label ? label : 'Data',
                            data: data.map((item) => Object.values(item)[1]),
                            fill: {
                                target: 'origin',
                                above: 'rgba(255, 99, 132, 0.2)',
                            },
                            backgroundColor: data.map(() => generateRandomColor()),
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: hideLegend ? false : {
                            position: labelPosition ? labelPosition : 'bottom', // Positionne les labels à droite du graphique
                            labels: {
                                boxWidth: 12, // Largeur des carrés de légende en pixels
                                usePointStyle: false,
                                pointStyle: 'square' // Utilise les formes des points comme légende (carrés)
                            },
                        },
                    },
                    animation: {
                        duration: 1000,
                    },
                },
            });
        }
    }, [data, hideLegend, label, labelPosition]);

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%', maxWidth: '100%' }} />
}
