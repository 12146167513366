import {useState,useRef} from 'react'
import {IoMdArrowDropdown,IoMdArrowDropup} from 'react-icons/io'
import useClickOutside from '../../hooks/useClickOutside'

export default function InputSelect({label,selected, setSelected, className, options,required}) {
    const [isActive, setIsActive] = useState(false)
    const selectRef = useRef(null)
    useClickOutside(selectRef, () => setIsActive(false))
    return (
        <div className={'input-container '+className}>
            <label htmlFor={label}>{label} {required && <span className='danger'>*</span>}</label>
            <div className={'input-select'} ref={selectRef}>
                <div className={isActive ? "select-placeholder active-select" : "select-placeholder" }
                    onClick={(e) => setIsActive(!isActive)}>
                    <span style={{color: '#333'}}>{selected ? selected : 'Veuillez choisir'}</span>
                    <span>{isActive ? <IoMdArrowDropup/> : <IoMdArrowDropdown/>}</span>
                </div>
                {isActive && 
                    <div className="select-content">
                        {options.map((option,index) => (
                            <div 
                                key={index}
                                onClick={(e) => {
                                    setSelected(option)
                                    setIsActive(false)
                                }}
                                className="select-option"
                            >
                                {option}
                            </div>
                        ))}
                        
                    </div>
                }
            </div>

        </div>
    )
}
