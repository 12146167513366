import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputTrajet from '../../components/input/InputTrajet'
import InputCooperative from '../../components/input/InputCooperative'
import InputSite from '../../components/input/InputSite'
import InputTarif from '../../components/input/InputTarif'
import InputSelect from '../../components/input/InputSelect'
import InputUser from '../../components/input/InputUser'
import Btn from '../../components/button/Btn'

export default function EditVoyage({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const categorieList = [
        'ECONOMIQUE',
        'PREMIUM',
        'VIP'
    ]

    const nbPlaceList = [
        '42',
        '37',
        '32',
        '27',
        '22',
        '18',
        '13',
        '12'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [trajet, setTrajet] = useState(null)
    const [cooperative, setCooperative] = useState(null)
    const [siteDepart, setSiteDepart] = useState(null)
    const [siteArrive, setSiteArrive] = useState(null)
    const [tarif, setTarif] = useState(null)
    const [nombrePlace, setNombrePlace] = useState("")
    const [dateDepart, setDateDepart] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        const isSuperAdmin = ["superadmin"].includes(auth.role)
        const isAdmin = ["admin"].includes(auth.role)

        const isInvalidBasicData = !tarif || !siteArrive
            || (nombrePlace !== null && typeof nombrePlace === 'string' && nombrePlace.trim() === '')
            || !dateDepart.trim() || loading

        let disabled;
        if (isSuperAdmin) {
            disabled = isInvalidBasicData || !cooperative || !siteDepart
        } else if (isAdmin) {
            disabled = isInvalidBasicData || !siteDepart
        } else {
            disabled = isInvalidBasicData
        }

        setBtnDisabled(disabled)
    }, [cooperative, siteDepart, siteArrive, tarif, nombrePlace, dateDepart, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            tarif_id: tarif ? tarif.id : '',
            cooperative_id: cooperative ? cooperative.id : '',
            site_depart_id: siteDepart ? siteDepart.id : '',
            site_arrive_id: siteArrive ? siteArrive.id : '',
            nombre_place: nombrePlace,
            date_depart: dateDepart
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/voyage/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setTarif(null)
                setCooperative(null)
                setSiteDepart(null)
                setSiteArrive(null)
                setNombrePlace("")
                setDateDepart("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/voyage')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/voyage/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const voyage = res.data.voyage
                    if (voyage) {
                        if (voyage.tarif_prix) setTarif({
                            id: voyage.tarif_id,
                            categorie: voyage.tarif_categorie,
                            prix: voyage.tarif_prix
                        })
                        if (voyage.cooperative) setCooperative({
                            id: voyage.cooperative_id,
                            designation: voyage.cooperative
                        })
                        if (voyage.site_depart_id) setSiteDepart({
                            id: voyage.site_depart_id,
                            designation: voyage.site_depart
                        })
                        if (voyage.site_arrive_id) setSiteArrive({
                            id: voyage.site_arrive_id,
                            designation: voyage.site_arrive
                        })
                        if (voyage.nombre_place) setNombrePlace(voyage.nombre_place)
                        if (voyage.date_depart) setDateDepart(voyage.date_depart)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/voyage')
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid-two">
                        <InputTarif
                            label="Trajet et Tarif"
                            value={tarif}
                            onChange={setTarif}
                        />
                        {['superadmin'].includes(auth.role) &&
                            <InputCooperative
                                label="Coopérative"
                                value={cooperative}
                                onChange={setCooperative}
                            />
                        }
                    </div>
                    <div className="grid-two">
                        {['superadmin', 'admin'].includes(auth.role) &&
                            <InputSite
                                label="Lieu de départ"
                                value={siteDepart}
                                onChange={setSiteDepart}
                                required
                            />
                        }
                        <InputSite
                            label="Lieu d'arrivé"
                            value={siteArrive}
                            onChange={setSiteArrive}
                            required
                        />
                    </div>
                    <div className="grid-two">
                        <InputSelect
                            label='Nombre de place'
                            selected={nombrePlace}
                            setSelected={setNombrePlace}
                            options={nbPlaceList}
                            className='large'
                            required />
                        <InputText
                            label='Date et heure de départ'
                            placeholder="Saisir le départ"
                            type="datetime"
                            value={dateDepart}
                            onChange={setDateDepart}
                            className='large'
                            required />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/voyage/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
