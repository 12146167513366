import { Link } from 'react-router-dom'

export default function ActionGare({ auth, gare }) {

    return (
        <div className='action-container'>
            {
                (["superadmin"].includes(auth.role)) &&
                <span>
                    <Link to={"/app/gare/edit/" + gare.id}>Editer</Link>
                </span>
            }
        </div>
    )
}