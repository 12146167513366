export default function InputCheckBox({disabled, name, label, checked, onChange}) {
    return (
        <div style={{margin: "15px 0"}}>
            <label className="checkbox-container">
                {label}
                <input disabled={disabled} type="checkbox" name={name} checked={checked} onChange={(e) => onChange(e.target.checked)}/>
                <span className="checkmark"></span>
            </label>
        </div>
    )
}
