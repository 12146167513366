import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatNumber from '../../hooks/useFormatNumber'
import useFileUrl from '../../hooks/useFileUrl'
import useClickOutside from '../../hooks/useClickOutside'
import PageLoader from '../../components/loader/PageLoader'
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import moment, { now } from 'moment/moment'
import InputSendWithEmoji from '../../components/input/InputSendWithEmoji'
import { FaCircle } from "react-icons/fa"

export default function BlogCommentaire({ auth, blogId }) {
    const tokenHeaders = useToken()
    const formatNumber = useFormatNumber()
    const locationSearch = useLocation().search
    const [commentaires, setCommentaires] = useState([])
    const [commentaire, setCommentaire] = useState("")
    const [editCommentaire, setEditCommentaire] = useState("")
    const [currentId, setCurrentId] = useState(null)
    const [showEdit, setShowEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [commentLoading, setCommentLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const commentaireEditRef = useRef(null)
    useClickOutside(commentaireEditRef, () => { setShowEdit(false) })

    const outsideRef = useRef(null)
    useClickOutside(outsideRef, () => { setCurrentId(null) })

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", commentaires.length)
        }

        params.set("blog_id", blogId)

        try {
            const res = await useApi.get('/commentaire' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setCommentaires(res.data.commentaires)
                    } else {
                        const list = commentaires.slice().concat(res.data.commentaires)
                        setCommentaires(list)
                    }
                    setallDataLoaded(res.data.commentaires.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        updateData(true)
    }, [locationSearch, blogId])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('commentaire-channel')
            .listen('.created', (event) => {
                setCommentaires(prevCommentaires => {
                    if (!prevCommentaires.some(commentaire => commentaire.id === event.commentaire.id)) {
                        return [event.commentaire, ...prevCommentaires]
                    }
                    return prevCommentaires
                });

            })
            .listen('.updated', (event) => {
                setCommentaires(prevCommentaires => prevCommentaires.map(commentaire => {
                    if (commentaire.id === event.commentaire.id) {
                        return event.commentaire
                    } else {
                        return commentaire
                    }
                }))
            })
            .listen('.deleted', (event) => {
                if (event.commentaire && event.commentaire.id) {
                    setCommentaires(prevCommentaires => prevCommentaires.filter(commentaire => commentaire.id !== event.commentaire.id));
                }
            })
    }, [commentaires])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCommentLoading(true)

        const data = new FormData()
        data.append('contenu', currentId ? editCommentaire : commentaire)
        data.append('blog_id', blogId)
        try {
            const response = await useApi.post(
                currentId ? "commentaire/edit/" + currentId : "/commentaire/add",
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setCommentLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setCurrentId(null)
                setCommentaire("")
                setEditCommentaire("")
                setCommentLoading(false)
                updateData(true)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setCommentLoading(false)
        }
    }

    const handleDelete = async () => {
        setCommentLoading(true)
        try {
            const response = await useApi.post("commentaire/delete/" + currentId, {}, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setCommentLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setCommentLoading(false)
                updateData(true)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setCommentLoading(false)
        }
    }

    return (
        <div className="show-blog-comment-list">
            <h3 style={{ display: "flex", alignItems: "center" }}>Commentaires {commentaires.length > 0 ? <><FaCircle size={5} className='separator' /> {formatNumber(commentaires.length)}</> : ""}</h3>
            <InputSendWithEmoji
                value={commentaire}
                onChange={setCommentaire}
                placeholder="Saisir votre commentaire"
                handleSubmit={handleSubmit}
                loading={commentLoading}
            />
            {loading ? <PageLoader />
                :
                <div ref={outsideRef}>
                    {commentaires.length == 0 ? ""
                        :
                        <InfiniteScroll
                            dataLength={commentaires.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {commentaires.map((com) => (
                                <div className="comment-card" key={com.id}>
                                    <div className="img-container">
                                        <img src={
                                            com.createur_photo ? useFileUrl + com.createur_photo
                                                : (com.createur_sexe == "homme" ? HOMME : FEMME)
                                        } alt="img" />
                                    </div>
                                    <div className="comment-container">
                                        {!(showEdit && currentId === com.id) &&
                                            <div className={currentId === com.id ? "comment-text comment-active" : "comment-text"} onClick={() => { setShowEdit(false); setCurrentId(com.id); setEditCommentaire(com.contenu) }}>
                                                <p>{com.contenu}</p>
                                            </div>
                                        }
                                        {(showEdit && currentId === com.id) &&
                                            <div ref={commentaireEditRef}>
                                                <InputSendWithEmoji
                                                    value={editCommentaire}
                                                    onChange={setEditCommentaire}
                                                    placeholder="Saisir votre commentaire"
                                                    handleSubmit={handleSubmit}
                                                    loading={commentLoading}
                                                />
                                            </div>
                                        }
                                        <div className="action-comment">
                                            <div>{moment(com.created_at).fromNow()}</div>
                                            {(currentId === com.id && com.user_id === auth.id) &&
                                                <div className="btn-group">
                                                    <span onClick={() => setShowEdit(true)}>Modifier</span>
                                                    <span onClick={() => handleDelete()}>Supprimer</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </InfiniteScroll>
                    }
                </div>
            }
        </div>
    )
}
