import { useState, useEffect } from 'react'
import EditNote from './EditNote'
import PageLoader from '../../components/loader/PageLoader'
import RateStar from '../../components/ratestar/RateStar'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { GiFallingStar } from "react-icons/gi"

export default function NoteVoyage({ auth, voyageId, chauffeurSecondId }) {

    const tokenHeaders = useToken()
    const [showEdit, setShowEdit] = useState(false)
    const [notes, setNotes] = useState([])
    const [editAction, setEditAction] = useState(false)
    const [noteVehicule, setNoteVehicule] = useState(0)
    const [noteChauffeur, setNoteChauffeur] = useState(0)
    const [noteChauffeurSecond, setNoteChauffeurSecond] = useState(0)
    const [noteCooperative, setNoteCooperative] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get(`/note/${voyageId}`, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    if (res.data.notes) {
                        setNotes(res.data.notes)
                        if (res.data.notes) setEditAction(res.data.notes.enable_edit)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (voyageId)
            updateData()
    }, [voyageId])

    useEffect(() => {
        window.Echo.channel('note-channel')
            .listen('.created', (event) => {
                updateData()
            })
            .listen('.updated', (event) => {
                updateData()
            })
    }, [notes])

    return (
        loading ? <PageLoader />
            :
            <>
                {!editAction &&
                    <div className="action-note">
                        {(["admin", "passager"].includes(auth.role)) &&
                            <span onClick={() => setShowEdit(!showEdit)}>
                                <GiFallingStar size={22} /> {editAction ? "Modifier la note" : "Noter le voyage"}
                            </span>
                        }
                    </div>
                }
                {showEdit &&
                    <EditNote
                        auth={auth}
                        voyageId={voyageId}
                        chauffeurSecondId={chauffeurSecondId}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        updateData={updateData}
                        editAction={editAction} />
                }
                <div className='note'>
                    <RateStar
                        label="Véhicule"
                        value={notes.note_vehicule ? parseFloat(notes.note_vehicule) : 0}
                        edit={false} />
                    <RateStar
                        label="Chauffeur"
                        value={notes.note_chauffeur ? parseFloat(notes.note_chauffeur) : 0}
                        edit={false} />
                    {chauffeurSecondId &&
                        <RateStar
                            label="Chauffeur Second"
                            value={notes.note_chauffeur_second ? parseFloat(notes.note_chauffeur_second) : 0}
                            edit={false} />
                    }
                    <RateStar
                        label="Coopérative"
                        value={notes.note_cooperative ? parseFloat(notes.note_cooperative) : 0}
                        edit={false} />
                </div>
            </>
    )
}
