import { Outlet, Navigate } from "react-router-dom"

export default function GareRoute({ auth }) {
    return (
        ["superadmin"].includes(auth.role) ?
            <Outlet />
            :
            <Navigate to="/app" />
    )
}
