import { Outlet, Navigate } from "react-router-dom"

export default function UserRoute({ auth }) {
    return (
        ["superadmin", "admin", "admin_site", "operateur", "passager"].includes(auth.role) ?
            <Outlet />
            :
            <Navigate to="/app" />
    )
}
