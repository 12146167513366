export default function InputTextArea({ label, value, onChange, disabled, required, placeholder, className, rows }) {

    return (
        <div className={'input-container ' + className}>
            <label htmlFor={label}>{label} {required && <span className='danger'>*</span>}</label>
            <div>
                <textarea
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                    rows={rows ? rows : "5"}
                >
                </textarea>
            </div>
        </div>
    )
}
