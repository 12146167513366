export default function Btn({ label, onClick, type, disabled, className, style }) {
    const buttonClass = `btn ${className}`
    return (
        <button
            style={style}
            className={buttonClass}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    )
}
