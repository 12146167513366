import { useState } from 'react'
import StateGuichet from './StateGuichet'

export default function ActionGuichet({ auth, guichet, updateData }) {
    const [showPaiment, setShowPaiment] = useState(false)

    return (
        <>
            {showPaiment &&
                <StateGuichet
                    auth={auth}
                    guichet={guichet}
                    updateData={() => updateData()}
                    closeModal={() => setShowPaiment(false)} />
            }
            <div className='action-container'>
                {
                    (
                        ["superadmin"].includes(auth.role) && guichet.reste_a_payer > 0
                        || (["resp_caisse"].includes(auth.role) && auth.id === guichet.resp_caisse_id) && guichet.reste_a_payer > 0
                    ) &&
                    <span onClick={() => { setShowPaiment(true); }}>
                        Encaisser
                    </span>
                }
            </div>
        </>
    )
}