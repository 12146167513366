import { useState, useRef, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { FaUsers, FaRoute, FaMapMarkedAlt } from 'react-icons/fa'
import { FaTreeCity } from 'react-icons/fa6'
import { IoStatsChart } from "react-icons/io5"
import { GiHorizonRoad } from "react-icons/gi"
import { HiTicket } from "react-icons/hi2"
import { ImMakeGroup } from "react-icons/im"
import { FaShop } from "react-icons/fa6"
import { TbSettingsDown, TbSettingsUp } from "react-icons/tb"
import { BsBusFront } from "react-icons/bs"
import { AiOutlineUngroup } from "react-icons/ai"
import { ImNewspaper } from "react-icons/im"
import { MdOutlineShoppingCartCheckout, MdBook } from "react-icons/md"
import useClickOutside from '../../hooks/useClickOutside'

export default function Sidebar({ auth, setShowSidebar }) {

    const params = useParams()
    const { pathname } = useLocation()
    const outsideRef = useRef(null)
    useClickOutside(outsideRef, () => setShowSidebar(false))
    const [showSetting, setShowSetting] = useState(false)

    useEffect(() => {
        if (
            [
                '/app/ville', '/app/ville/add', '/app/ville/edit/' + params.id,
                '/app/gare', '/app/gare/add', '/app/gare/edit/' + params.id,
                '/app/site', '/app/site/add', '/app/site/edit/' + params.id,
                '/app/trajet', '/app/trajet/add', '/app/trajet/edit/' + params.id,
                '/app/tarif', '/app/tarif/add', '/app/tarif/edit/' + params.id,
                '/app/vehicule', '/app/vehicule/add', '/app/vehicule/edit/' + params.id
            ].includes(pathname)) {
            setShowSetting(true)
        }
    }, [pathname])

    return (
        <>
            <div className='sidebar' ref={outsideRef}>
                <div className="sidebar-menu">
                    {(!auth.must_complete_profil && !auth.must_change_password) &&
                        <>
                            <Link to='/app' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app'].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                <IoStatsChart size={22} /> Tableau de bord
                            </Link>
                            {["passager"].includes(auth.role) &&
                                <Link to='/app/auth/voyage' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/auth/voyage'].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <GiHorizonRoad size={22} /> Voyages
                                </Link>
                            }
                            {["passager"].includes(auth.role) &&
                                <Link to='/app/auth/reservation' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/auth/reservation'].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <MdBook size={22} /> Reservations
                                </Link>
                            }
                            <Link to='/app/blog' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/blog', '/app/blog/add', '/app/blog/edit/' + params.id, '/app/blog/show/' + params.id].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                <ImNewspaper size={22} /> Blogs
                            </Link>

                            {["superadmin", "admin", "admin_site", "operateur", "passager"].includes(auth.role) &&
                                <Link to='/app/user' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/user', '/app/user/add', '/app/user/edit/' + params.id].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <FaUsers size={22} /> {["admin", "admin_site", "operateur", "chauffeur", "passager"].includes(auth.role) ? (["passager"].includes(auth.role) ? "Communautés" : "Employés") : "Utilisateurs"}
                                </Link>
                            }
                            {["superadmin"].includes(auth.role) &&
                                <Link to='/app/cooperative' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/cooperative', '/app/cooperative/add', '/app/cooperative/edit/' + params.id].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <ImMakeGroup size={22} /> Coopératives
                                </Link>
                            }
                            {["superadmin", "admin"].includes(auth.role) &&
                                <Link to='/app/map' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/map', '/app/map/add/' + params.object + "/" + params.id, '/app/map/edit/' + params.object + "/" + params.id].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <FaMapMarkedAlt size={22} /> Carte
                                </Link>
                            }
                            {["superadmin", "resp_gare", "resp_caisse", "admin", "admin_site", "operateur"].includes(auth.role) &&
                                <Link to='/app/guichet' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/guichet'].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <MdOutlineShoppingCartCheckout size={22} /> Guichet
                                </Link>
                            }
                            {["superadmin", "admin", "admin_site", "operateur"].includes(auth.role) &&
                                <Link to='/app/voyage' onClick={() => { setShowSidebar(false); setShowSetting(false) }} className={`${['/app/voyage', '/app/voyage/add', '/app/voyage/edit/' + params.id].includes(pathname) ? 'sidebar-menu-active' : ''}`}>
                                    <GiHorizonRoad size={22} /> Voyages
                                </Link>
                            }

                            {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                                <>
                                    <div onClick={() => { setShowSetting(!showSetting) }} className={`setting-item ${showSetting ? 'active-setting' : ''}`}>
                                        {showSetting ? <TbSettingsUp size={22} /> : <TbSettingsDown size={22} />} Paramètres
                                    </div>
                                    {showSetting &&
                                        <div className="setting-container">
                                            {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                                                <Link to='/app/ville' onClick={() => setShowSidebar(false)} className={`${['/app/ville', '/app/ville/add', '/app/ville/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Villes <FaTreeCity size={20} />
                                                </Link>
                                            }
                                            {["superadmin"].includes(auth.role) &&
                                                <Link to='/app/gare' onClick={() => setShowSidebar(false)} className={`${['/app/gare', '/app/gare/add', '/app/gare/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Gares routières <AiOutlineUngroup size={20} />
                                                </Link>
                                            }
                                            {["superadmin", "admin"].includes(auth.role) &&
                                                <Link to='/app/site' onClick={() => setShowSidebar(false)} className={`${['/app/site', '/app/site/add', '/app/site/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Sites <FaShop size={20} />
                                                </Link>
                                            }
                                            {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                                                <Link to='/app/trajet' onClick={() => setShowSidebar(false)} className={`${['/app/trajet', '/app/trajet/add', '/app/trajet/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Trajets <FaRoute size={20} />
                                                </Link>
                                            }
                                            {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                                                <Link to='/app/tarif' onClick={() => setShowSidebar(false)} className={`${['/app/tarif', '/app/tarif/add', '/app/tarif/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Tarif <HiTicket size={20} />
                                                </Link>
                                            }
                                            {["superadmin", "admin", "admin_site"].includes(auth.role) &&
                                                <Link to='/app/vehicule' onClick={() => setShowSidebar(false)} className={`${['/app/vehicule', '/app/vehicule/add', '/app/vehicule/edit/' + params.id].includes(pathname) ? 'sidebar-submenu-active' : ''}`}>
                                                    Vehicules <BsBusFront size={20} />
                                                </Link>
                                            }
                                        </div>
                                    }

                                </>
                            }

                        </>
                    }

                </div>
            </div >
        </>
    )
}
