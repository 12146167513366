import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'
import FEMME from '../../assets/PROFILE/femme.jpg'
import HOMME from '../../assets/PROFILE/homme.jpg'
import { LuTimerReset } from "react-icons/lu"
import moment from 'moment'

export default function Notification({ auth, notifications, setNotifications }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [allDataLoaded, setallDataLoaded] = useState(false)
    const [formattedDates, setFormattedDates] = useState([])

    const formatDates = (notifications) => {
        const formattedDates = notifications.map(not => moment(not.created_at).from(not.datetime))
        setFormattedDates(formattedDates)
    }

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", notifications.length)
        }

        try {
            const res = await useApi.get('/notification' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setNotifications(res.data.notifications)
                    } else {
                        const list = notifications.slice().concat(res.data.notifications)
                        setNotifications(list)
                    }
                    setallDataLoaded(res.data.notifications.length < 30)
                    formatDates(res.data.notifications)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        formatDates(notifications)
        const interval = setInterval(() => {
            formatDates(notifications)
        }, 60000)
        return () => clearInterval(interval)
    }, [notifications])

    useEffect(() => {
        window.Echo.channel('notification-channel')
            .listen('.created', (event) => {
                setNotifications(prevNotifications => {
                    if (!prevNotifications.some(notification => notification.id === event.notification.id)) {
                        return [event.notification, ...prevNotifications]
                    }
                    return prevNotifications
                });

            })
            .listen('.updated', (event) => {
                setNotifications(prevNotifications => prevNotifications.map(notification => {
                    if (notification.id === event.notification.id) {
                        return event.notification
                    } else {
                        return notification
                    }
                }))
            })
    }, [notifications])

    const handleMarkSeen = async (notificationId) => {
        setLoading(true)
        try {
            const response = await useApi.post(`/seen/mark_as_seen/notification_id/${notificationId}`, {}, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setLoading(false)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                updateData()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            setLoading(false)
        }
    }

    const handleNavigateNotification = (notification) => {
        handleMarkSeen(notification.id)
        switch (true) {
            case Boolean(notification.blog_id):
                navigate(`/app/blog?id=${notification.blog_id}`);
                break;
            case Boolean(notification.voyage_id):
                navigate(`/app/voyage?id=${notification.voyage_id}`);
                break;
            case Boolean(notification.trajet_id):
                navigate(`/app/trajet?id=${notification.trajet_id}`);
                break;
            case Boolean(notification.tarif_id):
                navigate(`/app/tarif?id=${notification.tarif_id}`);
                break;
            case Boolean(notification.reservation_id):
                navigate(`/app/reservation?id=${notification.reservation_id}`);
                break;
            case Boolean(notification.paiement_id):
                navigate(`/app/paiement?id=${notification.paiement_id}`);
                break;
            case Boolean(notification.vehicule_id):
                navigate(`/app/vehicule?id=${notification.vehicule_id}`);
                break;
            case Boolean(notification.ville_id):
                navigate(`/app/ville?id=${notification.ville_id}`);
                break;
            case Boolean(notification.site_id):
                navigate(`/app/site?id=${notification.site_id}`);
                break;
            case Boolean(notification.cooperative_id):
                navigate(`/app/cooperative?id=${notification.cooperative_id}`);
                break;
            case Boolean(notification.utilisateur_id):
                navigate(`/app/user?id=${notification.utilisateur_id}`);
                break;
            default:
                console.error('Aucune rédirection trouvé');
        }
    }

    return (
        <>
            <ViewHeader
                label="Notifications"
                searchItems={searchItems}
                authorisation={false} />
            {loading ? <PageLoader />
                :
                <>
                    {notifications.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={notifications.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            {
                                notifications.map((not, index) => (
                                    <div className="view-card-container" key={not.id} onClick={() => handleNavigateNotification(not)}>
                                        <div className="view-card-space-between">
                                            <div className="view-card-with-img">
                                                <div className="img-container">
                                                    <img src={
                                                        not.createur_photo ? useFileUrl + not.createur_photo
                                                            : (not.createur_sexe == "homme" ? HOMME : FEMME)
                                                    } alt="img" />
                                                </div>
                                                <div className="card-with-img-detail">
                                                    <h2>{not.createur_prenom}</h2>
                                                    <p className={!not.seen ? `unread` : ''}>{not.message}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <span className='text'>{" <" + not.createur_email + ">"}</span>
                                            <p className='view-card-timing'><LuTimerReset size={15} />{formattedDates[index]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
