import { useState, useEffect } from 'react'
import HomeSuperAdmin from './HomeSuperAdmin'
import HomeRespGare from './HomeRespGare'
import HomeRespCaisse from './HomeRespCaisse'
import HomeAdmin from './HomeAdmin'
import HomeAdminSite from './HomeAdminSite'
import HomeOperateur from './HomeOperateur'
import HomeChauffeur from './HomeChauffeur'
import HomePassager from './HomePassager'

export default function Home({ auth }) {

    return (
        ['superadmin'].includes(auth.role) ? <HomeSuperAdmin auth={auth} />
            : ['resp_gare'].includes(auth.role) ? <HomeRespGare auth={auth} />
                : ['resp_caisse'].includes(auth.role) ? <HomeRespCaisse auth={auth} />
                    : ['admin'].includes(auth.role) ? <HomeAdmin auth={auth} />
                        : ['admin_site'].includes(auth.role) ? <HomeAdminSite auth={auth} />
                            : ['operateur'].includes(auth.role) ? <HomeOperateur auth={auth} />
                                : ['chauffeur'].includes(auth.role) ? <HomeChauffeur auth={auth} />
                                    : ['passager'].includes(auth.role) ? <HomePassager auth={auth} />
                                        : <span>Not Found</span>
    )
}
