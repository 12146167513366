import React, { useEffect, useRef, useState } from 'react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { Marker, useMap } from '@vis.gl/react-google-maps'
import Position from '../../assets/MARKER/Position.png'

export default function ClusterMarker({ items }) {
    const map = useMap()
    const [markers, setMarkers] = useState({})
    const clusterer = useRef(null)

    useEffect(() => {
        if (!map) return
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map })
        }
    }, [map])

    useEffect(() => {
        clusterer.current.clearMarkers()
        clusterer.current.addMarkers(Object.values(markers))
    }, [markers])

    const setMarkerRef = (marker, key) => {
        if (marker && markers[key]) return
        if (!marker && !markers[key]) return

        setMarkers(prev => {
            if (marker) {
                return { ...prev, [key]: marker }
            } else {
                const newMarkers = { ...prev }
                delete newMarkers[key]
                return newMarkers
            }
        })
    }

    return (
        items.map(point =>
            <Marker
                position={{ lat: point.latitude, lng: point.longitude }}
                icon={{ url: Position, scaledSize: { width: 23, height: 30 } }}
                label={{
                    text: point.designation,
                    color: 'black',
                    fontSize: '0.8vw',
                    fontWeight: 'bold',
                    className: 'marker-label'
                }}

                key={point.id}
                ref={(marker) => setMarkerRef(marker, point.id)} />
        )
    )
}
