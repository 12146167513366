import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import InputFile from '../../components/input/InputFile'
import InputTextArea from '../../components/input/InputTextArea'
import InputCheckbox from '../../components/input/InputCheckbox'
import Btn from '../../components/button/Btn'


export default function EditBlog({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const categorieList = [
        'Guides de voyage',
        'Récits de voyage',
        'Conseils de sécurité',
        'Culture locale',
        'Événements et festivals',
        'Environnement et durabilité',
        'Conseils de voyage économiques',
        'Interviews',
        'Conseils de planification',
        'Actualités et mises à jour'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [files, setFiles] = useState([])
    const [tmpFile, setTmpFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [categorie, setCategorie] = useState("")
    const [titre, setTitre] = useState("")
    const [contenu, setContenu] = useState("")
    const [draft, setDraft] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(
            !titre.trim() || !contenu.trim() || loading
        )
    }, [titre, contenu, categorie, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('is_with_profil', true)
        if (['/app/blog/add'].includes(pathname)) {
            files.forEach((file, index) => {
                data.append(`fichiers[${index}]`, file)
            })
        } else {
            data.append('fichier', files)
        }
        if (['/app/blog/edit/' + params.id].includes(pathname)) {
            data.append('fichier_id', fileId)
            data.append('level', 1)
        }
        data.append('categorie', categorie)
        data.append('titre', titre)
        data.append('contenu', contenu)

        if (!draft) {
            data.append('status', "PUBLIE")
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : ""),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setFiles([])
                setCategorie("")
                setTitre("")
                setContenu("")
                setDraft(false)
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/blog')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/blog/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const blog = res.data.blog
                    if (blog) {
                        if (blog.photo) setTmpFile(blog.photo)
                        if (blog.photo_id) setFileId(blog.photo_id)
                        if (blog.categorie) setCategorie(blog.categorie)
                        if (blog.titre) setTitre(blog.titre)
                        if (blog.contenu) setContenu(blog.contenu)
                    }

                    console.log(blog.photo_id + "toto")
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="view-content">
            <div>
                <div className="vertical-align">
                    <div style={{ paddingRight: "10px" }} onClick={goBack}>
                        <IoMdArrowBack size={26} className='pointer' />
                    </div>
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="grid-one">
                        <InputFile
                            label="Photo(s)"
                            file={files}
                            setFile={setFiles}
                            tmpFile={tmpFile}
                            setTmpFile={setTmpFile}
                            multiple={['/app/blog/add'].includes(pathname) ? true : false}
                            id="Photos"
                            required
                        />
                    </div>
                    <div className="grid-one">
                        <InputSelect
                            label='Catégorie'
                            selected={categorie}
                            setSelected={setCategorie}
                            options={categorieList}
                            className='large'
                            required />

                    </div>
                    <div className="grid-one">
                        <InputText
                            label='Titre'
                            placeholder="Saisir le titre"
                            value={titre}
                            onChange={setTitre}
                            className='large'
                            required />
                    </div>
                    <div className="grid-one">
                        <InputTextArea
                            label="Contenu"
                            placeholder="Saisir votre texte ici"
                            value={contenu}
                            onChange={setContenu}
                            className='large'
                            rows="7"
                            required />
                    </div>
                    <div className="grid-one">
                        <InputCheckbox
                            label="Enregistrer comme brouillon"
                            name="publication"
                            checked={draft}
                            onChange={setDraft}
                        />
                    </div>
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/blog/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : (draft ? "Enregistrer" : "Publier"))}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
