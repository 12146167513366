import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import useFileUrl from '../../hooks/useFileUrl'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionVoyage from './ActionVoyage'
import ShowTab from '../../view/ShowTab'
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { MdAirlineSeatReclineExtra, MdOutlineUpdate } from "react-icons/md"
import { FaRegCalendar, FaRegCalendarCheck } from "react-icons/fa6"
import { PiUserCirclePlusFill } from "react-icons/pi"
import VEHICULE from '../../assets/PROFILE/VEHICULE.png'

export default function ShowVoyage({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [voyage, setVoyage] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/voyage/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setVoyage(res.data.voyage)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(voyage)
    }, [voyage])

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('voyage-channel')
            .listen('.updated', (event) => {
                setVoyage(prevVoyage => {
                    if (prevVoyage && prevVoyage.id === event.voyage.id) {
                        return { ...prevVoyage, ...event.voyage }
                    }
                    return prevVoyage
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {voyage &&
                    <div className='view-show-container'>
                        <ShowHeader data={voyage} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <div className="show-container-with-img">
                                <div className="show-img-container">
                                    <img src={voyage.vehicule_photo ? useFileUrl + voyage.vehicule_photo : VEHICULE} alt="" />
                                </div>
                                <div className='show-img-details'>
                                    <h3>{voyage.designation}</h3>
                                    {voyage.vehicule_id &&
                                        <span>{voyage.vehicule_matricule + " [" + voyage.vehicule_marque + "]"}</span>
                                    }
                                    <p>
                                        <span>
                                            <span className={
                                                voyage.status == "EN ATTENTE" ? "badge-outline badge-purple"
                                                    : voyage.status == "VALIDE" ? "badge-outline badge-blue"
                                                        : voyage.status == "ANNULE" ? "badge-outline badge-red"
                                                            : voyage.status == "REPORTE" ? "badge-outline badge-pink"
                                                                : voyage.status == "EN ROUTE" ? "badge-outline badge-yellow"
                                                                    : voyage.status == "EN RETARD" ? "badge-outline badge-orange"
                                                                        : "badge-outline badge-green"
                                            }>
                                                {voyage.status}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {auth.role === "superadmin" &&
                                <p className="important">{voyage.cooperative}</p>
                            }
                            <p>
                                <FaRegMoneyBillAlt size={16} className='svg-text' />
                                <span className='text'>{formatPrice(voyage.tarif_prix)}</span>
                                {voyage.vehicule_categorie &&
                                    <span className='text'>, {voyage.vehicule_categorie}</span>
                                }
                                {voyage.guichet_unique === 1 &&
                                    <span className='text'>, Guichet unique</span>
                                }
                            </p>
                            <p>
                                <FaRegCalendar size={16} className='svg-text' />
                                <span className='text'>{moment(voyage.date_depart).format('D MMMM YYYY [à] HH[h] mm')}</span>
                                <span className='text'>, {voyage.site_depart}</span>
                            </p>
                            {voyage.date_arrive &&
                                <p><FaRegCalendarCheck size={16} className='svg-text' /> <span className='text'>{moment(voyage.date_arrive).format('D MMMM YYYY [à] HH[h] mm')}</span></p>
                            }
                            <p><MdAirlineSeatReclineExtra size={16} className='svg-text' /> <span className='text'>{voyage.nombre_place + " place" + (voyage.nombre_place > 2 ? "s" : "")}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{voyage.createur_prenom + " <" + voyage.createur_email + ">"}</span></p>
                            <ActionVoyage auth={auth} voyage={voyage} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="voyage_id" value={voyage.id} updateData={updateData} voyage={voyage} voyageStatus={voyage.status} />
                    </div>
                }
            </>

    )
}
