import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import { FiMoreVertical } from 'react-icons/fi'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'

export default function Trajet({ auth, trajets, setTrajets, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Ville de départ', name: 'ville_depart_id', type: 'string' },
        { label: "Ville d'arrivé", name: 'ville_arrive_id', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", trajets.length)
        }

        try {
            const res = await useApi.get('/trajet' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setTrajets(res.data.trajets)
                    } else {
                        const list = trajets.slice().concat(res.data.trajets)
                        setTrajets(list)
                    }
                    setallDataLoaded(res.data.trajets.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('trajet-channel')
            .listen('.created', (event) => {
                setTrajets(prevTrajets => {
                    if (!prevTrajets.some(trajet => trajet.id === event.trajet.id)) {
                        return [event.trajet, ...prevTrajets]
                    }
                    return prevTrajets
                })

            })
            .listen('.updated', (event) => {
                setTrajets(prevTrajets => prevTrajets.map(trajet => {
                    if (trajet.id === event.trajet.id) {
                        return event.trajet
                    } else {
                        return trajet
                    }
                }))
            })
    }, [trajets])

    return (
        <>
            <ViewHeader
                label="Trajets"
                searchItems={searchItems}
                link="/app/trajet/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {trajets.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={trajets.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Désignation</th>
                                        <th className='table-ellipsis'>Durée</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        trajets.map((tr) => (
                                            <tr className={`${currentId && currentId == tr.id ? 'selected' : ''}`} key={tr.id} onClick={() => setCurrentId(tr.id)}>
                                                <td className='table-ellipsis'>{tr.ville_depart + " [" + tr.ville_depart_libelle + "] - " + tr.ville_arrive + " [" + tr.ville_arrive_libelle + "]"}</td>
                                                <td>{moment({ hour: tr.heure_trajet }).format('HH [h]')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
