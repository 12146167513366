import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { FiMoreVertical } from 'react-icons/fi'
import PageLoader from '../../components/loader/PageLoader'
import ViewHeader from '../../view/ViewHeader'

export default function Ville({ auth, villes, setVilles, currentId, setCurrentId }) {
    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    let searchItems = [
        { label: 'Identifiant', name: 'id', type: 'string' },
        { label: 'Detail', name: 'detail', type: 'string' },
        { label: 'Province', name: 'province', type: 'string' },
        { label: 'Créateur', name: 'user_id', type: 'string' },
        { label: 'Date de création', name: 'created_at', type: 'date' },
    ]

    const updateData = async (initial) => {
        let isMounted = true
        const params = new URLSearchParams(locationSearch)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        } else {
            params.set("offset", villes.length)
        }

        try {
            const res = await useApi.get('/ville' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    if (initial) {
                        setVilles(res.data.villes)
                    } else {
                        const list = villes.slice().concat(res.data.villes)
                        setVilles(list)
                    }
                    setallDataLoaded(res.data.villes.length < 30)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => { updateData(true) }, [locationSearch])

    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('ville-channel')
            .listen('.created', (event) => {
                setVilles(prevVilles => {
                    if (!prevVilles.some(ville => ville.id === event.ville.id)) {
                        return [event.ville, ...prevVilles]
                    }
                    return prevVilles
                })
            })
            .listen('.updated', (event) => {
                setVilles(prevVilles => prevVilles.map(ville => {
                    if (ville.id === event.ville.id) {
                        return event.ville
                    } else {
                        return ville
                    }
                }))
            })
    }, [villes])

    return (
        <>
            <ViewHeader
                label="Villes"
                searchItems={searchItems}
                link="/app/ville/add"
                authorisation={["superadmin", "admin", "admin_site"].includes(auth.role)} />
            {loading ? <PageLoader />
                :
                <>
                    {villes.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                        :
                        <InfiniteScroll
                            dataLength={villes.length}
                            next={fetchMoreData}
                            hasMore={!allDataLoaded}
                            loader={<PageLoader />}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='table-ellipsis'>Désignation</th>
                                        <th>Province</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        villes.map((vi) => (
                                            <tr className={`${currentId && currentId == vi.id ? 'selected' : ''}`} key={vi.id} onClick={() => setCurrentId(vi.id)}>
                                                <td className='table-ellipsis'>{vi.nom}</td>
                                                <td>{vi.province}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </>
            }
        </>
    )
}
