import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputFile from '../../components/input/InputFile'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import Btn from '../../components/button/Btn'

export default function VehiculeControleEdit({ vehiculeId, vehiculeControleId, setVehiculeControleId, setShowModal, updateData }) {
    const typeList = [
        'ASSURANCE',
        'VISITE TECHNIQUE',
    ]

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [type, setType] = useState("")
    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")
    const [fichier, setFichier] = useState("")
    const [tmpFile, setTmpFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(!type.trim() || !dateDebut.trim() || !dateFin.trim() || loading)
    }, [type, dateDebut, dateFin, fichier, loading])

    const closeModal = () => {
        setType("")
        setDateDebut("")
        setDateFin("")
        setFichier("")
        setShowModal(false)
        setVehiculeControleId(null)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('fichier', fichier)
        data.append('type', type)
        data.append('date_debut', dateDebut)
        data.append('date_fin', dateFin)
        data.append('vehicule_id', vehiculeId)

        try {
            const response = await useApi.post(
                vehiculeControleId ? "/vehicule_controle/edit/" + vehiculeControleId : "/vehicule_controle/add"
                , data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                closeModal()
                updateData()
                setLoading(false)
                toast.success(response.data.success)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/vehicule_controle/show/' + vehiculeControleId, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const vehicule_controle = res.data.vehicule_controle
                    if (vehicule_controle) {
                        if (vehicule_controle.fichier) setTmpFile(vehicule_controle.fichier)
                        if (vehicule_controle.fichier_id) setFileId(vehicule_controle.fichier_id)
                        if (vehicule_controle.type) setType(vehicule_controle.type)
                        if (vehicule_controle.date_debut) setDateDebut(vehicule_controle.date_debut)
                        if (vehicule_controle.date_fin) setDateFin(vehicule_controle.date_fin)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [vehiculeControleId])

    return (
        <div className='input-modal'>
            <div>
                <h3>{vehiculeControleId ? "Modification contrôle de véhicule" : "Ajout contrôle de véhicule"}</h3>
                <form onSubmit={handleSubmit}>
                    <InputSelect
                        label='Type de contrôle'
                        selected={type}
                        setSelected={setType}
                        options={typeList}
                        className='large'
                        required />
                    <InputText
                        label="Date de début"
                        placeholder='Saisir date début'
                        type="date"
                        value={dateDebut}
                        onChange={setDateDebut}
                        className='large'
                        required />
                    <InputText
                        label="Date de fin"
                        placeholder='Saisir date fin'
                        type="date"
                        value={dateFin}
                        onChange={setDateFin}
                        className='large'
                        required />
                    <InputFile
                        label="Pièce jointe"
                        file={fichier}
                        setFile={setFichier}
                        tmpFile={tmpFile}
                        setTmpFile={setTmpFile}
                        multiple={false}
                        id="fichier"
                        required
                    />
                </form>
                <div className="justify-right">
                    <Btn
                        label={loading ? "Chargement..." : (vehiculeControleId ? "Modifier" : "Ajouter")}
                        className='btn-primary small btn-margin'
                        onClick={(e) => handleSubmit(e)}
                        disabled={btnDisabled || loading} />
                    <Btn
                        label="Annuler"
                        className='btn-outline small'
                        onClick={() => closeModal()}
                        disabled={loading} />
                </div>
            </div>
        </div>
    )
}
