import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import PageLoader from '../../components/loader/PageLoader'
import { IoMdClose } from "react-icons/io"
import InputText from '../../components/input/InputText'
import moment from 'moment'
import VehiculeControleEdit from './VehiculeControleEdit'
import { MdNoteAdd, MdOutlineFileDownload, MdOutlineNoteAdd } from "react-icons/md"
import { FiEdit } from "react-icons/fi"
import useFileUrl from '../../hooks/useFileUrl'

export default function VehiculeControle({ auth, name, value, vehicule }) {
    const tokenHeaders = useToken()
    const [vehiculeControles, setVehiculeControles] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const locationSearch = useLocation().search
    const [showModal, setShowModal] = useState(false)
    const [vehiculeControleId, setVehiculeControleId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)


    const updateData = async () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        params.set("type", searchValue)
        params.append(name, value)
        try {
            const res = await useApi.get('/vehicule_controle' + '?' + params, tokenHeaders)
            if (isMounted) {
                if (res.data.error) {
                    console.error(res.data.error);
                } else {
                    setVehiculeControles(res.data.vehicule_controles)
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }

        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])

    const controleDesignation = (vec) => {
        const moisIntervalle = []
        let currentDate = moment(vec.date_debut).startOf('month')
        const endDate = moment(vec.date_fin).startOf('month')

        while (currentDate.isSameOrBefore(endDate)) {
            moisIntervalle.push(currentDate.format('MMMM').toUpperCase())
            currentDate.add(1, 'month')
        }
        //${moisIntervalle.join(', ')}
        const designation = `${vec.type} 
            ${moisIntervalle.length >= 1 && moisIntervalle.length <= 9 ? '0' + moisIntervalle.length : moisIntervalle.length} 
            MOIS [${moment(vec.date_debut).format('D MMMM YY') + " - " + moment(vec.date_fin).format('D MMMM YY')}]`
        return designation
    }

    const verifierStatutDate = (vec) => {
        const currentDate = moment();
        const startDate = moment(vec.date_debut);
        const endDate = moment(vec.date_fin);
        const daysUntilExpiration = endDate.diff(currentDate, 'days');

        if (daysUntilExpiration <= 0) {
            return 'Expiré';
        } else if (daysUntilExpiration <= 5) {
            return `Expiré dans ${daysUntilExpiration + 1} jours`;
        } else if (currentDate.isBetween(startDate, endDate, null, '[]')) {
            return 'En cours';
        } else {
            return 'À venir';
        }
    }

    return (
        loading ? <PageLoader />
            :
            <>
                {showModal &&
                    <VehiculeControleEdit
                        vehiculeId={value}
                        vehiculeControleId={vehiculeControleId}
                        setVehiculeControleId={setVehiculeControleId}
                        setShowModal={setShowModal}
                        updateData={updateData}
                    />
                }
                <div className="space-between">
                    <h3>Contrôles du véhicule</h3>
                    <div className="tab-btn-container">
                        <span className='tabs-btn' onClick={() => setShowModal(true)}>Ajouter <MdOutlineNoteAdd size={18} /></span>
                    </div>
                </div>
                {vehiculeControles.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                    :

                    <div style={{ borderTop: '1px dashed #ddd', marginTop: '10px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className='table-ellipsis'>Désignation</th>
                                    <th style={{ textAlign: 'left' }}>Status</th>
                                    <th style={{ textAlign: 'left' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehiculeControles.map((vec) => (
                                    <tr key={vec.id}>
                                        <td>{controleDesignation(vec)}</td>
                                        <td style={{ textAlign: 'left' }}>{verifierStatutDate(vec)}</td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <span onClick={() => { setShowModal(true); setVehiculeControleId(vec.id) }}><FiEdit size={16} /></span>
                                            <a href={useFileUrl + vec.fichier} target="_blank" style={{ marginLeft: '10px' }}><MdOutlineFileDownload size={20} /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div >
                }
            </>
    )
}
