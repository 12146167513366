import {useState, useEffect} from 'react'

export default function useScreenSize() {
    const [screenSize, setScreenSize] = useState(getScreenSize())

    function getScreenSize() {
        const width = window.innerWidth
        if (width >= 1536) {
            return '2xl';
        } else if (width >= 1280) {
            return 'xl';
        } else if (width >= 900) {
            return 'lg';
        } else if (width >= 600) {
            return 'md';
        } else {
            return 'sm';
        }
    }

    useEffect(() => {
        function handleResize() {
            setScreenSize(getScreenSize())
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return screenSize
}