import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useClickOutside from '../../hooks/useClickOutside'
import useScreenSize from '../../hooks/useScreenSize'
import usePrice from '../../hooks/usePrice'
import useFormatNumber from '../../hooks/useFormatNumber'
import ScreenLoader from '../../components/loader/ScreenLoader'
import moment from 'moment'
import { CgClose } from 'react-icons/cg'
import { FaShop, FaUsersRectangle } from "react-icons/fa6"
import { GiHorizonRoad } from 'react-icons/gi'

export default function HomeRespGare({ auth }) {
    const tokenHeaders = useToken()
    const screenSize = useScreenSize()
    const locationSearch = useLocation().search
    const localizer = momentLocalizer(moment)
    const formatPrice = usePrice()
    const formatNumber = useFormatNumber()
    const [events, setEvents] = useState([])
    const [tooltipStyle, setTooltipStyle] = useState({})
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [nbVoyageAttente, setNbVoyageAttente] = useState(0)
    const [nbSite, setNbSite] = useState(0)
    const [nbEmploye, setNbEmploye] = useState(0)
    const [totalPaiement, setTotalPaiement] = useState(0)
    const [loading, setLoading] = useState(false)
    const outsideDropdownRef = useRef(null)
    useClickOutside(outsideDropdownRef, () => setSelectedEvent(null))

    useEffect(() => {
        setSelectedEvent(null)
    }, [screenSize])

    const messages = {
        date: 'Date',
        time: 'Heure',
        event: 'Événement',
        allDay: 'Toute la journée',
        week: 'Semaine',
        work_week: 'Semaine de travail',
        day: 'Jour',
        month: 'Mois',
        previous: 'Précédent',
        next: 'Suivant',
        yesterday: 'Hier',
        tomorrow: 'Demain',
        today: "Aujourd'hui",
        agenda: 'Agenda',
        noEventsInRange: "Aucun événement dans cette période.",
        showMore: total => `+ Voir plus (${total})`
    }

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        useApi.get('/home/resp_gare' + '?' + params, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const voyages = res.data.voyages.map(voyage => ({
                        title: voyage.designation,
                        start: new Date(voyage.date_depart),
                        end: new Date(voyage.date_depart),
                        ...voyage
                    }))
                    setEvents(voyages)
                    if (res.data.nb_voyage_enattente) setNbVoyageAttente(res.data.nb_voyage_enattente)
                    if (res.data.nb_sites) setNbSite(res.data.nb_sites)
                    if (res.data.nb_employe) setNbEmploye(res.data.nb_employe)
                    if (res.data.total_paiements) setTotalPaiement(res.data.total_paiements)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        updateData()
    }, [])

    const eventPropGetter = (event, start, end, isSelected) => {
        return {
            className: 'custom-event',
            style: {
                backgroundColor: (
                    event.status === "EN ATTENTE" ? '#9c27b0'
                        : event.status === "VALIDE" ? '#0400ff'
                            : event.status === "ANNULE" ? '#ff4646'
                                : event.status === "REPORTE" ? '#ff0066'
                                    : event.status === "EN ROUTE" ? '#fff700'
                                        : event.status === "EN RETARD" ? '#ff5e1f'
                                            : '#09ff00'
                )
                ,
                color: '#fff',
                border: '1px solid #ddd'
            }
        }
    }

    const handleSelectEvent = (event, e) => {

        const tooltipX = (["sm"].includes(screenSize) ? e.clientX : e.clientX - 250)
        const tooltipY = (e.clientY - 50)

        setTooltipStyle({
            top: tooltipY,
            left: tooltipX,
            display: 'block',
            border: (event.status === "EN ATTENTE" ? '1px solid #9c27b0'
                : event.status === "VALIDE" ? '1px solid #0400ff'
                    : event.status === "ANNULE" ? '1px solid #ff4646'
                        : event.status === "REPORTE" ? '1px solid #ff0066'
                            : event.status === "EN ROUTE" ? '1px solid #fff700'
                                : event.status === "EN RETARD" ? '1px solid #ff5e1f'
                                    : '1px solid #09ff00')
        })
        setSelectedEvent(event)
    }

    const closeTooltip = () => {
        setTooltipStyle({ display: 'none' })
        setSelectedEvent(null)
    }

    return (
        loading ? <ScreenLoader />
            :
            <div className='home-adminsite'>
                {selectedEvent && (
                    <div className="tooltip" style={tooltipStyle} ref={outsideDropdownRef}>
                        <CgClose className="close-tooltip" onClick={closeTooltip} />
                        <h3>{selectedEvent.cooperative}</h3>
                        <div>{selectedEvent.title}</div>
                        <div>{moment(selectedEvent.start).format('DD MMMM YYYY [à] HH[h] mm')}</div>
                        <div>
                            {(selectedEvent.vehicule_marque ? selectedEvent.vehicule_marque + " " + selectedEvent.vehicule_matricule + ", " : '')
                                + selectedEvent.nombre_place + " places"
                            }
                        </div>
                        <div>Tarif : {formatPrice(parseInt(selectedEvent.tarif))}</div>
                        <div>Status du voyage : {selectedEvent.status}</div>
                    </div>
                )}
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={handleSelectEvent}
                    messages={messages}
                    eventPropGetter={eventPropGetter}
                />
                <div className="home-grid-container">
                    <div className="home-grid-card">
                        <h4>Total Paiements</h4>
                        <div className="grid-detail">
                            <h1 style={{ fontSize: '1.5rem' }}>{formatPrice(parseInt(totalPaiement))}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Voyages en attente</h4>
                        <div className="grid-detail">
                            <GiHorizonRoad size={30} />
                            <h1>{formatNumber(nbVoyageAttente)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Sites Gare</h4>
                        <div className="grid-detail">
                            <FaShop size={30} />
                            <h1>{formatNumber(nbSite)}</h1>
                        </div>
                    </div>
                    <div className="home-grid-card">
                        <h4>Total Employés</h4>
                        <div className="grid-detail">
                            <FaUsersRectangle size={30} />
                            <h1>{formatNumber(nbEmploye)}</h1>
                        </div>
                    </div>
                </div>
            </div>
    )
}
