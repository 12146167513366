import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InputText from '../input/InputText'
import Btn from '../button/Btn'

export default function InputDateModal({label, param, closeModal}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [value, setValue] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleSubmit = () => {
        let params = new URLSearchParams(location.search)
        params.set(param, value)
        navigate(location.pathname + "?" + params)
        closeModal()
    }

    const handleCancel = () => {
        let params = new URLSearchParams(location.search)
        params.delete(param)
        navigate(location.pathname + "?" + params)
        closeModal()
    }

    useEffect(() => {
        setBtnDisabled(!value)
    }, [value])


    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <InputText
                        label={label} 
                        placeholder='Saisir votre recherche'
                        value={value}
                        onChange={setValue}
                        type='date'
                        className='large'
                        required/>
                    <div className="justify-right">
                    <Btn 
                        label="OK"
                        className='btn-primary small btn-margin'
                        type='submit'
                        disabled={btnDisabled}/>
                    <Btn 
                        label="Annuler"
                        className='btn-outline small'
                        onClick={handleCancel}/>
                    </div>
                </form>
            </div>
            
        </div>
    )
}
