import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import Btn from '../../components/button/Btn'

export default function StateGuichet({ auth, guichet, updateData, closeModal }) {

    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [typePaiement, setTypePaiement] = useState("")
    const [telephone, setTelephone] = useState("")
    const [referenceMobile, setReferenceMobile] = useState("")
    const [montantPaye, setMontantPaye] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)
    const validPhone = /^(032|033|034|038)\d{7}$/

    const typePaiementList = [
        'CASH',
        'MOBILE',
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            voyage_id: guichet.voyage_id,
            client_id: guichet.client_id,
            type_paiement: typePaiement,
            telephone: telephone,
            reference_mobile: referenceMobile,
            montant_paye: montantPaye
        }

        try {
            const response = await useApi.post("/paiement/guichet/add",
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setTypePaiement("")
                setTelephone("")
                setReferenceMobile("")
                setMontantPaye("")
                updateData(true)
                setLoading(false)
                toast.success(response.data.success)
                closeModal()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    useEffect(() => {
        setBtnDisabled(
            typePaiement === "MOBILE" ?
                (!typePaiement.trim() || !montantPaye.trim() || !validPhone.test(telephone) || !referenceMobile.trim() || loading)
                :
                (!typePaiement.trim() || !montantPaye.trim() || loading)
        )
    }, [typePaiement, montantPaye, telephone, referenceMobile, loading])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>{auth.id === guichet.client_id ? "Compléter paiement" : "Encaissement paiement"}</h3>
                    {(guichet && guichet.cout_total) &&
                        <p>Coût total : {formatPrice(guichet.cout_total)}</p>
                    }
                    {(guichet && guichet.total_payer) &&
                        <p>Montant payé : {formatPrice(guichet.total_payer)}</p>
                    }
                    {(guichet && guichet.reste_a_payer) &&
                        <p>Reste à payer : {formatPrice(guichet.reste_a_payer)}</p>
                    }
                    <InputSelect
                        label='Type de paiement'
                        selected={typePaiement}
                        setSelected={setTypePaiement}
                        options={typePaiementList}
                        className='large'
                        required />
                    <InputText
                        label='Montant'
                        placeholder='Saisir le montant'
                        value={montantPaye}
                        onChange={setMontantPaye}
                        type="number"
                        className='large'
                        required />
                    {typePaiement === "MOBILE" &&
                        <>
                            <InputText
                                label={auth.id === guichet.client_id ? "Votre numéro mobile money" : "Numéro mobile money du client"}
                                placeholder='Saisir le numéro'
                                value={telephone}
                                onChange={setTelephone}
                                className='large'
                                required />
                            <InputText
                                label='Référence mobile money'
                                placeholder='Saisir la référence du paiement'
                                value={referenceMobile}
                                onChange={setReferenceMobile}
                                className='large'
                                required />
                        </>
                    }

                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading} />
                    </div>
                </form>
            </div>

        </div>
    )
}
