import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionTrajet from './ActionTrajet'
import ShowTab from '../../view/ShowTab'
import { MdTimer } from "react-icons/md"
import { PiUserCirclePlusFill } from "react-icons/pi"
import { IoMdInformationCircleOutline } from "react-icons/io"

export default function ShowTrajet({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [trajet, setTrajet] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/trajet/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setTrajet(res.data.trajet)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(trajet)
    }, [trajet]);

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('trajet-channel')
            .listen('.updated', (event) => {
                setTrajet(prevTrajet => {
                    if (prevTrajet && prevTrajet.id === event.trajet.id) {
                        return { ...prevTrajet, ...event.trajet }
                    }
                    return prevTrajet
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {trajet &&
                    <div className='view-show-container'>
                        <ShowHeader data={trajet} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <h3>{trajet.ville_depart_libelle + " - " + trajet.ville_arrive_libelle}</h3>
                            <p><IoMdInformationCircleOutline size={16} className='svg-text' /> Ville départ : <span className='text'>{trajet.ville_depart + " (" + trajet.ville_depart_libelle + ")"}</span>, Ville arrivé : <span className='text'>{trajet.ville_arrive + " (" + trajet.ville_arrive_libelle + ")"}</span></p>
                            <p><MdTimer size={16} className='svg-text' /> <span className='text'>{moment({ hour: trajet.heure_trajet }).format('HH [h]')}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{trajet.createur_prenom + " <" + trajet.createur_email + ">"}</span></p>
                            <ActionTrajet auth={auth} trajet={trajet} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="trajet_id" value={trajet.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
