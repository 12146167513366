import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputTextArea from '../../components/input/InputTextArea'
import InputText from '../../components/input/InputText'
import InputUser from '../../components/input/InputUser'
import InputVehicule from '../../components/input/InputVehicule'
import Btn from '../../components/button/Btn'

export default function StateVoyage({ actionType, voyage, updateData, closeModal }) {

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [motif, setMotif] = useState("")
    const [chauffeur, setChauffeur] = useState(null)
    const [chauffeurSecond, setChauffeurSecond] = useState(null)
    const [vehicule, setVehicule] = useState(null)
    const [dateDepart, setDateDepart] = useState("")
    const [dateArrive, setDateArrive] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let data = null;
        if (actionType == "do_depart") {
            data = {
                motif: motif,
                date_depart: dateDepart,
            }
        } else if (actionType == "do_arrive") {
            data = {
                motif: motif,
                date_arrive: dateArrive,
            }
        } else if (actionType == "do_valide") {
            data = {
                motif: motif,
                chauffeur_id: chauffeur ? chauffeur.id : '',
                chauffeur_second_id: chauffeurSecond ? chauffeurSecond.id : '',
                vehicule_id: vehicule ? vehicule.id : ''
            }
        } else {
            data = {
                motif: motif
            }
        }

        try {
            const response = await useApi.post("/voyage/"
                + actionType
                + "/" + voyage.id,
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setMotif("")
                setChauffeur(null)
                setChauffeurSecond(null)
                setVehicule(null)
                setDateDepart("")
                setDateArrive("")
                updateData(true)
                setLoading(false)
                toast.success(response.data.success)
                closeModal()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    useEffect(() => {
        setBtnDisabled(
            (actionType === "do_valide") ?
                !motif.trim() || (chauffeur === null || chauffeur.length === 0)
                :
                !motif.trim()
        )
    }, [motif, chauffeur])

    useEffect(() => {
        if (voyage.chauffeur_id) setChauffeur({
            id: voyage.chauffeur_id,
            prenom: voyage.chauffeur_prenom,
            email: voyage.chauffeur_email
        })
        if (voyage.chauffeur_second_id) setChauffeurSecond({
            id: voyage.chauffeur_second_id,
            prenom: voyage.chauffeur_second_prenom,
            email: voyage.chauffeur_second_email
        })
        if (voyage.vehicule_id) setVehicule({
            id: voyage.vehicule_id,
            marque: voyage.vehicule_marque,
            matricule: voyage.vehicule_matricule
        })

    }, [voyage])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>
                        {
                            actionType == "do_attente" ? "Mise en attente du voyage"
                                : actionType == "do_annule" ? "Annulation du voyage"
                                    : actionType == "do_valide" ? "Validation du voyage"
                                        : actionType == "do_reporte" ? "Reporter le voyage"
                                            : actionType == "do_depart" ? "Départ du voyage"
                                                : actionType == "do_arrive" ? "Arrivé du voyage"
                                                    : ""
                        }
                    </h3>
                    <InputTextArea
                        label="Motif"
                        placeholder="Décrivez la motif"
                        value={motif}
                        onChange={setMotif}
                        className='large'
                        required />
                    {actionType == "do_valide" &&
                        <>
                            <InputVehicule
                                label="Véhicule"
                                value={vehicule}
                                onChange={setVehicule}
                                status="DISPONIBLE"
                            />
                            <InputUser
                                label="Chauffeur principale"
                                role="chauffeur"
                                value={chauffeur}
                                onChange={setChauffeur}
                                chauffeurStatus="DISPONIBLE"
                            />
                            <InputUser
                                label="Chauffeur second"
                                role="chauffeur"
                                value={chauffeurSecond}
                                onChange={setChauffeurSecond}
                                chauffeurStatus="DISPONIBLE"
                            />
                        </>
                    }

                    {actionType == "do_depart" &&
                        <InputText
                            label="Date et heure de départ"
                            placeholder='Saisir date et heure'
                            type="datetime"
                            value={dateDepart}
                            onChange={setDateDepart}
                            className='large'
                            required />
                    }
                    {actionType == "do_arrive" &&
                        <InputText
                            label="Date et heure d'arrivé"
                            placeholder='Saisir date et heure'
                            type="datetime"
                            value={dateArrive}
                            onChange={setDateArrive}
                            className='large'
                            required />
                    }

                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading} />
                    </div>
                </form>
            </div>

        </div>
    )
}
