import { Link } from 'react-router-dom'

export default function ActionTrajet({ auth, trajet }) {

    return (
        <div className='action-container'>
            {
                (["superadmin", "admin", "admin_site"].includes(auth.role)) &&
                <span>
                    <Link to={"/app/trajet/edit/" + trajet.id}>Editer</Link>
                </span>
            }
        </div>
    )
}