import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputText from '../input/InputText'
import Btn from '../button/Btn'
import PageLoader from '../loader/PageLoader'

export default function InputVille({ label, villeType, value, onChange, required, hideInput, closeModal, disabled, useLink, className, emptyObject }) {
    const tokenHeaders = useToken()
    const navigate = useNavigate()
    const location = useLocation()
    const locationSearch = useLocation().search
    const [showModal, setShowModal] = useState(false)
    const [villes, setVilles] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [allDataLoaded, setallDataLoaded] = useState(false)

    const handleSelectVille = (vi) => {
        if (useLink) {
            let params = new URLSearchParams(location.search)
            if (villeType === "ville_depart_id")
                params.set("ville_depart_id", vi.id)
            if (villeType === "ville_arrive_id")
                params.set("ville_arrive_id", vi.id)
            if (villeType === "ville_id")
                params.set("ville_id", vi.id)
            navigate(location.pathname + "?" + params)
        }
        setShowModal(false)
        onChange(vi)
        if (closeModal) closeModal()
    }

    const handleCloseModal = () => {
        setShowModal(false)
        if (closeModal) closeModal()
    }

    const updateData = (initial) => {
        let isMounted = true;
        const params = new URLSearchParams(locationSearch)
        params.set("detail", searchValue)
        if (initial) {
            setLoading(true)
            setallDataLoaded(true)
            params.set("offset", 0)
        }
        else
            params.set("offset", villes.length)
        useApi.get('/ville'
            + '?' + params, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    if (res.data.error)
                        console.error(res.data.error)
                    else {
                        if (initial)
                            setVilles(res.data.villes)
                        else {
                            const list = villes.slice().concat(res.data.villes)
                            setVilles(list)
                        }
                        setallDataLoaded(res.data.villes.length < 30)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
            })
        return () => { isMounted = false }
    }

    useEffect(() => {
        let timeoutId = null
        if (searchValue !== '') {
            timeoutId = setTimeout(() => {
                updateData(true)
            }, 500)
        } else {
            updateData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [locationSearch, searchValue])


    //LOAD MORE DATA
    const fetchMoreData = () => {
        setTimeout(() => {
            updateData()
        }, 300)
    }

    useEffect(() => {
        window.Echo.channel('ville-channel')
            .listen('.created', (event) => {
                setVilles(prevVilles => {
                    if (!prevVilles.some(ville => ville.id === event.ville.id)) {
                        return [event.ville, ...prevVilles]
                    }
                    return prevVilles
                })
            })
            .listen('.updated', (event) => {
                setVilles(prevVilles => prevVilles.map(ville => {
                    if (ville.id === event.ville.id) {
                        return event.ville
                    } else {
                        return ville
                    }
                }))
            })
    }, [villes])

    return (
        <>
            {!hideInput &&
                <InputText
                    label={label ? label : "Ville"}
                    placeholder='Cliquer pour chercher'
                    value={value ? value.nom : ''}
                    disabled={disabled}
                    className={className ? className : 'large'}
                    required={required}
                    readOnly
                    onClick={() => setShowModal(true)}
                    emptyObject={emptyObject} />
            }
            {(hideInput || showModal) &&
                <div className='input-modal'>
                    <div>
                        <div>
                            <InputText
                                label={label ? label : "Ville"}
                                placeholder='Saisir votre recherche'
                                value={searchValue}
                                onChange={setSearchValue}
                                className='large'
                                required />
                            <div className="justify-right">
                                <Btn
                                    label="Annuler"
                                    className='btn-outline small'
                                    onClick={handleCloseModal} />
                            </div>
                        </div>
                        {loading ? <PageLoader />
                            :
                            <>
                                {villes.length == 0 ? <h3 className='justify-center'>Aucune données trouvé</h3>
                                    :
                                    <div className="list-modal-container">
                                        <InfiniteScroll
                                            dataLength={villes.length}
                                            next={fetchMoreData}
                                            hasMore={!allDataLoaded}
                                            loader={<PageLoader />}
                                        >
                                            {
                                                villes.map((vi) => (
                                                    <div className="list-modal" key={vi.id} onClick={() => handleSelectVille(vi)}>
                                                        <h3>{vi.nom}</h3>
                                                        <span>{vi.province}</span>
                                                    </div>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            }

        </>
    )
}
