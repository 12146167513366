import { useState } from 'react'
import { Link } from 'react-router-dom'
import StateVoyage from './StateVoyage'

export default function ActionVoyage({auth, voyage, updateData}) {
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState("")
    
    return (
        <>
            {showConfirmModal && 
                <StateVoyage
                    actionType={actionType}
                    voyage={voyage} 
                    updateData={() => updateData()} 
                    closeModal={() => setShowConfirmModal(false)}/> 
            }
            <div className='action-container'>
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["EN ATTENTE","ANNULE"].includes(voyage.status)
                    ) &&
                    <span>
                        <Link to={"/app/voyage/edit/" + voyage.id}>Editer</Link>
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["ANNULE"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_attente")}}>
                        Mettre en attente
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["EN ATTENTE"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_annule")}}>
                        Annuler
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["EN ATTENTE"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_valide")}}>
                        Valider
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["EN ATTENTE","VALIDE"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_reporte")}}>
                        Reporter
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["VALIDE","REPORTE"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_depart")}}>
                        Mettre en route
                    </span>
                }
                {
                    (
                        ["superadmin","admin","admin_site"].includes(auth.role) 
                        && ["EN ROUTE","EN RETARD"].includes(voyage.status)
                    ) &&
                    <span onClick={() => {setShowConfirmModal(true);setActionType("do_arrive")}}>
                        Marquer comme arrivé
                    </span>
                }
            </div>
        </>
    )
}