import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputText from '../../components/input/InputText'
import InputSelect from '../../components/input/InputSelect'
import Btn from '../../components/button/Btn'
import usePrice from '../../hooks/usePrice'

export default function EditReservation({ auth, voyageId, setShowModal, selectedSeats, setSelectedSeats, updateData, clientId, currentVoyage, clientPaiement }) {

    const tokenHeaders = useToken()
    const { pathname } = useLocation()
    const formatPrice = usePrice()
    const categorieList = [
        'ECONOMIQUE',
        'PREMIUM',
        'VIP'
    ]

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [email, setEmail] = useState("")
    const [telephone, setTelephone] = useState("")
    const [telephoneUrgence, setTelephoneUrgence] = useState("")
    const [adresse, setAdresse] = useState("")
    const [cin, setCin] = useState("")
    const [typePaiement, setTypePaiement] = useState("")
    const [montantPaye, setMontantPaye] = useState("")
    const [referenceMobile, setReferenceMobile] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)
    const validPhone = /^(032|033|034|038)\d{7}$/

    const typePaiementList = [
        'CASH',
        'MOBILE',
    ]

    useEffect(() => {
        const isInvalideDataNotPassager = !validPhone.test(telephone) || !nom.trim() || !prenom.trim() || !adresse.trim() || !validPhone.test(telephoneUrgence) || loading
        const isInvalideDataPassager = !typePaiement.trim() || !montantPaye.trim() || !validPhone.test(telephoneUrgence) || loading
        const isNotGuichetUnique = !typePaiement.trim() || !montantPaye.trim()
        const isTypeMobile = !referenceMobile.trim()

        let disabled
        if (clientId) {
            disabled = typePaiement === "MOBILE" ? (isNotGuichetUnique || isTypeMobile) : isNotGuichetUnique
        } else if (auth.role === "passager") {
            disabled = typePaiement === "MOBILE" ? (isInvalideDataPassager || isTypeMobile) : isInvalideDataPassager
        } else {
            disabled = currentVoyage.guichet_unique ?
                isInvalideDataNotPassager
                :
                (typePaiement === "MOBILE" ? (isInvalideDataNotPassager || isNotGuichetUnique || isTypeMobile) : (isInvalideDataNotPassager || isNotGuichetUnique))
        }

        setBtnDisabled(disabled)
    }, [nom, prenom, telephone, telephoneUrgence, typePaiement, montantPaye, referenceMobile, loading])

    const closeModal = () => {
        setNom("")
        setPrenom("")
        setEmail("")
        setTelephone("")
        setTelephoneUrgence("")
        setAdresse("")
        setCin("")
        setTypePaiement("")
        setMontantPaye("")
        setReferenceMobile("")
        setShowModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        let data = {}
        if (clientId) {
            data = {
                type_paiement: typePaiement,
                montant_paye: montantPaye,
                reference_mobile: referenceMobile,
                voyage_id: voyageId,
                client_id: clientId,
                telephone_urgence: telephoneUrgence
            }
        } else {
            data = {
                nom: nom,
                prenom: prenom,
                email: email,
                telephone: telephone,
                telephone_urgence: telephoneUrgence,
                adresse: adresse,
                cin: cin,
                type_paiement: typePaiement,
                montant_paye: montantPaye,
                reference_mobile: referenceMobile,
                selected_seats: selectedSeats,
                voyage_id: voyageId
            }

        }

        try {
            const response = await useApi.post(
                clientId ? "/reservation/complete" : "/reservation/add",
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setSelectedSeats([])
                updateData(true)
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                closeModal()
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/utilisateur/show_by_number/' + telephone, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const user = res.data.utilisateur
                    if (user) {
                        if (user.nom) setNom(user.nom)
                        if (user.prenom) setPrenom(user.prenom)
                        if (user.email) setEmail(user.email)
                        if (user.telephone) setTelephone(user.telephone)
                        if (user.telephone_urgence) setTelephoneUrgence(user.telephone_urgence)
                        if (user.adresse) setAdresse(user.adresse)
                        if (user.cin) setCin(user.cin)
                    } else {
                        setNom("")
                        setPrenom("")
                        setEmail("")
                        setAdresse("")
                        setCin("")
                    }

                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(() => {
        if (!validPhone.test(telephone)) {
            setNom("")
            setPrenom("")
            setEmail("")
            setAdresse("")
            setCin("")
            return
        }
        let timeoutId = null
        if (telephone !== '') {
            timeoutId = setTimeout(() => {
                fetchData(true)
            }, 500)
        } else {
            fetchData(true)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [telephone])

    useEffect(() => {
        if (['/app/auth/voyage'].includes(pathname)) {
            setTelephoneUrgence(auth.telephone_urgence)
        }
    }, [pathname])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>{(clientId ? "Complété paiement" : "Validé") + " réservation" + (selectedSeats.length > 1 ? "s" : "")}</h3>
                    {(currentVoyage && !clientId) &&
                        <>
                            <p>{currentVoyage.cooperative + ", " + (currentVoyage.designation ? currentVoyage.designation : currentVoyage.trajet_designation)}</p>
                            <p>Place : {selectedSeats.length}</p>
                            <p>Total : {formatPrice(selectedSeats.length * currentVoyage.tarif_prix)}</p>
                        </>
                    }
                    {clientId && clientPaiement.cout_total &&
                        <p>Total : <span className='text'>{formatPrice(clientPaiement.cout_total) + " / " + clientPaiement.nombre_de_places + (clientPaiement.nombre_de_places > 1 ? " places" : " place")}</span></p>
                    }
                    {clientId && clientPaiement.total_payer &&
                        <p>Montant payé : <span className='text'>{formatPrice(clientPaiement.total_payer)}</span></p>
                    }
                    {clientId && clientPaiement.reste_a_payer > 0 &&
                        <p>Reste à payer : <span className='text'>{formatPrice(clientPaiement.reste_a_payer)}</span></p>
                    }
                    {!(clientId || ['/app/auth/voyage'].includes(pathname)) &&
                        <div className="grid-two">
                            <InputText
                                label='Téléphone'
                                placeholder='Saisir le téléphone'
                                value={telephone}
                                onChange={setTelephone}
                                className='large'
                                required />
                            <InputText
                                label='Nom'
                                placeholder='Saisir le nom'
                                value={nom}
                                onChange={setNom}
                                className='large'
                                required
                                disabled={!validPhone.test(telephone)} />
                        </div>}
                    {!(clientId || ['/app/auth/voyage'].includes(pathname)) &&
                        <div className="grid-two">
                            <InputText
                                label='Prénom(s)'
                                placeholder='Saisir le prénom(s)'
                                value={prenom}
                                onChange={setPrenom}
                                className='large'
                                required
                                disabled={!validPhone.test(telephone)} />
                            <InputText
                                label='Email'
                                placeholder="Saisir l'adresse email"
                                value={email}
                                type="email"
                                onChange={setEmail}
                                className='large'
                                disabled={!validPhone.test(telephone)} />
                        </div>}
                    <div className="grid-two">
                        <InputText
                            label="Numéro d'urgence"
                            placeholder='Saisir le numéro téléphone'
                            value={telephoneUrgence}
                            onChange={setTelephoneUrgence}
                            className='large'
                            required />
                    </div>
                    {!(clientId || ['/app/auth/voyage'].includes(pathname)) &&
                        <div className="grid-two">
                            <InputText
                                label='Adresse'
                                placeholder="Saisir l'adresse"
                                value={adresse}
                                onChange={setAdresse}
                                className='large'
                                disabled={!validPhone.test(telephone)}
                                required />
                            <InputText
                                label='CIN'
                                placeholder='Saisir le cin'
                                value={cin}
                                onChange={setCin}
                                className='large'
                                disabled={!validPhone.test(telephone)} />
                        </div>}
                    {(!currentVoyage.guichet_unique || ['/app/auth/voyage'].includes(pathname)) &&
                        <>
                            <div className="grid-two">
                                {(validPhone.test(telephone) || clientId || ['/app/auth/voyage'].includes(pathname)) &&
                                    <InputSelect
                                        label='Type de paiement'
                                        selected={typePaiement}
                                        setSelected={setTypePaiement}
                                        options={typePaiementList}
                                        className='large'
                                        required />
                                }
                                <InputText
                                    label='Montant'
                                    placeholder='Saisir le montant'
                                    value={montantPaye}
                                    onChange={setMontantPaye}
                                    type="number"
                                    className='large'
                                    disabled={(clientId || ['/app/auth/voyage'].includes(pathname)) ? false : !validPhone.test(telephone)} />
                            </div>
                            {((validPhone.test(telephone) && typePaiement == "MOBILE") || ((clientId || ['/app/auth/voyage'].includes(pathname)) && typePaiement == "MOBILE")) &&
                                <div className="grid-two">
                                    <InputText
                                        label='Reference du paiement mobile'
                                        placeholder='Saisir le référence'
                                        value={referenceMobile}
                                        onChange={setReferenceMobile}
                                        className='large'
                                        disabled={(clientId || ['/app/auth/voyage'].includes(pathname)) ? false : !validPhone.test(telephone)} />
                                </div>
                            }
                        </>
                    }

                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={closeModal}
                            disabled={loading} />
                    </div>
                </form>
            </div>
        </div >
    )
}
