import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ResetPassword from './ResetPassword'

export default function ActionUser({ auth, user, updateData }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    return (
        <>
            {showConfirmModal &&
                <ResetPassword
                    id={user.id}
                    updateData={() => updateData()}
                    closeModal={() => setShowConfirmModal(false)} />
            }
            <div className='action-container'>
                <span>
                    <Link to={"/app/conversation/add?recipient_id=" + user.id}>Envoyer un message</Link>
                </span>
                <span>
                    <Link to={"/app/profile?user_id=" + user.id}>Voir profil</Link>
                </span>
                {
                    (
                        (["superadmin", "resp_gare", "resp_caisse", "admin", "admin_site", "operateur", "chauffeur", "passager"].includes(user.role) && auth.role === "superadmin")
                        || (["admin_site", "operateur", "chauffeur"].includes(user.role) && auth.role === "admin")
                        || (["operateur", "chauffeur"].includes(user.role) && auth.role === "admin_site")
                    ) &&
                    <span>
                        <Link to={"/app/user/edit/" + user.id}>Editer</Link>
                    </span>
                }
                {
                    (
                        (["superadmin", "resp_gare", "resp_caisse", "admin", "admin_site", "operateur", "chauffeur", "passager"].includes(user.role) && auth.role === "superadmin")
                        || (["admin_site", "operateur", "chauffeur"].includes(user.role) && auth.role === "admin")
                        || (["operateur", "chauffeur"].includes(user.role) && auth.role === "admin_site")
                    ) &&
                    <span onClick={() => setShowConfirmModal(true)}>Réinitialiser</span>
                }
            </div>
        </>
    )
}