import { Link } from 'react-router-dom'

export default function ActionVille({auth, ville}) {
    
    return (
        <div className='action-container'>
            {
                (["superadmin","admin","admin_site"].includes(auth.role)) && 
                <span>
                    <Link to={"/app/ville/edit/" + ville.id}>Editer</Link>
                </span>
            }
        </div>
    )
}