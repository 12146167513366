import { useState } from 'react'
import Login from './Login'
import Register from './Register'
import BG from '../../assets/AUTH/AUTH_BG.svg'
import LETTER from '../../assets/AUTH/AUTH_LETTER.svg'
import LOGO from '../../assets/LOGO/LOGO_LG_BLACKBG.svg'

export default function Authentication() {
    const [activeTab, setActiveTab] = useState("login")
    return (
        <div className='auth'>
            <div className="auth-images" 
            style={{
                backgroundImage: `url(${BG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100vh',
            }}>            
                <img src={LETTER} alt='Logo' />                
            </div>
            <div className="auth-form">
                <img src={LOGO} alt='Logo' />
                <div className="auth-form-container">
                    <div className="auth-tab">
                        <span onClick={() => setActiveTab("login")} className={activeTab == "login" ? "active" : ""}>S'identifier</span>
                        <span onClick={() => setActiveTab("register")} className={activeTab == "register" ? "active" : ""}>Créer un compte</span>
                    </div>
                    {activeTab == "login" ? <Login /> : <Register />}
                </div>
                
            </div>
        </div>
    )
}
