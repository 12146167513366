import { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import { Navigate, Outlet, useLocation } from "react-router-dom"

import ScreenLoader from '../components/loader/ScreenLoader'
import useToken from '../hooks/useToken'

export default function GuestRoute({auth, setAuth}) {
    const {pathname} = useLocation()
    const [loading, setLoading] = useState(true)
    const tokenHeaders = useToken()

    useEffect(() => {
        let timeoutId = null

        const fetchData = () => {
            useApi.get('/auth', tokenHeaders)
                .then((res) => {
                    setAuth(res.data)
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        const token = localStorage.getItem("token")
        if (token) {
            timeoutId = setTimeout(fetchData, 500)
        } else {
            fetchData()
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    if(loading) {
        return <ScreenLoader/>
    }

    return (
        ['/'].includes(pathname) ? (
            !auth ? <Outlet /> : <Navigate to="/app" />
        ) 
        : 
        (
            !auth ? <Navigate to="/" /> : <Outlet />
        )
    )
}