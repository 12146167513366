import { useEffect, useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { IoMdClose } from "react-icons/io"
import { IoMdEye } from "react-icons/io"
import moment from 'moment'
moment.locale("fr")

export default function InputText({ label, labelColor, value, onChange, type, disabled, required, placeholder, accept, min, max, className, onClick, readOnly, emptyObject }) {
    const [currentType, setCurrentType] = useState("")

    useEffect(() => {
        switch (type) {
            case 'password':
                return setCurrentType("password")
            case 'file':
                return setCurrentType("file")
            case 'email':
                return setCurrentType("email")
            case 'date':
                return setCurrentType("date")
            case 'datetime':
                return setCurrentType("datetime-local")
            case 'number':
                return setCurrentType("number")
            default:
                return setCurrentType("text")
        }
    }, [])

    return (
        <div className={'input-container ' + className}>
            <label style={{ color: labelColor ? labelColor : '#333' }} htmlFor={label}>{label} {required && <span className='danger'>*</span>} {(value && emptyObject) && <IoMdClose onClick={emptyObject} style={{ cursor: 'pointer', transform: 'translateY(2px)' }} fill="red" />}</label>
            <div className='field'>
                <input
                    placeholder={placeholder}
                    type={currentType}
                    value={value}
                    onChange={(e) => { currentType == 'file' ? onChange(e.target.files[0]) : (currentType == 'datetime-local' ? onChange(moment(e.target.value).format('YYYY-MM-DD HH:mm:ss')) : onChange(e.target.value)) }}
                    disabled={disabled}
                    accept={accept}
                    min={min}
                    max={max}
                    autoComplete="off"
                    onClick={onClick}
                    readOnly={readOnly}
                />
                {type === "password" &&
                    <div className='input-icon'>
                        {currentType == "password" ? <AiOutlineEyeInvisible size={18} onClick={() => setCurrentType("text")} /> : <AiOutlineEye size={18} onClick={() => setCurrentType("password")} />}
                    </div>
                }
            </div>
        </div>
    )
}
