import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import { FiSearch } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import InputStatus from '../input/InputStatus'
import InputGare from '../input/InputGare'
import InputCooperative from '../input/InputCooperative'
import InputSite from '../input/InputSite'
import InputVille from '../input/InputVille'
import InputTarif from '../input/InputTarif'
import InputTrajet from '../input/InputTrajet'
import InputVehicule from '../input/InputVehicule'
import InputSearchDateModal from '../modal/InputSearchDateModal'
import InputSearchTextModal from '../modal/InputSearchTextModal'
import InputUser from '../input/InputUser'

export default function SearchBar({ searchItems, statusList }) {
    const tokenHeaders = useToken()
    const location = useLocation()
    const formatPrice = usePrice()
    const locationPathname = location.pathname
    const locationSearch = location.search
    const navigate = useNavigate()
    const [showOption, setShowOption] = useState(false)
    const [currentItem, setCurrentItem] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [showTextModal, setShowTextModal] = useState(false)
    const [showReference, toggleReference] = useState(false)
    const [showId, setShowId] = useState(false)
    const [showAccount, setShowAccount] = useState(false)
    const [showDate, setShowDate] = useState(false)
    const [showStatus, setShowStatus] = useState(false)
    const [showGare, setShowGare] = useState(false)
    const [showCooperative, setShowCooperative] = useState(false)
    const [showSite, setShowSite] = useState(false)
    const [showSiteDepart, setShowSiteDepart] = useState(false)
    const [showSiteArrive, setShowSiteArrive] = useState(false)
    const [showVille, setShowVille] = useState(false)
    const [showVilleDepart, setShowVilleDepart] = useState(false)
    const [showVilleArrive, setShowVilleArrive] = useState(false)
    const [showTarif, setShowTarif] = useState(false)
    const [showTrajet, setShowTrajet] = useState(false)
    const [showVehicule, setShowVehicule] = useState(false)
    const [showProvince, setShowProvince] = useState(false)
    const [showUser, setShowUser] = useState(false)
    const [showClient, setShowClient] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [showDateDepart, setShowDateDepart] = useState(false)

    useEffect(() => {
        let isMounted = true
        useApi.get('/recherche' + locationSearch, tokenHeaders)
            .then((res) => {
                const {
                    utilisateur, client, gare_routiere, cooperative, site, site_depart, site_arrive,
                    ville, ville_depart, ville_arrive, tarif, trajet, vehicule
                } = res.data
                if (isMounted) {
                    const items = []
                    searchItems.forEach(item => {
                        const searchParamValue = new URLSearchParams(locationSearch).get(item.name)
                        if (searchParamValue) {
                            if (item.name === 'id') {
                                item.value = (new URLSearchParams(locationSearch)).get("id")
                                item.description = (new URLSearchParams(locationSearch)).get("id")
                                items.push(item)
                            }
                            if (item.name === 'account') {
                                item.value = (new URLSearchParams(locationSearch)).get("account")
                                item.description = (new URLSearchParams(locationSearch)).get("account")
                                items.push(item)
                            }
                            if (item.name === 'gare_routiere_id') {
                                item.value = gare_routiere.id
                                item.description = gare_routiere.designation
                                items.push(item)
                            }
                            if (item.name === 'cooperative_id') {
                                item.value = cooperative.id
                                item.description = cooperative.designation
                                items.push(item)
                            }
                            if (item.name === 'site_id') {
                                item.value = site.id
                                item.description = site.designation
                                items.push(item)
                            }
                            if (item.name === 'site_depart_id') {
                                item.value = site_depart.id
                                item.description = site_depart.designation
                                items.push(item)
                            }
                            if (item.name === 'site_arrive_id') {
                                item.value = site_arrive.id
                                item.description = site_arrive.designation
                                items.push(item)
                            }
                            if (item.name === 'ville_id') {
                                item.value = ville.id
                                item.description = ville.nom
                                items.push(item)
                            }
                            if (item.name === 'ville_depart_id') {
                                item.value = ville_depart.id
                                item.description = ville_depart.nom
                                items.push(item)
                            }
                            if (item.name === 'ville_arrive_id') {
                                item.value = ville_arrive.id
                                item.description = ville_arrive.nom
                                items.push(item)
                            }
                            if (item.name === 'tarif_id') {
                                item.value = tarif.id
                                item.description = formatPrice(tarif.prix) + "[" + tarif.trajet + "]"
                                items.push(item)
                            }
                            if (item.name === 'trajet_id') {
                                item.value = trajet.id
                                item.description = trajet.designation
                                items.push(item)
                            }
                            if (item.name === 'vehicule_id') {
                                item.value = vehicule.id
                                item.description = vehicule.marque + "[ " + vehicule.designation + " ]"
                                items.push(item)
                            }
                            if (item.name === 'user_id') {
                                item.value = utilisateur.id
                                item.description = utilisateur.prenom + " <" + utilisateur.email + ">"
                                items.push(item)
                            }
                            if (item.name === 'client_id') {
                                item.value = client.id
                                item.description = client.prenom + " <" + client.email + ">"
                                items.push(item)
                            }
                            if (item.name === 'date_depart') {
                                item.value = moment(searchParamValue).format("YYYY-MM-DD")
                                item.description = moment(searchParamValue).format("DD MMM YYYY")
                                items.push(item)
                            }
                            if (item.name === 'created_at') {
                                item.value = moment(searchParamValue).format("YYYY-MM-DD")
                                item.description = moment(searchParamValue).format("DD MMM YYYY")
                                items.push(item)
                            }
                            if (item.name === 'detail') {
                                item.value = (new URLSearchParams(locationSearch)).get("detail")
                                item.description = (new URLSearchParams(locationSearch)).get("detail")
                                items.push(item)
                            }
                            if (item.name === 'province') {
                                item.value = (new URLSearchParams(locationSearch)).get("province")
                                item.description = (new URLSearchParams(locationSearch)).get("province")
                                items.push(item)
                            }
                        }
                    })
                    setSelectedItems(items)
                }
            })
        return () => { isMounted = false };
    }, [locationSearch])

    const handleRemoveSearchItem = (e, name) => {
        e.stopPropagation()
        let params = new URLSearchParams(locationSearch)
        params.delete(name)
        navigate(locationPathname + "?" + params)
    }

    const handleAddSearchItem = () => {
        setShowOption(false)
    }

    const handleClickItem = (item) => {
        setCurrentItem(item)
        if (item.name === 'id') setShowId(true)
        if (item.name === 'status') setShowStatus(true)
        if (item.name === 'account') setShowAccount(true)
        if (item.name === 'province') setShowProvince(true)
        if (item.name === 'gare_routiere_id') setShowGare(true)
        if (item.name === 'cooperative_id') setShowCooperative(true)
        if (item.name === 'site_id') setShowSite(true)
        if (item.name === 'site_depart_id') setShowSiteDepart(true)
        if (item.name === 'site_arrive_id') setShowSiteArrive(true)
        if (item.name === 'ville_id') setShowVille(true)
        if (item.name === 'ville_depart_id') setShowVilleDepart(true)
        if (item.name === 'ville_arrive_id') setShowVilleArrive(true)
        if (item.name === 'tarif_id') setShowTarif(true)
        if (item.name === 'trajet_id') setShowTrajet(true)
        if (item.name === 'vehicule_id') setShowVehicule(true)
        if (item.name === 'user_id') setShowUser(true)
        if (item.name === 'client_id') setShowUser(true)
        if (item.name === 'detail') setShowDetail(true)
        if (item.name === 'date_depart') setShowDateDepart(true)
        if (item.type === 'string') setShowTextModal(true)
        if (item.type === 'date') setShowDate(true)
    }

    return (
        <div className="search-bar-container">
            {
                showStatus && <InputStatus param="status" statusList={statusList} closeModal={() => { setShowStatus(false); setShowOption(false) }} />
            }
            {
                showId && <InputSearchTextModal param="id" label="Identifiant" closeModal={() => { setShowId(false); setShowOption(false) }} />
            }
            {
                showAccount && <InputSearchTextModal param="account" label="Compte ou contact" closeModal={() => { setShowAccount(false); setShowOption(false) }} />
            }
            {
                showProvince && <InputSearchTextModal param="province" label="Province" closeModal={() => { setShowProvince(false); setShowOption(false) }} />
            }
            {
                showGare && <InputGare useLink onChange={handleAddSearchItem} closeModal={() => { setShowGare(false); setShowOption(false) }} hideInput />
            }
            {
                showCooperative && <InputCooperative useLink onChange={handleAddSearchItem} closeModal={() => { setShowCooperative(false); setShowOption(false) }} hideInput />
            }
            {
                showSite && <InputSite siteType="site_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowSite(false); setShowOption(false) }} hideInput />
            }
            {
                showSiteDepart && <InputSite siteType="site_depart_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowSiteDepart(false); setShowOption(false) }} hideInput />
            }
            {
                showSiteArrive && <InputSite siteType="site_arrive_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowSiteArrive(false); setShowOption(false) }} hideInput />
            }
            {
                showVille && <InputVille villeType="ville_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowVille(false); setShowOption(false) }} hideInput />
            }
            {
                showVilleDepart && <InputVille villeType="ville_depart_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowVilleDepart(false); setShowOption(false) }} hideInput />
            }
            {
                showVilleArrive && <InputVille villeType="ville_arrive_id" useLink onChange={handleAddSearchItem} closeModal={() => { setShowVilleArrive(false); setShowOption(false) }} hideInput />
            }
            {
                showTarif && <InputTarif useLink onChange={handleAddSearchItem} closeModal={() => { setShowTarif(false); setShowOption(false) }} hideInput />
            }
            {
                showTrajet && <InputTrajet useLink onChange={handleAddSearchItem} closeModal={() => { setShowTrajet(false); setShowOption(false) }} hideInput />
            }
            {
                showVehicule && <InputVehicule useLink onChange={handleAddSearchItem} closeModal={() => { setShowVehicule(false); setShowOption(false) }} hideInput />
            }
            {
                showDate && <InputSearchDateModal param="created_at" label="Date de création" onChange={handleAddSearchItem} closeModal={() => { setShowDate(false); setShowOption(false) }} />
            }
            {
                showDateDepart && <InputSearchDateModal param="date_depart" label="Date de départ" onChange={handleAddSearchItem} closeModal={() => { setShowDateDepart(false); setShowOption(false) }} />
            }
            {
                showUser && <InputUser useLink onChange={handleAddSearchItem} closeModal={() => { setShowUser(false); setShowOption(false) }} hideInput />
            }
            {
                showClient && <InputUser useLink onChange={handleAddSearchItem} closeModal={() => { setShowClient(false); setShowOption(false) }} hideInput />
            }
            {
                showAccount && <InputSearchTextModal param="account" label="Compte ou contact" closeModal={() => { setShowAccount(false); setShowOption(false) }} />
            }
            {
                showDetail && <InputSearchTextModal param="detail" label="Detail" closeModal={() => { setShowDetail(false); setShowOption(false) }} />
            }
            {
                !showOption ?
                    <div className="space-between" onClick={() => setShowOption(true)}>
                        <div className="search-value">
                            {
                                selectedItems.length > 0 ?
                                    selectedItems.map(item => <div className='search-text' key={item.name}>
                                        <div>
                                            {
                                                item.value ?
                                                    <>{item.label} <span className='secondary'>{' : ' + item.description}</span></>
                                                    :
                                                    <>{item.description}</>
                                            }
                                            <CgClose className='pointer' onClick={(e) => handleRemoveSearchItem(e, item.name)} />
                                        </div>
                                    </div>
                                    )
                                    :
                                    <div className="search-value">
                                        {selectedItems.length === 0 && <div className='adress-item'>Rechercher</div>}
                                    </div>
                            }
                        </div>
                        <FiSearch size={20} className='pointer' />
                    </div>
                    :
                    <div className="search-container">
                        <div className="space-between">
                            <h4 style={{ margin: "0" }} className='adress-item'>Filtré par :</h4>
                            <CgClose size={20} className='pointer' onClick={() => setShowOption(false)} />
                        </div>
                        <ul className='list-detail'>
                            {
                                searchItems.map((item) => (
                                    <li key={item.name}><span onClick={() => handleClickItem(item)}>{item.label}</span></li>
                                ))
                            }
                        </ul>
                    </div>
            }
        </div>

    )
}
