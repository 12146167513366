import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFileUrl from '../../hooks/useFileUrl'
import useScreenSize from '../../hooks/useScreenSize'
import { FaUserEdit } from "react-icons/fa"
import useFormatPhone from '../../hooks/useFormatPhone'
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi"
import Femme from '../../assets/PROFILE/femme.jpg'
import Homme from '../../assets/PROFILE/homme.jpg'
import BADGE1 from '../../assets/SVG/BADGE1.svg'
import BADGE2 from '../../assets/SVG/BADGE2.svg'
import BADGE3 from '../../assets/SVG/BADGE3.svg'
import Btn from '../../components/button/Btn'
import ScreenLoader from '../../components/loader/ScreenLoader'
import ProfileBlog from './ProfileBlog'
import EditNotification from './EditNotification'
import moment from 'moment'

export default function Profile({ auth }) {

    const tokenHeaders = useToken()
    const locationSearch = useLocation().search
    const navigate = useNavigate()
    const screenSize = useScreenSize()
    const formatPhoneNumber = useFormatPhone()
    const [user, setUser] = useState([])
    const [showProfilDetail, setShowProfileDetail] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [showAllBio, setShowAllBio] = useState(false)
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        if (["sm", "md"].includes(screenSize)) {
            setShowProfileDetail(false)
        } else {
            setShowProfileDetail(true)
        }
    }, [screenSize])



    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        const params = new URLSearchParams(locationSearch)
        useApi.get('/utilisateur/show/' + (params.get("user_id") ? params.get("user_id") : auth.id),
            tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setUser(res.data.utilisateur)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(fetchData, [])

    useEffect(fetchData, [locationSearch])

    return (
        loading ? <ScreenLoader />
            :
            <div className='profile'>
                {showNotification &&
                    <EditNotification
                        auth={auth}
                        user={user}
                        updateData={fetchData}
                        closeModal={() => setShowNotification(false)}
                    />
                }
                <div className="profile-detail-container">
                    <div className="justify-center">
                        <div className="profile-img-container">
                            <img src={user.profil ? useFileUrl + user.profil : (user.sexe == "homme" ? Homme : Femme)} alt="img" />
                        </div>
                    </div>
                    <div className="text-details-header">
                        <h1>
                            <span className='uppercase'>{user.nom}</span> <span className='capitalize'>{user.prenom}</span>
                        </h1>
                        {user.id === auth.id &&
                            <span onClick={() => navigate('/app/profile/edit')}><FaUserEdit size={18} /> Modifier profil</span>
                        }
                        {user.id !== auth.id &&
                            <Btn
                                label="Envoyer un message"
                                className='btn-outline small'
                                onClick={() => navigate(`/app/conversation/add?recipient_id=${auth.id}`)} />
                        }
                        <div className='btn-showdetail' onClick={() => setShowProfileDetail(!showProfilDetail)}>
                            <span>
                                {showProfilDetail ? "Cacher les détails" : "Afficher les détails"}
                            </span>
                            {showProfilDetail ?
                                <FiArrowUpCircle size={18} />
                                :
                                <FiArrowDownCircle size={18} />
                            }

                        </div>
                    </div>
                    {showProfilDetail &&
                        <>
                            {user.biographie &&
                                <p className='profile-biographie'>{user.biographie}</p>
                            }
                            <div className="profile-details">
                                {((auth.role === "passager" && user.id === auth.id) || ["superadmin", "admin", "admin_site", "operateur", "chauffeur"].includes(auth.role)) &&
                                    <>
                                        <h3>Contact</h3>
                                        <div>
                                            <div className='text-label'>Téléphone:</div>
                                            <div className='text-detail text-orange-w'>{formatPhoneNumber(user.telephone)}</div>
                                        </div>
                                        <div>
                                            <div className='text-label'>Adresse:</div>
                                            <div className='text-detail'>{user.adresse}</div>
                                        </div>
                                        <div>
                                            <div className='text-label'>E-mail:</div>
                                            <div className='text-detail text-orange-w'>{user.email}</div>
                                        </div>
                                    </>
                                }

                                <h3>Informations de base</h3>
                                <div>
                                    <div className='text-label'>Date de naissance:</div>
                                    <div className='text-detail'>{user.date_naissance ? moment(user.date_naissance).format('DD MMMM[,] YYYY ') : "N/A"}</div>
                                </div>
                                <div>
                                    <div className='text-label'>Lieu de naissance:</div>
                                    <div className='text-detail capitalize'>{user.lieu_naissance ? user.lieu_naissance : 'N/A'}</div>
                                </div>
                                <div>
                                    <div className='text-label'>Sexe:</div>
                                    <div className='text-detail capitalize'>{user.sexe}</div>
                                </div>
                                <div>
                                    <div className='text-label'>CIN:</div>
                                    <div className='text-detail'>{user.cin ? user.cin : "N/A"}</div>
                                </div>
                                {((auth.role === "passager" && user.id === auth.id) || (['superadmin', 'admin', 'admin_site', 'operateur', 'chauffeur'].includes(auth.role) && user.id === auth.id)) &&
                                    <>
                                        <h3>Extras</h3>
                                        <div>
                                            <div className='text-label'>Notification:</div>
                                            <div className='text-detail pointer' onClick={() => {
                                                if (auth.id !== user.id) return
                                                setShowNotification(true)
                                            }}>
                                                {user.notification_email ? 'E-mail' : ''}
                                                {user.notification_email && user.notification_sms ? ', ' : ''}
                                                {user.notification_sms ? 'SMS' : ''}
                                                {(!user.notification_email && !user.notification_sms) && 'N/A'}
                                            </div>
                                        </div>
                                    </>
                                }

                                {['superadmin', 'admin', 'admin_site', 'operateur', 'chauffeur'].includes(auth.role) &&
                                    <>
                                        <div>
                                            <div className='text-label'>Coopérative:</div>
                                            <div className='text-detail'>{user.cooperative ? user.cooperative : "N/A"}</div>
                                        </div>
                                        <div>
                                            <div className='text-label'>Site:</div>
                                            <div className='text-detail'>{user.site ? user.site : "N/A"}</div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="profile-post-container">
                    <ProfileBlog auth={auth} user={user} />
                </div>
            </div>
    )
}
