import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputVille from '../../components/input/InputVille'
import Btn from '../../components/button/Btn'


export default function EditTrajet({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [villeDepart, setVilleDepart] = useState(null)
    const [villeArrive, setVilleArrive] = useState(null)
    const [heureTrajet, setHeureTrajet] = useState("")
    const validUnsignedNumber = /^[0-9]+$/
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        setBtnDisabled(
            (villeDepart === null || villeDepart.length === 0) || (villeArrive === null || villeArrive.length === 0) || !validUnsignedNumber.test(heureTrajet) || loading
        )
    }, [villeDepart, villeArrive, heureTrajet, loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            ville_depart_id: villeDepart ? villeDepart.id : '',
            ville_arrive_id: villeArrive ? villeArrive.id : '',
            heure_trajet: heureTrajet,
        }

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/trajet/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setVilleDepart(null)
                setVilleArrive(null)
                setHeureTrajet("")
                setLoading(false)
                toast.success(response.data.success)
                navigate('/app/trajet')
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/trajet/show/' + params.id, tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const trajet = res.data.trajet
                    if (trajet) {
                        if (trajet.ville_depart) setVilleDepart({
                            id: trajet.ville_depart_id,
                            nom: trajet.ville_depart,
                        })
                        if (trajet.ville_arrive) setVilleArrive({
                            id: trajet.ville_arrive_id,
                            nom: trajet.ville_arrive,
                        })
                        if (trajet.heure_trajet) setHeureTrajet(trajet.heure_trajet)
                    }
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false }
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        navigate('/app/trajet')
    }

    return (
        <div className="view-content">
            <div className='view-regular'>
                <div className="vertical-align">
                    {!auth.must_complete_profil &&
                        <div style={{ paddingRight: "10px" }} onClick={goBack}>
                            <IoMdArrowBack size={26} className='pointer' />
                        </div>
                    }
                    <h2>{title}</h2>
                </div>

                <form onSubmit={handleSubmit}>
                    <InputVille
                        label="Ville de départ"
                        value={villeDepart}
                        onChange={setVilleDepart}
                    />
                    <InputVille
                        label="Ville arrivé"
                        value={villeArrive}
                        onChange={setVilleArrive}
                    />
                    <InputText
                        label="Durée en heure du trajet"
                        placeholder="Saisir le nombre d'heure"
                        type="number"
                        min="0"
                        value={heureTrajet}
                        onChange={setHeureTrajet}
                        className='large'
                        required />
                    <div className="form-btn">
                        <Btn
                            label={loading ? "Chargement..."
                                : (['/app/trajet/edit/' + params.id].includes(pathname) ? "Modifier"
                                    : "Ajouter")}
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
