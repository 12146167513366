import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import { IoMdArrowBack } from 'react-icons/io'
import InputText from '../../components/input/InputText'
import InputTextArea from '../../components/input/InputTextArea'
import InputSelect from '../../components/input/InputSelect'
import InputCooperative from '../../components/input/InputCooperative'
import InputSite from '../../components/input/InputSite'
import InputUser from '../../components/input/InputUser'
import InputFile from '../../components/input/InputFile'
import Btn from '../../components/button/Btn'
import ScreenLoader from '../../components/loader/ScreenLoader'

export default function EditUser({ auth, title, action }) {

    const params = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tokenHeaders = useToken()
    const today = new Date()
    const YearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    const maxDate = YearsAgo.toISOString().split('T')[0]

    const sexList = [
        'homme',
        'femme'
    ]

    let roleList = []

    if (['superadmin'].includes(auth.role)) {
        roleList = [
            'superadmin',
            'resp_gare',
            'resp_caisse',
            'admin',
            'admin_site',
            'operateur',
            'chauffeur',
            'passager'
        ]
    } else if (['admin'].includes(auth.role)) {
        roleList = [
            'admin_site',
            'operateur',
            'chauffeur'
        ]
    } else {
        roleList = [
            'operateur',
            'chauffeur'
        ]
    }


    const typeList = [
        'parent',
        'fictif'
    ]

    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [error, setError] = useState("")
    const [file, setFile] = useState("")
    const [tmpFile, setTmpFile] = useState("")
    const [fileId, setFileId] = useState("")
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [email, setEmail] = useState("")
    const [adresse, setAdresse] = useState("")
    const [telephone, setTelephone] = useState("")
    const [biographie, setBiographie] = useState("")
    const [dateNaissance, setDateNaissance] = useState("")
    const [lieuNaissance, setLieuNaissance] = useState("")
    const [cin, setCin] = useState("")
    const [sexe, setSexe] = useState("")
    const [cooperative, setCooperative] = useState(null)
    const [site, setSite] = useState(null)
    const [type, setType] = useState("")
    const [role, setRole] = useState("")
    const [utilisateurParent, setUtilisateurParent] = useState(null)
    const validEmail = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const validPhone = /^(032|033|034|038)\d{7}$/
    const [btnDisabled, setBtnDisabled] = useState(true)

    useEffect(() => {
        const isSuperAdmin = ["superadmin"].includes(auth.role)
        const isAdmin = ["admin"].includes(auth.role)
        const isAdminSite = ["admin_site"].includes(auth.role)
        const isProfileEdit = ['/app', '/app/profile/edit'].includes(pathname) || ['/app/user/edit/' + params.id].includes(pathname) && params.id == auth.id

        const isInvalidDataProfil = !nom.trim() || !prenom.trim() || !validPhone.test(telephone) || !validEmail.test(email) || !adresse.trim() || !sexe.trim() || loading
        const isInvalidBaseData = !nom.trim() || !prenom.trim() || !validPhone.test(telephone) || !validEmail.test(email) || !adresse.trim() || !sexe.trim() || !type.trim() || !role.trim() || loading
        const isAdminRole = ["admin"].includes(role)
        const isAdminSiteRole = ["admin_site", "operateur", "chauffeur"].includes(role)

        const isFictif = type === "fictif";
        const isInvalidFictifData = isInvalidBaseData || !utilisateurParent
        const isInvalidNonFictifData = isInvalidBaseData

        let disabled;

        if (loading) {
            disabled = true;
        } else if (isProfileEdit) {
            disabled = isInvalidDataProfil;
        } else if (isSuperAdmin) {
            disabled = isFictif ?
                ((isAdminRole) ? (isInvalidFictifData || !cooperative)
                    : isAdminSiteRole ? (isInvalidFictifData || !cooperative || !site)
                        : isInvalidFictifData)
                :
                ((isAdminRole) ? (isInvalidNonFictifData || !cooperative)
                    : isAdminSiteRole ? (isInvalidNonFictifData || !cooperative || !site)
                        : isInvalidNonFictifData)
        } else if (isAdmin || isAdminSite) {
            disabled = isFictif ?
                (isAdminSiteRole ? isInvalidFictifData
                    : isInvalidFictifData)
                :
                (isAdminSiteRole ? isInvalidNonFictifData
                    : isInvalidNonFictifData)
        }

        setBtnDisabled(disabled)
    }, [auth, nom, prenom, email, adresse, telephone, sexe, type, role, cooperative, site, utilisateurParent, loading, pathname, params.id, validEmail, validPhone]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('fichier', file)
        if (['/app/user/edit/' + params.id, '/app/profile/edit'].includes(pathname)) {
            data.append('fichier_id', fileId)
        }
        data.append('level', 1)
        data.append('nom', nom)
        data.append('prenom', prenom)
        data.append('email', email)
        data.append('adresse', adresse)
        data.append('telephone', telephone)
        data.append('biographie', biographie)
        data.append('date_naissance', dateNaissance)
        data.append('lieu_naissance', lieuNaissance)
        data.append('cin', cin)
        data.append('sexe', sexe)
        data.append('cooperative_id', cooperative ? cooperative.id : '')
        data.append('site_id', site ? site.id : '')
        data.append('type', type)
        data.append('role', role)
        data.append('real_email_id', utilisateurParent ? utilisateurParent.id : '')

        try {
            const response = await useApi.post(
                action + (params.id ? "/" + params.id : (['/app/profile/edit'].includes(pathname) ? "/" + auth.id : "")),
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                if (['/app/profile/add'].includes(pathname)) {
                    setFile("")
                    setNom("")
                    setPrenom("")
                    setEmail("")
                    setAdresse("")
                    setTelephone("")
                    setBiographie("")
                    setDateNaissance("")
                    setLieuNaissance("")
                    setCin("")
                    setSexe("")
                    setCooperative(null)
                    setSite(null)
                    setType("")
                    setRole("")
                    setUtilisateurParent("")
                }
                setLoading(false)
                toast.success(response.data.success)
                if (['/app/user/edit/' + params.id, '/app/user/add'].includes(pathname)) {
                    navigate('/app/user')
                } else if (['/app/profile/edit'].includes(pathname)) {
                    navigate('/app/profile')
                } else {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }

            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    const fetchData = () => {
        let isMounted = true
        setLoadingData(true)
        useApi.get('/utilisateur/show/' + (auth.must_complete_profil ? auth.id : (['/app/profile/edit'].includes(pathname) ? auth.id : params.id)), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    const user = res.data.utilisateur
                    if (user) {
                        if (user.role) {
                            setRole(user.role)
                            if (['/app/user/edit/' + params.id].includes(pathname) && !(
                                (["superadmin", "resp_gare", "resp_caisse", "admin", "admin_site", "operateur", "chauffeur", "passager"].includes(user.role) && auth.role === "superadmin")
                                || (["admin_site", "operateur", "chauffeur"].includes(user.role) && auth.role === "admin")
                                || (["operateur", "chauffeur"].includes(user.role) && auth.role === "admin_site")
                            )) {
                                return navigate(-1)
                            }
                        }
                        if (user.profil) setTmpFile(user.profil)
                        if (user.profil_id) setFileId(user.profil_id)
                        if (user.nom) setNom(user.nom)
                        if (user.prenom) setPrenom(user.prenom)
                        if (user.email) setEmail(user.email)
                        if (user.adresse) setAdresse(user.adresse)
                        if (user.telephone) setTelephone(user.telephone)
                        if (user.biographie) setBiographie(user.biographie)
                        if (user.date_naissance) setDateNaissance(user.date_naissance)
                        if (user.lieu_naissance) setLieuNaissance(user.lieu_naissance)
                        if (user.cin) setCin(user.cin)
                        if (user.sexe) setSexe(user.sexe)
                        if (user.cooperative) setCooperative({
                            id: user.cooperative_id,
                            designation: user.cooperative
                        })
                        if (user.site) setSite({
                            id: user.site_id,
                            designation: user.site
                        })
                        if (user.type) setType(user.type)
                        if (user.utilisateur_parent_nom) setUtilisateurParent({
                            id: user.real_email_id,
                            nom: user.utilisateur_parent_nom,
                            prenom: user.utilisateur_parent_prenom,
                            email: user.utilisateur_parent_email,
                        })
                    }
                    setLoadingData(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoadingData(false)
            })
        return () => { isMounted = false };
    }

    useEffect(fetchData, [params.id])

    const goBack = () => {
        if (['/app/profile/edit'].includes(pathname)) {
            navigate('/app/profile')
        } else {
            navigate('/app/user')
        }
    }

    return (
        loadingData ? <ScreenLoader />
            :
            <div className="view-content">
                <div>
                    <div className="vertical-align">
                        {!auth.must_complete_profil &&
                            <div style={{ paddingRight: "10px" }} onClick={goBack}>
                                <IoMdArrowBack size={26} className='pointer' />
                            </div>
                        }
                        <h2>{title}</h2>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="grid-one">
                            <InputFile
                                label="Photo de profil"
                                file={file}
                                setFile={setFile}
                                tmpFile={tmpFile}
                                setTmpFile={setTmpFile}
                                multiple={false}
                                id="profil"
                            />
                        </div>

                        <div className="grid-two">
                            <InputText
                                label='Téléphone'
                                placeholder='Saisir votre n° téléphone'
                                value={telephone}
                                onChange={setTelephone}
                                className='large'
                                required />
                            {(!auth.must_complete_profil && !['/app/profile/edit'].includes(pathname)) &&
                                <InputText
                                    label='Email'
                                    placeholder='Saisir votre adresse mail'
                                    value={email}
                                    type="email"
                                    onChange={setEmail}
                                    className='large'
                                    required={['/app/user/edit/' + params.id, '/app/user/add'].includes(pathname)} />
                            }
                        </div>
                        <div className="grid-two">
                            <InputText
                                label='Nom'
                                placeholder='Saisir votre nom'
                                value={nom}
                                onChange={setNom}
                                className='large'
                                required />
                            <InputText
                                label='Prénom(s)'
                                placeholder='Saisir votre prénom(s)'
                                value={prenom}
                                onChange={setPrenom}
                                className='large'
                                required />
                        </div>
                        <div className="grid-two">
                            <InputText
                                label='Date de naissance'
                                placeholder='Saisir votre date de naissance'
                                type='date'
                                max={maxDate}
                                value={dateNaissance}
                                onChange={setDateNaissance}
                                className='large' />
                            <InputSelect
                                label='Sexe'
                                selected={sexe}
                                setSelected={setSexe}
                                options={sexList}
                                className='large'
                                required />
                        </div>
                        <div className="grid-two">
                            <InputText
                                label='Lieu de naissance'
                                placeholder='Saisir votre lieu de naissance'
                                value={lieuNaissance}
                                onChange={setLieuNaissance}
                                className='large' />
                            <InputText
                                label='CIN'
                                placeholder='Saisir votre n° cin'
                                value={cin}
                                onChange={setCin}
                                className='large' />
                        </div>
                        {(
                            !auth.must_complete_profil
                            && !['/app/profile/edit'].includes(pathname)
                            && ["superadmin", "admin", "admin_site"].includes(auth.role)
                            && !(['/app/user/edit/' + params.id].includes(pathname) && params.id == auth.id)
                        ) &&
                            <div className="grid-two">
                                <InputSelect
                                    label='Role'
                                    selected={role}
                                    setSelected={setRole}
                                    options={roleList}
                                    className='large'
                                    required />
                                <InputSelect
                                    label='Type'
                                    selected={type}
                                    setSelected={setType}
                                    options={typeList}
                                    className='large'
                                    required />
                            </div>
                        }
                        <div className="grid-two">
                            <InputText
                                label='Adresse'
                                placeholder='Saisir votre adresse'
                                value={adresse}
                                onChange={setAdresse}
                                className='large'
                                required />
                            {(
                                auth.role === "superadmin"
                                && !(['/app/user/edit/' + params.id].includes(pathname) && params.id == auth.id)
                                && !['/app', '/app/profile/edit'].includes(pathname)
                                && ['admin', 'admin_site', 'operateur', 'chauffeur'].includes(role)
                            ) &&
                                <InputCooperative
                                    value={cooperative}
                                    onChange={setCooperative}
                                    required
                                />
                            }
                        </div>
                        <div className="grid-two">
                            {(
                                auth.role === "superadmin"
                                && !(['/app/user/edit/' + params.id].includes(pathname) && params.id == auth.id)
                                && !['/app', '/app/profile/edit'].includes(pathname)
                                && ['admin_site', 'operateur', 'chauffeur'].includes(role)
                            ) &&
                                <InputSite
                                    value={site}
                                    onChange={setSite}
                                    required
                                />
                            }
                            {(type === "fictif" && ["superadmin", "admin", "admin_site"].includes(auth.role)) &&
                                <InputUser
                                    label="Compte parent"
                                    type="parent"
                                    value={utilisateurParent}
                                    onChange={setUtilisateurParent}
                                />
                            }
                        </div>
                        {['/app/profile/edit'].includes(pathname) &&
                            <div className="grid-one">
                                <InputTextArea
                                    label="Biographie"
                                    placeholder="Décrivez-vous en quelques lignes"
                                    value={biographie}
                                    onChange={setBiographie}
                                    className='large' />
                            </div>
                        }
                        <div className="form-btn">
                            <Btn
                                label={loading ? "Chargement..."
                                    : (['/app/user/edit/' + params.id].includes(pathname) ? "Modifier"
                                        : (['/app/user/add'].includes(pathname)) ? "Ajouter" : "Compléter")}
                                className='btn-primary regular'
                                type='submit'
                                disabled={btnDisabled || loading}
                            />
                        </div>
                    </form>
                </div>
            </div>
    )
}
