import { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputTextArea from '../../components/input/InputTextArea'
import Btn from '../../components/button/Btn'

export default function StateBlog({ actionType, blog, updateData, closeModal }) {

    const tokenHeaders = useToken()
    const params = useParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [motif, setMotif] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            motif: motif
        }
        try {
            const response = await useApi.post("/blog/"
                + actionType
                + "/" + blog.id,
                data, tokenHeaders)

            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                toast.success(response.data.success)
                if (actionType === "do_delete") {
                    if (['/app/blog/show/' + params.id].includes(pathname)) {
                        navigate('/app/blog')
                    } else {
                        updateData(true)
                    }
                } else {
                    setError(null)
                    setMotif("")
                    updateData(true)
                    setLoading(false)
                    closeModal()
                }

            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    useEffect(() => {
        setBtnDisabled(!motif.trim())
    }, [motif])

    return (
        <div className='input-modal'>
            <div>
                <form onSubmit={handleSubmit}>
                    <h3>
                        {
                            actionType === "do_publish" ? "Publication du blog"
                                : actionType === "do_delete" ? "Suppression du blog"
                                    : actionType === "do_draft" ? "Mise en brouillon du blog"
                                        : ""
                        }
                    </h3>
                    <InputTextArea
                        label="Motif"
                        placeholder="Décrivez la motif"
                        value={motif}
                        onChange={setMotif}
                        className='large'
                        required />
                    <div className="justify-right">
                        <Btn
                            label={loading ? "Chargement..." : "OK"}
                            className='btn-primary small btn-margin'
                            type='submit'
                            disabled={btnDisabled || loading} />
                        <Btn
                            label="Annuler"
                            className='btn-outline small'
                            onClick={() => closeModal()}
                            disabled={loading} />
                    </div>
                </form>
            </div>

        </div>
    )
}
