import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import useFormatPhone from '../../hooks/useFormatPhone'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionVille from './ActionVille'
import ShowTab from '../../view/ShowTab'
import { PiUserCirclePlusFill } from "react-icons/pi"
import { IoMdInformationCircleOutline } from "react-icons/io"

export default function ShowVille({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPhoneNumber = useFormatPhone()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [ville, setVille] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/ville/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setVille(res.data.ville)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(ville)
    }, [ville]);

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('ville-channel')
            .listen('.updated', (event) => {
                setVille(prevVille => {
                    if (prevVille && prevVille.id === event.ville.id) {
                        return { ...prevVille, ...event.ville }
                    }
                    return prevVille
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {ville &&
                    <div className='view-show-container'>
                        <ShowHeader data={ville} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <h3>{ville.nom}</h3>
                            <p><IoMdInformationCircleOutline size={16} className='svg-text' /> Libellé : <span className='text'>{ville.libelle}</span>, Province : <span className='text'>{ville.province}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{ville.createur_prenom + " <" + ville.createur_email + ">"}</span></p>
                            <ActionVille auth={auth} ville={ville} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="ville_id" value={ville.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
