import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import usePrice from '../../hooks/usePrice'
import PageLoader from '../../components/loader/PageLoader'
import ShowHeader from '../../view/ShowHeader'
import ActionTarif from './ActionTarif'
import ShowTab from '../../view/ShowTab'
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { PiUserCirclePlusFill } from "react-icons/pi"

export default function ShowTarif({ auth, currentId, setCurrentId, setCurrentItem }) {
    const tokenHeaders = useToken()
    const formatPrice = usePrice()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [tarif, setTarif] = useState(null)

    const updateData = () => {
        let isMounted = true
        setLoading(true)
        useApi.get('/tarif/show/' + (currentId ? currentId : params.id), tokenHeaders)
            .then((res) => {
                if (isMounted) {
                    setTarif(res.data.tarif)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
        return () => { isMounted = false };
    }

    useEffect(() => {
        if (setCurrentItem)
            setCurrentItem(tarif)
    }, [tarif])

    useEffect(updateData, [currentId])

    useEffect(() => {
        window.Echo.channel('tarif-channel')
            .listen('.updated', (event) => {
                setTarif(prevTarif => {
                    if (prevTarif && prevTarif.id === event.tarif.id) {
                        return { ...prevTarif, ...event.tarif }
                    }
                    return prevTarif
                })
            })
    }, [])

    return (
        loading ? <PageLoader />
            :
            <>
                {tarif &&
                    <div className='view-show-container'>
                        <ShowHeader data={tarif} closeDetail={() => setCurrentId()} />
                        <div className="view-show">
                            <h3>{tarif.trajet}</h3>
                            {auth.role === "superadmin" &&
                                <p className="important">{tarif.cooperative}</p>
                            }
                            <p><FaRegMoneyBillAlt size={16} className='svg-text' /> <span className='text'>{formatPrice(tarif.prix)}</span>, <span className='text'>{tarif.categorie}</span></p>
                            <p><PiUserCirclePlusFill size={16} className='svg-text' /> <span className='text'>{tarif.createur_prenom + " <" + tarif.createur_email + ">"}</span></p>
                            <ActionTarif auth={auth} tarif={tarif} updateData={updateData} />
                        </div>
                        <ShowTab auth={auth} name="tarif_id" value={tarif.id} updateData={updateData} />
                    </div>
                }
            </>

    )
}
