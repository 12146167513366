import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import moment from 'moment/moment'

export default function MultiChart({ data, type, displayTitle, hideLegend, labelPosition }) {
    const canvasRef = useRef(null)
    const chartInstance = useRef(null)

    const hexToRGBA = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)
        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    // Fonction pour éclaircir une couleur hexadécimale
    const lightenColor = (hex, percent) => {
        const num = parseInt(hex.slice(1), 16)
        const amt = Math.round(2.55 * percent)
        const R = (num >> 16) + amt
        const G = ((num >> 8) & 0x00ff) + amt
        const B = (num & 0x0000ff) + amt
        const newColor = (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
            (B < 255 ? (B < 1 ? 0 : B) : 255)
        ).toString(16)
            .slice(1)

        return `#${newColor}`
    }



    useEffect(() => {
        const generateCustomColor = () => {
            const colors = [
                { color: '#2196f3', weight: 1 },
                { color: '#4dabf5', weight: 1 },
                { color: '#1769aa', weight: 1 },
                { color: '#00bcd4', weight: 1 },
                { color: '#6d1b7b', weight: 1 },
                { color: '#9c27b0', weight: 1 },
                { color: '#af52bf', weight: 1 },
                { color: '#a31545', weight: 1 },
                { color: '#e91e63', weight: 1 },
                { color: '#ed4b82', weight: 2 },
                { color: '#aa2e25', weight: 1 },
                { color: '#f44336', weight: 1 },
                { color: '#f6685e', weight: 1 },
                { color: '#2c387e', weight: 1 },
                { color: '#3f51b5', weight: 1 },
                { color: '#6573c3', weight: 1 },
                { color: '#00695f', weight: 1 },
                { color: '#009688', weight: 1 },
                { color: '#33ab9f', weight: 1 },
                { color: '#ffeb3b', weight: 1 },
                { color: '#ffef62', weight: 1 },
                { color: '#ffc107', weight: 1 },
                { color: '#ffcd38', weight: 1 },
                { color: '#ff5722', weight: 1 },
                { color: '#ff784e', weight: 1 },
                { color: '#ab003c', weight: 1 },
                { color: '#f50057', weight: 1 },
                { color: '#f73378', weight: 1 },
                { color: '#357a38', weight: 1 },
                { color: '#4caf50', weight: 1 },
                { color: '#6fbf73', weight: 1 },
            ];

            const totalWeight = colors.reduce((sum, { weight }) => sum + weight, 0);

            let random = Math.random() * totalWeight;
            for (const { color, weight } of colors) {
                if (random < weight) {
                    return {
                        backgroundColor: hexToRGBA(color, 0.5),
                        borderColor: lightenColor(color, 0.2),
                        borderWidth: 1,
                        fill: {
                            target: 'origin',
                            above: hexToRGBA(color, 0.2),
                        },
                    };
                }
                random -= weight;
            }

            return {
                backgroundColor: hexToRGBA(colors[colors.length - 1].color, 0.5),
                borderColor: lightenColor(colors[colors.length - 1].color, 0.2),
                borderWidth: 1,
                fill: {
                    target: 'origin',
                    above: hexToRGBA(colors[colors.length - 1].color, 0.2),
                },
            };
        };

        if (data) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const dates = data[Object.keys(data)[0]].map(item => moment(item.date).format("DD[/]MM[/]YYYY")); // Obtenir les dates à partir du premier jeu de données
            const datasets = Object.entries(data).map(([key, value]) => ({
                label: key,
                data: value.map(item => item.count),
                fill: {
                    target: 'origin',
                    above: 'rgba(255, 99, 132, 0.2)',
                },
                ...generateCustomColor(),
                tension: 0.5,
            }));

            const ctx = canvasRef.current.getContext('2d');
            chartInstance.current = new Chart(ctx, {
                type: type ? type : 'bar',
                data: {
                    labels: dates,
                    datasets: datasets,
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    animation: {
                        duration: 1000,
                    },
                    plugins: {
                        legend: hideLegend ? false : {
                            position: labelPosition ? labelPosition : 'top', // Positionne les labels à droite du graphique
                            labels: {
                                boxWidth: 12,
                                usePointStyle: false,
                                pointStyle: 'circle',
                                radius: 10,
                            },
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: displayTitle ? true : false,
                                text: 'Date',
                            },
                        },
                        y: {
                            beginAtZero: true,
                            display: true,
                            title: {
                                display: displayTitle ? true : false,
                                text: 'Nombre',
                            },
                        },
                    },
                },
            });
        }
    }, [data, type, displayTitle, hideLegend, labelPosition]);

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%', maxWidth: '100%' }} />;
}