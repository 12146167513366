import { Outlet,Navigate } from "react-router-dom"

export default function ProfileRoute({auth}) {
    return (
        !(auth.must_complete_profil && auth.must_change_password) ?
            <Outlet/>
        : 
            <Navigate to="/app"/>
    )
}
