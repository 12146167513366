import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react'

const Direction = ({ origin, destination, color }) => {
    const [directionService, setDirectionService] = useState();
    const [directionRenderer, setDirectionRenderer] = useState();
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        if (!routesLibrary || !map) return;
        setDirectionService(new routesLibrary.DirectionsService());
        setDirectionRenderer(new routesLibrary.DirectionsRenderer({
            map,
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: color ? color : "#FF8B04",
            }
        }));
    }, [map, routesLibrary])

    useEffect(() => {
        if (!directionService || !directionRenderer) return;
        directionService.route({
            origin: origin,
            destination: destination,
            travelMode: 'WALKING',

        }).then((responses) => {
            directionRenderer.setDirections(responses);
            setRoutes(responses.routes);
        })
    }, [directionRenderer, directionService, origin, destination])

    return (
        null
    )
}

export default Direction