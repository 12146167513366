import { useLocation, useNavigate } from 'react-router-dom'

export default function InputStatus({ statusList, closeModal }) {
    const navigate = useNavigate()
    const location = useLocation()

    const handleSelectStatus = (st) => {
        let params = new URLSearchParams(location.search)
        params.set("status", st.designation)
        navigate(location.pathname + "?" + params.toString())
        if (closeModal) closeModal()
    }

    return (
        <div className='input-modal'>
            <div className="list-modal-container">
                <h4>Status</h4>
                {statusList.length > 0 ? (
                    statusList.map((st) => (
                        <div className="list-modal" key={st.id} onClick={() => handleSelectStatus(st)}>
                            <h3>{st.designation}</h3>
                        </div>
                    ))
                ) : (
                    <p>Aucun statut disponible</p>
                )}
            </div>
        </div>
    )
}
