import {useState,useEffect} from 'react'
import { toast } from 'react-toastify'
import useApi from '../../hooks/useApi'
import useToken from '../../hooks/useToken'
import InputText from '../../components/input/InputText'
import Btn from '../../components/button/Btn'


export default function ChangePassword() {

    const tokenHeaders = useToken()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [btnDisabled,setBtnDisabled] = useState(true)
    const validPassword = /^.{6}/

    useEffect(() => {
        setBtnDisabled(!validPassword.test(newPassword) || !validPassword.test(newPasswordConfirmation)
            || newPassword !== newPasswordConfirmation || loading
        )
    },[newPassword,newPasswordConfirmation,loading])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
    
        const data = {
            new_password: newPassword,
            new_password_confirmation: newPasswordConfirmation
        }
    
        try {
            const response = await useApi.post(
                "/utilisateur/change_password", 
                data, tokenHeaders)
    
            if (response.data.error) {
                setError(response.data.error)
                setBtnDisabled(false)
                setLoading(false)
                toast.error(response.data.error)
            } else if (response.data.success) {
                setError(null)
                setLoading(false)
                toast.success(response.data.success)
                setTimeout(() => {
                    localStorage.removeItem('token')
                    window.location.reload()
                }, 1000)
            }
        } catch (error) {
            console.error(error)
            setError("Erreur d'envoi, réessayez.")
            toast.error("Erreur d'envoi, réessayez.")
            setLoading(false)
            setBtnDisabled(false)
        }
    }

    return (
        <div className="view-content">
            <div className='view-regular'>
                <div className="vertical-align">                  
                    <h2>Changement mot de passe</h2>
                </div>
                
                <form onSubmit={handleSubmit}>                                
                    <div className="grid-one">
                        <InputText
                            label='Nouveau mot de passe' 
                            placeholder='Saisir votre nouveau mot de passe'
                            type='password'
                            value={newPassword}
                            onChange={setNewPassword}
                            className='large'
                            required/>
                    </div>
                    <div className="grid-one">
                        <InputText
                            label='Confirmation nouveau mot de passe' 
                            placeholder='Confirmez votre mot de passe'
                            type='password'
                            value={newPasswordConfirmation}
                            onChange={setNewPasswordConfirmation}
                            className='large'
                            required/>
                    </div>
                    <div className="form-btn">
                        <Btn 
                            label="Modifier"
                            className='btn-primary regular'
                            type='submit'
                            disabled={btnDisabled || loading}
                            />
                    </div>
                </form>
            </div>
        </div>
    )
}
